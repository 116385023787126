export const clipboardCopy = (text) => {
    const aux = document.createElement("input");
    aux.setAttribute("value", text);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
};

export const isUndefined = val => typeof val === 'undefined';
export const isNull = val => val === null;

export const safeChoice = (...args) => {
    return args.reduce((accum, val) => {
        const noAccum = isUndefined(accum) || isNull(accum);
        return noAccum ? val : accum;
    }, null);
}

export function waitFor (operation, interval) {
    console.log('---waitFor', interval);
    return new Promise(async (resolve, reject) => {
        let resolved = false;
        const timerId = setTimeout(() => {
            if (!resolved) {
                resolved = true;
                console.log('---waitFor timeout');
                resolve();
            }
        }, interval);
        const result = await operation();
        if (!resolved) {
            resolved = true;
            clearTimeout(timerId);
            resolve(result);
        }
    });
}

export function makeSync (operation, waitTime) {
    return operation;
}

export function getAllFuncs(toCheck) {
    const props = [];
    toCheck = toCheck || {};
    let obj = toCheck;
    do {
        props.push(...Object.getOwnPropertyNames(obj));
    } while (obj = Object.getPrototypeOf(obj));

    return props.sort().filter((e, i, arr) => {
       if (e!=arr[i+1] && typeof toCheck[e] == 'function') return true;
    });
}

export function apiExport (path, label, items = []) {
    const utils = getAllFuncs(path).filter(name => name[0] !== '_');
    utils.forEach(key => {
        const item = {label: label + key, value: (...args) => {
            return path[key](...args);
        }};
        items.push(item);
    });
    return items;
}

export async function getAudioFileDuration(arrayBuffer) {
    return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContext.decodeAudioData(arrayBuffer,
            function(buffer) {
                let duration = buffer.duration
                resolve(duration);
            }
        )
    });
}

export function usesWeb3Eth(content) {
    return content.includes('eth-') || content.includes('react-Contract');
}

export function usesWeb3Cosmos(content) {
    return content.includes('cosmos-');
}

export function randomElement(arr) {
    const index = Math.round(Math.random() * (arr.length - 1));
    return [arr[index], arr.slice(0, index).concat(arr.slice(index + 1))];
}
