import { BN } from 'bn.js';
import { ContractInstance, buildEwasmVmContext, hexToUint8Array } from "@ark-us/ewasmvm-js/dist/lib";
import { parseOpcodeLogs } from './utils';
import { uint8ArrayToHex } from 'ewasm-jsvm/src/utils';

let cache = {}
const DEFAULT_TX_INFO = {
    gasLimit: 10000000,
    gasPrice: 10,
    from: '0x79f379cebbd362c99af2765d1fa541415aa78508',
    value: 0,
}

export async function debugEwasmCode(ewasmFileList, txData, options) {
    if (!txData.data) txData.data = '0x';
    const ewasmBuff = await ewasmFileList[0].arrayBuffer();
    const tx = {
        ...DEFAULT_TX_INFO,
        ...txData,
    };

    let key = ethers.utils.id(JSON.stringify(txData) + ewasmFileList[0].name + ewasmFileList[0].lastModified);
    if (!txData || !key) key = Math.floor(Math.random() * 1000000);
    if (cache[key]) {
        return cache[key];
    }
    else {
        cache[key] = debugEwasmCodeInner(ewasmBuff, tx, options);
        return cache[key];
    }
}

export async function debugEwasmCodeInner(ewasmBuff, txData, options) {
    const vmContext = buildEwasmVmContext({});

    const txObj = {
      from: txData.from,
      value: new BN(0),
      gasLimit: new BN(5000000),
      gasPrice: new BN(10000),
      data: hexToUint8Array(txData.data),
    }
    const abi = [];

    const contract = new ContractInstance(vmContext, new Uint8Array(ewasmBuff), abi)

    let result;
    result = await contract.instantiate(txObj);
    if (txData.funcName === 'main') {
        result = await contract.main({...txObj, to: contract.address});
    }

    // move state 1 previous
    const firstLog = {name: '--', contractAddress: contract.address, input: [], output: [], context: {}, logs: [], stack: [], changed: {}, position: 0, gasCost: new BN(0), addlGasCost: new BN(0), refundedGas: new BN(0)};
    let opcodeLogs = [firstLog].concat(result.opcodeLogs);
    for (let i = 0; i < opcodeLogs.length - 1; i++) {
        opcodeLogs[i].stack = opcodeLogs[i + 1].stack;
        opcodeLogs[i].context = opcodeLogs[i + 1].context;
    }

    return parseOpcodeLogs(uint8ArrayToHex(result.result), opcodeLogs, txObj, {})
}
