import { assets, chains as cosmosChains, ibc } from 'chain-registry';
import { ChainId, ChainName, chains as evmChains } from 'eth-chains';

/*

interface Chain {
    name: string;
    shortName: string;
    chain: string; // subtype
    icon: string;
    infoURL: string;
    chainId: string;
    networkId: string;
    slip44: number;
    nativeCurrency: {name, symbol, decimals}
    rpc: [{url}]
    faucets: [{url}]
    explorers: [{name, url, standard, kind, tx_page}]
    network_type: string // mainnet, testnet
    status: string; // live
    bech32_prefix?: string
    hasCosmWasm: boolean
    hasEvm: boolean
}

*/

const eip155 = {testnet: {}, mainnet: {}};
const cosmos = {testnet: {}, mainnet: {}};

const allEvmChains = evmChains.all();
for (let chainid in allEvmChains) {
    const chain = allEvmChains[chainid];
    const networkType = JSON.stringify(chain).toLowerCase().includes('testnet') ? 'testnet' : 'mainnet';

    eip155[networkType][chainid.toString()] = {
        name: chain.name,
        shortName: chain.shortName,
        chain: chain.chain, // subtype
        icon: chain.icon,
        infoURL: chain.infoURL,
        chainId: chain.chainId,
        networkId: chain.networkId,
        slip44: chain.slip44,
        nativeCurrency: chain.nativeCurrency,
        apis: {
            rpc: chain.rpc.map(v => ({address: v})),
        },
        faucets: chain.faucets.map(v => ({url: v})),
        explorers: chain.explorers,
        network_type: networkType,
        status: 'live',
    }
}

eip155['mainnet'][7001] = {
    name: 'mythos',
    shortName: 'mythos',
    chain: 'mythos', // subtype
    icon: 'mythos',
    // infoURL: chain.infoURL,
    chainId: 7001,
    networkId: 7001,
    nativeCurrency: {name: 'MYT', symbol: 'aMYT', decimals: 18},
    apis: {
        rpc: [{address: 'https://mythos-evm.provable.dev'}]
    },
    faucets: [],
    explorers: [],
    network_type: 'mainnet',
    status: 'live',
}

function parseFeeForTaylor(fee) {
    return fee ? fee.toString() : "0";
}

cosmosChains.forEach(chain => {
    if (!cosmos[chain.network_type]) return;

    let fees = chain.fees;
    if (fees && fees.fee_tokens) {
        fees.fee_tokens[0].average_gas_price = parseFeeForTaylor(fees.fee_tokens[0].average_gas_price);
        fees.fee_tokens[0].fixed_min_gas_price = parseFeeForTaylor(fees.fee_tokens[0].fixed_min_gas_price);
        fees.fee_tokens[0].high_gas_price = parseFeeForTaylor(fees.fee_tokens[0].high_gas_price);
        fees.fee_tokens[0].low_gas_price = parseFeeForTaylor(fees.fee_tokens[0].low_gas_price);
    }

    cosmos[chain.network_type][chain.chain_name] = {
        name: chain.pretty_name,
        shortName: chain.chain_name,
        chain: chain.chain, // subtype
        // icon: chain.icon,
        infoURL: chain.codebase ? chain.codebase.git_repo : null,
        chainId: chain.chain_id,
        networkId: chain.chain_id,
        slip44: chain.slip44,
        fees: fees,
        apis: chain.apis,
        // faucets: chain.faucets.map(v => ({url: v})),
        explorers: chain.explorers,
        network_type: chain.network_type,
        status: chain.status,
        bech32_prefix: chain.bech32_prefix,
        hasCosmWasm: chain.codebase && chain.codebase.cosmwasm_enabled,
    }
})

assets.forEach(asset => {
    const nativeAsset = asset.assets[0];
    const currency = {
        ...nativeAsset,
        name: nativeAsset.base,
        symbol: nativeAsset.symbol,
    }
    if (cosmos.mainnet[asset.chain_name]) {
        cosmos.mainnet[asset.chain_name].nativeCurrency = currency;
    }
    if (cosmos.testnet[asset.chain_name]) {
        cosmos.testnet[asset.chain_name].nativeCurrency = currency;
    }
})

cosmos.mainnet.evmos.hasEvm = true;
cosmos.testnet.evmostestnet.hasEvm = true;

cosmos.mainnet.tgrade.hasCosmWasm = true;
cosmos.mainnet.juno.hasCosmWasm = true;
cosmos.mainnet.osmosis.hasCosmWasm = true;
cosmos.testnet.junotestnet.hasCosmWasm = true;
cosmos.testnet.cosmwasmtestnet.hasCosmWasm = true;
cosmos.testnet.osmosistestnet.hasCosmWasm = true;

const namespaceMap = {eip155, cosmos};
window.namespaceMap = namespaceMap;
export default namespaceMap;

export function toChainRegistryFormat(ourdata) {
    const data = {
        ...ourdata,
        chain_name: ourdata.shortName,
        pretty_name: ourdata.name,
        chain_id: ourdata.chainId || ourdata.networkId,
        staking: {
            staking_tokens: [{
              denom: ourdata.nativeCurrency.base,
            }],
        },
        fees: {
            fee_tokens: [{
              denom: ourdata.nativeCurrency.base,
            }],
        },
    }
    const assets = [
        {
            chain_name: data.chain_name,
            ...ourdata.nativeCurrency,
            coinMinimalDenom: ourdata.nativeCurrency.base,
        }
    ]
    return {data, assets}
}


/*


['name', 'chain', 'icon', 'rpc', 'faucets', 'nativeCurrency', 'infoURL', 'shortName', 'chainId', 'networkId', 'slip44', 'ens', 'explorers']

['$schema', 'chain_name', 'status', 'network_type', 'pretty_name', 'chain_id', 'bech32_prefix', 'daemon_name', 'node_home', 'genesis', 'key_algos', 'slip44', 'fees', 'codebase', 'peers', 'apis', 'explorers']

// {denom: 'aevmos', low_gas_price: 10000000000, average_gas_price: 25000000000, high_gas_price: 40000000000}

*/
