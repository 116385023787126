const typeSize = 16;
const typeMap = {
    uint: 'ffffffffffffffffffffffffffffff00',
    uint256: 'ffffffffffffffffffffffffffffff00',
    string: 'fffffffffffffffffffffffffffffe00',
    bytes: 'fffffffffffffffffffffffffffffd00',
    address: 'fffffffffffffffffffffffffffffc00',
    table: 'fffffffffffffffffffffffffffffb00',
    bool: 'fffffffffffffffffffffffffffffa00',
    uint8: 'fffffffffffffffffffffffffffff900',
    'uint128': 'fffffffffffffffffffffffffffff800',
    'uint256[]': 'ffffffffffffffffffffffffffffef00',
    'uint128[]': 'ffffffffffffffffffffffffffffee00',
    custom: (index, size) => index.toString(16).padStart((size || typeSize) * 2, '0'),
}
const typeMapR = Object.keys(typeMap).reduce((accum, key) => {
    accum[typeMap[key]] = key;
    return accum;
}, {});

const typeLengthMap = {
    uint: 32,
    int: 32,
    bool: 1,
    address: 20,
    string: 0,
    bytes: 0,
}

const CompilerFunctions = {
    salt: '',
    name: 'CompilerFunctions',
    keyLength: 2,
    isMulti: 13,
    rowSize: 2,
    fields: [
        {name: 'ipfsHash', type: typeMap.bytes, length: 32},
        {name: 'name', type: typeMap.string, length: 27},
        {name: 'type', type: typeMap.uint, length: 1},
        {name: 'inputSlots', type: typeMap.uint, length: 1},
        {name: 'outputSlots', type: typeMap.uint, length: 1},
        {name: 'bytecodeStart', type: typeMap.uint, length: 2},
    ],
}

const encodeMap = {
    uint: uint,
    string: encodeString,
    bytes: encodeBytes,
    address: encodeAddress,
}

// const decodeMap = {
//     uint: decodeUint,
//     string: decodeString,
//     bytes: decodeBytes,
//     address: decodeAddress,
// }

const encType32 = (typeValue) => typeValue.padEnd(64, '0');

function uint(value, sizeBytes = 32) {
    return value.toString(16).padStart(sizeBytes*2, '0');
}

function uint128(value) {
    return value.toString(16).padStart(32, '0');
}

function asciiToString (hexcode) {
    const chars = hexcode.split('');
    let str = '';
    for (let i = 0; i < chars.length; i += 2) {
        str += String.fromCharCode(parseInt(chars[i] + chars[i + 1], 16))
    }
    return str;
}

function stringToAscii (str) {
    return str.split('').map(v => uint(v.codePointAt(0), 1)).join('');
}

function encodeBytes (value, sizeBytes = 32) {
    value = value.slice(0, 2) === '0x' ? value.slice(2) : value;
    return '0x' + value.padEnd(sizeBytes * 2, '0');
}

function addressPad(value, sizeBytes) {
    value = value.slice(0, 2) === '0x' ? value.slice(2) : value;
    return '0x' + value.toLowerCase().padStart(sizeBytes * 2, '0');
}
function encodeAddress(...args) {return addressPad(...args).slice(2)};

function encodeString(value, sizeBytes) {
    let v = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(value)).slice(2);
    sizeBytes = sizeBytes || (v.length / 2);
    v = v.slice(0, sizeBytes * 2);
    return v.padEnd(sizeBytes * 2, '0');
}

function encodeValue(value, type, size) {
    if (typeof type === 'object') return encodeRecord(type, value);
    let f = encodeMap[typeMapR[type]];
    if (typeof f !== 'function') f = uint;
    return f(value, size);
}

function encodeArray(value, type, size) {
    const encoded = value.map(v => encodeValue(v, type, size)).join('');
    return uint(encoded.length / 2) + encoded;
}

function encodeRecord(fields, obj) {
    return fields.map(field => {
        let encoded;
        if (field.array) encoded = encodeArray(obj[field.name], field.type, field.length);
        else encoded = encodeValue(obj[field.name], field.type, field.length);
        return encoded.slice(0, 2) === '0x' ? encoded.slice(2) : encoded;
    }).join('').toLowerCase();
}

function encodeFields(fields) {
    let bstart = 0;
    return fields.map(fieldObj => {
        const encoded = encodeRecord(fieldsSet.fields,  {...fieldObj, bstart});
        bstart += fieldObj.length;
        return encoded;
    }).join('');
}

function encodeTypeRecord(obj) {
    obj.edsid = obj.edsid || 0;
    return encodeRecord([...typesSet.fields], obj);
}

function encodeTableSet ({salt, name, keyLength, isMulti, rowSize, count, nonce, fields}) {
    count = count || 0;
    nonce = nonce || 0;
    salt = salt.slice(0, 2) === '0x' ? salt.slice(2) : salt;
    const _fields = encodeFields(fields);
    return (salt
        + encodeString(name, 32) + encType32(typeMap.table)
        + uint(keyLength, 32) + uint(isMulti, 32) + uint(rowSize, 32)
        + uint(fields.length, 32)
        + uint(count, 32)
        + uint(nonce, 32)
        + _fields
    );
}

function encodeSetRecord (setObj) {
    const set = {
        nonce: 0,
        count: 0,
        type: typeMap.table,
        ...setObj,
        fieldsLength: setObj.fields.length,
    };
    const encodedSet = encodeRecord(setsSet.fields, set);
    const encodedFields = encodeFields(set.fields);
    return {set: encodedSet, fields: encodedFields};
}

function encodeCompilerFunctions(cfuncs = []) {
    // count 32B + function records +
    let offset = 32 + cfuncs.length * 2 * 32 + 32;
    cfuncs = cfuncs.map(cfunc => {
        cfunc.bytecodeStart = offset;
        offset += cfunc.bytecode.length / 2;
        return cfunc;
    });

    const encodedRecords = cfuncs.map(v => encodeRecord(CompilerFunctions.fields, v));
    const data = uint(cfuncs.length, 32) + encodedRecords.join('') + uint(0, 32) + cfuncs.map(v => v.bytecode).join('');
    return data;
}

export {
    encodeCompilerFunctions,
}
