const coinAbi = {
    "name": "amount",
    "type": "tuple",
    "internalType": "Coin",
    "components": [
        {
            "name": "amount",
            "type": "string"
        },
        {
            "name": "denom",
            "type": "string"
        }
    ]
}

const coinsAbi = {
    ...coinAbi,
    "name": "coins",
    "type": "tuple[]",
    "internalType": "Coin[]",
}

export const feeAbi = {
    "name": "fee",
    "type": "tuple",
    "internalType": "Fee",
    "components": [
        {
            "name": "gas",
            "type": "string"
        },
        {...coinsAbi, name: "amount"},
    ]
}

export const feeFunctionAbi = {
    "name": "fee",
    "constant": true,
    "payable": false,
    "stateMutability": "view",
    "type": "function",
    "inputs": [
        {
            "name": "gas",
            "type": "string"
        },
        {...coinsAbi, name: "amount"},
    ]
}

export const TextProposal = {
    "name": "TextProposal",
    "type": "tuple",
    "internalType": "TextProposal",
    "components": [
        {
            "name": "title",
            "type": "string"
        },
        {
            "name": "description",
            "type": "string"
        },
    ]
}

export const CommunityPoolSpend = {
    "name": "CommunityPoolSpend",
    "type": "tuple",
    "internalType": "CommunityPoolSpend",
    "components": [
        {
            "name": "title",
            "type": "string"
        },
        {
            "name": "description",
            "type": "string"
        },
        {
            "name": "recipient",
            "type": "string"
        },
        {...coinAbi, name: "amount"},
    ]
}

export const ParamsChangeProposal = {
    "name": "ParamsChangeProposal",
    "type": "tuple",
    "internalType": "ParamsChangeProposal",
    "components": [
        {
            "name": "title",
            "type": "string"
        },
        {
            "name": "description",
            "type": "string"
        },
        {
            "name": "changes",
            "type": "tuple",
            "internalType": "tuple",
            "components": [
                {
                    "name": "subspace",
                    "type": "string"
                },
                {
                    "name": "key",
                    "type": "string"
                },
                {
                    "name": "value",
                    "type": "string"
                },
            ]
        },
        {...coinAbi, name: "deposit"},
    ]
}

const ValidatorDescription = {
    "name": "description",
    "type": "tuple",
    "internalType": "Description",
    "components": [
        {
            "name": "moniker",
            "type": "string"
        },
        {
            "name": "identity",
            "type": "string"
        },
        {
            "name": "website",
            "type": "string"
        },
        {
            "name": "securityContact",
            "type": "string"
        },
        {
            "name": "details",
            "type": "string"
        },
    ]
}

const bank = [
    {
        "name": "getBalance",
        "typeUrl": "/cosmos.bank.v1beta1.Query/Balance",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "address",
                "type": "string"
            },
            {
                "name": "denom",
                "type": "string"
            },
        ],
        "outputs": [
            {...coinAbi},
        ],
    },
    {
        "name": "getAllBalances",
        "typeUrl": "/cosmos.bank.v1beta1.Query/AllBalances",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "address",
                "type": "string"
            },
        ],
        "outputs": [
            {...coinsAbi},
        ],
    },
    {
        "name": "getSpendableBalances",
        "typeUrl": "/cosmos.bank.v1beta1.Query/SpendableBalances",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "address",
                "type": "string"
            },
        ],
        "outputs": [
            {...coinsAbi},
        ],
    },
    {
        "name": "getTotalSupply",
        "typeUrl": "/cosmos.bank.v1beta1.Query/TotalSupply",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
        ],
        "outputs": [
            {...coinsAbi, name: "supply"},
        ],
    },
    {
        "name": "getSupplyOf",
        "typeUrl": "/cosmos.bank.v1beta1.Query/SupplyOf",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "denom",
                "type": "string"
            },
        ],
        "outputs": [
            {...coinAbi},
        ],
    },
    {
        "name": "getParams",
        "typeUrl": "/cosmos.bank.v1beta1.Query/Params",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "subspace",
                "type": "string"
            },
            {
                "name": "key",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "params",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDenomsMetadata",
        "typeUrl": "/cosmos.bank.v1beta1.Query/DenomsMetadata",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
        ],
        "outputs": [
            {
                "name": "metadata",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDenomMetadata",
        "typeUrl": "/cosmos.bank.v1beta1.Query/DenomMetadata",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "denom",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "metadata",
                "type": "any"
            },
        ],
    },
    // {
    //     "name": "getDenomOwners",
    //     "typeUrl": "/cosmos.bank.v1beta1.Query/DenomOwners",
    //     "constant": true,
    //     "payable": false,
    //     "stateMutability": "view",
    //     "type": "function",
    //     "inputs": [
    //     ],
    //     "outputs": [
    //     ],
    // },
    // {
    //     "name": "getSendEnabled",
    //     "typeUrl": "/cosmos.bank.v1beta1.Query/SendEnabled",
    //     "constant": true,
    //     "payable": false,
    //     "stateMutability": "view",
    //     "type": "function",
    //     "inputs": [
    //     ],
    //     "outputs": [
    //     ],
    // },
    {
        "name": "send",
        "typeUrl": "/cosmos.bank.v1beta1.MsgSend",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "fromAddress",
                "type": "string"
            },
            {
                "name": "toAddress",
                "type": "string"
            },
            {...coinsAbi, name: "amount"},
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    // {
    //     "name": "multiSend",
    //     "typeUrl": "/cosmos.bank.v1beta1.MsgMultiSend",
    //     "constant": false,
    //     "payable": true,
    //     "stateMutability": "payable",
    //     "type": "function",
    //     "inputs": [
    //         {
    //             "name": "recipientAddress",
    //             "type": "string"
    //         },
    //         {...coinAbi, name: "amount"},
    //     ],
    //     "outputs": [
    //         {
    //             "name": "receipt",
    //             "type": "string"
    //         }
    //     ],
    // },
    // {
    //     "name": "updateParams",
    //     "typeUrl": "/cosmos.bank.v1beta1.MsgUpdateParams",
    //     "constant": false,
    //     "payable": true,
    //     "stateMutability": "payable",
    //     "type": "function",
    //     "inputs": [
    //     ],
    //     "outputs": [
    //         {
    //             "name": "receipt",
    //             "type": "string"
    //         }
    //     ],
    // },
    // {
    //     "name": "setSendEnabled",
    //     "typeUrl": "/cosmos.bank.v1beta1.MsgSetSendEnabled",
    //     "constant": false,
    //     "payable": true,
    //     "stateMutability": "payable",
    //     "type": "function",
    //     "inputs": [
    //     ],
    //     "outputs": [
    //         {
    //             "name": "receipt",
    //             "type": "string"
    //         }
    //     ],
    // },
]

const staking = [
    {
        "name": "getValidators",
        "typeUrl": "/cosmos.staking.v1beta1.Query/Validators",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "status",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "validators",
                "type": "any"
            },
        ],
    },
    {
        "name": "getValidator",
        "typeUrl": "/cosmos.staking.v1beta1.Query/Validator",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "validatorAddr",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "validator",
                "type": "any"
            },
        ],
    },
    {
        "name": "getValidatorDelegations",
        "typeUrl": "/cosmos.staking.v1beta1.Query/ValidatorDelegations",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "validatorAddr",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "delegationResponses",
                "type": "string"
            },
        ],
    },
    {
        "name": "getValidatorUnbondingDelegations",
        "typeUrl": "/cosmos.staking.v1beta1.Query/ValidatorUnbondingDelegations",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "validatorAddr",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "unbondingResponses",
                "type": "string"
            },
        ],
    },
    {
        "name": "getDelegation",
        "typeUrl": "/cosmos.staking.v1beta1.Query/Delegation",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddr",
                "type": "string"
            },
            {
                "name": "validatorAddr",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "delegationResponse",
                "type": "any"
            },
        ],
    },
    {
        "name": "getUnbondingDelegation",
        "typeUrl": "/cosmos.staking.v1beta1.Query/UnbondingDelegation",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddr",
                "type": "string"
            },
            {
                "name": "validatorAddr",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "unbond",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDelegatorDelegations",
        "typeUrl": "/cosmos.staking.v1beta1.Query/DelegatorDelegations",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddr",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "delegationResponses",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDelegatorUnbondingDelegations",
        "typeUrl": "/cosmos.staking.v1beta1.Query/DelegatorUnbondingDelegations",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddr",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "unbondingResponses",
                "type": "any"
            },
        ],
    },
    {
        "name": "getRedelegations",
        "typeUrl": "/cosmos.staking.v1beta1.Query/Redelegations",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddr",
                "type": "string"
            },
            {
                "name": "srcValidatorAddr",
                "type": "string"
            },
            {
                "name": "dstValidatorAddr",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "redelegationResponses",
                "type": "string"
            },
        ],
    },
    {
        "name": "getDelegatorValidators",
        "typeUrl": "/cosmos.staking.v1beta1.Query/DelegatorValidators",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddr",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "validators",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDelegatorValidator",
        "typeUrl": "/cosmos.staking.v1beta1.Query/DelegatorValidator",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddr",
                "type": "string"
            },
            {
                "name": "validatorAddr",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "validator",
                "type": "any"
            },
        ],
    },
    // {
    //     "name": "getHistoricalInfo",
    //     "typeUrl": "/cosmos.staking.v1beta1.Query/HistoricalInfo",
    //     "constant": true,
    //     "payable": false,
    //     "stateMutability": "view",
    //     "type": "function",
    //     "inputs": [
    //         {
    //             "name": "height",
    //             "type": "Long"
    //         },
    //     ],
    //     "outputs": [

    //     ],
    // },
    {
        "name": "getPool",
        "typeUrl": "/cosmos.staking.v1beta1.Query/Pool",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
        ],
        "outputs": [
            {
                "name": "pool",
                "type": "any"
            },
        ],
    },
    {
        "name": "getParams",
        "typeUrl": "/cosmos.staking.v1beta1.Query/Params",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
        ],
        "outputs": [
            {
                "name": "params",
                "type": "any"
            },
        ],
    },
    {
        "name": "createValidator",
        "typeUrl": "/cosmos.staking.v1beta1.MsgCreateValidator",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {...ValidatorDescription},
            {
                "name": "commission",
                "type": "tuple",
                "internalType": "CommissionRates",
                "components": [
                    {
                        "name": "rate",
                        "type": "string"
                    },
                    {
                        "name": "maxRate",
                        "type": "string"
                    },
                    {
                        "name": "maxChangeRate",
                        "type": "string"
                    },
                ]
            },
            {
                "name": "minSelfDelegation",
                "type": "string"
            },
            {
                "name": "delegatorAddress",
                "type": "string"
            },
            {
                "name": "validatorAddress",
                "type": "string"
            },
            {
                "name": "pubkey",
                "type": "any"
            },
            {
                ...coinAbi,
                "name": "value",
            }
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "editValidator",
        "typeUrl": "/cosmos.staking.v1beta1.MsgEditValidator",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {...ValidatorDescription},
            {
                "name": "validatorAddress",
                "type": "string"
            },
            {
                "name": "commissionRate",
                "type": "string"
            },
            {
                "name": "minSelfDelegation",
                "type": "string"
            }
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "delegate",
        "typeUrl": "/cosmos.staking.v1beta1.MsgDelegate",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddress",
                "type": "string"
            },
            {
                "name": "validatorAddress",
                "type": "string"
            },
            {...coinAbi, name: "amount"},
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "undelegate",
        "typeUrl": "/cosmos.staking.v1beta1.MsgUndelegate",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddress",
                "type": "string"
            },
            {
                "name": "validatorAddress",
                "type": "string"
            },
            {...coinAbi, name: "amount"},
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "beginRedelegate",
        "typeUrl": "/cosmos.staking.v1beta1.MsgBeginRedelegate",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddress",
                "type": "string"
            },
            {
                "name": "validatorSrcAddress",
                "type": "string"
            },
            {
                "name": "validatorDstAddress",
                "type": "string"
            },
            {...coinAbi, name: "amount"},
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    // {
    //     "name": "cancelUnbondingDelegation",
    //     "typeUrl": "/cosmos.staking.v1beta1.MsgCancelUnbondingDelegation",
    //     "constant": false,
    //     "payable": true,
    //     "stateMutability": "payable",
    //     "type": "function",
    //     "inputs": [
    //     ],
    //     "outputs": [
    //         {
    //             "name": "receipt",
    //             "type": "string"
    //         }
    //     ],
    // },
    // {
    //     "name": "updateParams",
    //     "typeUrl": "/cosmos.staking.v1beta1.MsgUpdateParams",
    //     "constant": false,
    //     "payable": false,
    //     "stateMutability": "non-payable",
    //     "type": "function",
    //     "inputs": [
    //     ],
    //     "outputs": [
    //         {
    //             "name": "receipt",
    //             "type": "string"
    //         }
    //     ],
    // },
]

const distribution = [
    {
        "name": "getParams",
        "typeUrl": "/cosmos.distribution.v1beta1.Query/Params",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "subspace",
                "type": "string"
            },
            {
                "name": "key",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "params",
                "type": "any"
            },
        ],
    },
    // {
    //     "name": "getValidatorDistributionInfo",
    //     "typeUrl": "/cosmos.distribution.v1beta1.Query/ValidatorDistributionInfo",
    //     "constant": true,
    //     "payable": false,
    //     "stateMutability": "view",
    //     "type": "function",
    //     "inputs": [
    //     ],
    //     "outputs": [

    //     ],
    // },
    {
        "name": "getValidatorOutstandingRewards",
        "typeUrl": "/cosmos.distribution.v1beta1.Query/ValidatorOutstandingRewards",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "validatorAddress",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "rewards",
                "type": "any"
            },
        ],
    },
    {
        "name": "getValidatorCommission",
        "typeUrl": "/cosmos.distribution.v1beta1.Query/ValidatorCommission",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "validatorAddress",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "commission",
                "type": "any"
            },
        ],
    },
    {
        "name": "getValidatorSlashes",
        "typeUrl": "/cosmos.distribution.v1beta1.Query/ValidatorSlashes",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "validatorAddress",
                "type": "string"
            },
            {
                "name": "startingHeight",
                "type": "string"
            },
            {
                "name": "endingHeight",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "slashes",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDelegationRewards",
        "typeUrl": "/cosmos.distribution.v1beta1.Query/DelegationRewards",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddress",
                "type": "string"
            },
            {
                "name": "validatorAddress",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "rewards",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDelegationTotalRewards",
        "typeUrl": "/cosmos.distribution.v1beta1.Query/DelegationTotalRewards",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddress",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "rewards",
                "type": "any"
            },
            {
                "name": "total",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDelegatorValidators",
        "typeUrl": "/cosmos.distribution.v1beta1.Query/DelegatorValidators",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddress",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "validators",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDelegatorWithdrawAddress",
        "typeUrl": "/cosmos.distribution.v1beta1.Query/DelegatorWithdrawAddress",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddress",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "withdrawAddress",
                "type": "string"
            },
        ],
    },
    {
        "name": "getCommunityPool",
        "typeUrl": "/cosmos.distribution.v1beta1.Query/CommunityPool",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
        ],
        "outputs": [
            {
                "name": "pool",
                "type": "string"
            },
        ],
    },
    {
        "name": "setWithdrawAddress",
        "typeUrl": "/cosmos.distribution.v1beta1.MsgSetWithdrawAddress",
        "constant": false,
        "payable": false,
        "stateMutability": "non-payable",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddress",
                "type": "string"
            },
            {
                "name": "withdrawAddress",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "withdrawDelegatorReward",
        "typeUrl": "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "delegatorAddress",
                "type": "string"
            },
            {
                "name": "validatorAddress",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "withdrawValidatorCommission",
        "typeUrl": "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "validatorAddress",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "fundCommunityPool",
        "typeUrl": "/cosmos.distribution.v1beta1.MsgFundCommunityPool",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {...coinsAbi},
            {
                "name": "depositor",
                "type": "string"
            },

        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "updateParams",
        "typeUrl": "/cosmos.distribution.v1beta1.MsgUpdateParams",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "createCommunityPoolSpendProposal",
        "typeUrl": "/cosmos.distribution.v1beta1.CommunityPoolSpendProposal",
        "constant": true,
        "payable": false,
        "stateMutability": "pure",
        "type": "function",
        "inputs": [
            ...CommunityPoolSpend.components,
        ],
        "outputs": [],
    },
]

const gov = [
    {
        "name": "getProposal",
        "typeUrl": "/cosmos.gov.v1beta1.Query/Proposal",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "proposalId",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "proposal",
                "type": "any"
            },
        ],
    },
    {
        "name": "getProposals",
        "typeUrl": "/cosmos.gov.v1beta1.Query/Proposals",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "proposalStatus",
                "type": "number"
            },
            {
                "name": "voter",
                "type": "string"
            },
            {
                "name": "depositor",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "proposals",
                "type": "any"
            },
        ],
    },
    {
        "name": "getVote",
        "typeUrl": "/cosmos.gov.v1beta1.Query/Vote",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "proposalId",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "vote",
                "type": "any"
            },
        ],
    },
    {
        "name": "getVotes",
        "typeUrl": "/cosmos.gov.v1beta1.Query/Votes",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "proposalId",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "votes",
                "type": "any"
            },
        ],
    },
    {
        "name": "getParams",
        "typeUrl": "/cosmos.gov.v1beta1.Query/Params",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "paramsType",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "votingParams",
                "type": "any"
            },
            {
                "name": "depositParams",
                "type": "any"
            },
            {
                "name": "tallyParams",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDeposit",
        "typeUrl": "/cosmos.gov.v1beta1.Query/Deposit",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "proposalId",
                "type": "string"
            },
            {
                "name": "depositor",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "deposit",
                "type": "any"
            },
        ],
    },
    {
        "name": "getDeposits",
        "typeUrl": "/cosmos.gov.v1beta1.Query/Deposits",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "proposalId",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "deposits",
                "type": "any"
            },
        ],
    },
    {
        "name": "getTallyResult",
        "typeUrl": "/cosmos.gov.v1beta1.Query/TallyResult",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "proposalId",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "tally",
                "type": "any"
            },
        ],
    },
    {
        "name": "submitProposal",
        "typeUrl": "/cosmos.gov.v1beta1.MsgSubmitProposal",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "content",
                "type": "string",
                // "type": "tuple",
                // "internalType": "Proposal",
                // "components": [
                //     {
                //         "name": "typeUrl",
                //         "type": "string"
                //     },
                //     {
                //         "name": "value", // hex/uint8array
                //         "type": "string"
                //     },
                // ]
            },
            {
                "name": "proposer",
                "type": "string"
            },
            {...coinAbi, name: "initialDeposit"},
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "vote",
        "typeUrl": "/cosmos.gov.v1beta1.MsgVote",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "proposalId",
                "type": "string"
            },
            {
                "name": "voter",
                "type": "string"
            },
            {
                "name": "option",
                "type": "string",
                "options": [
                    "VOTE_OPTION_UNSPECIFIED",
                    "VOTE_OPTION_YES",
                    "VOTE_OPTION_ABSTAIN",
                    "VOTE_OPTION_NO",
                    "VOTE_OPTION_NO_WITH_VETO",
                ]
            },
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "voteWeighted",
        "typeUrl": "/cosmos.gov.v1beta1.MsgVoteWeighted",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "proposalId",
                "type": "string",
            },
            {
                "name": "voter",
                "type": "string",
            },
            {
                "name": "options",
                "type": "tuple",
                "internalType": "WeightedVoteOption",
                "components": [
                    {
                        "name": "option",
                        "type": "number"
                    },
                    {
                        "name": "weight",
                        "type": "string"
                    }
                ]
            },
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "submitDeposit",
        "typeUrl": "/cosmos.gov.v1beta1.MsgDeposit",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "proposalId",
                "type": "string"
            },
            {
                "name": "depositor",
                "type": "string"
            },
            {...coinAbi, name: "amount"},
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "createTextProposal",
        "typeUrl": "/cosmos.gov.v1beta1.TextProposal",
        "constant": true,
        "payable": false,
        "stateMutability": "pure",
        "type": "function",
        "inputs": [
            ...TextProposal.components,
        ],
        "outputs": [],
    },
]

const slashing = [
    {
        "name": "getParams",
        "typeUrl": "/cosmos.slashing.v1beta1.Query/Params",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "subspace",
                "type": "string"
            },
            {
                "name": "key",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "param",
                "type": "any"
            },
        ],
    },
    {
        "name": "getSigningInfo",
        "typeUrl": "/cosmos.slashing.v1beta1.Query/SigningInfo",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "consAddress",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "valSigningInfo",
                "type": "any"
            },
        ],
    },
    {
        "name": "getSigningInfos",
        "typeUrl": "/cosmos.slashing.v1beta1.Query/SigningInfos",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
        ],
        "outputs": [
            {
                "name": "info",
                "type": "string"
            },
        ],
    },
    {
        "name": "unjail",
        "typeUrl": "/cosmos.slashing.v1beta1.MsgUnjail",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "validatorAddr",
                "type": "string"
            }
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    // {
    //     "name": "updateParams",
    //     "typeUrl": "/cosmos.slashing.v1beta1.MsgUpdateParams",
    //     "constant": false,
    //     "payable": true,
    //     "stateMutability": "payable",
    //     "type": "function",
    //     "inputs": [
    //     ],
    //     "outputs": [
    //         {
    //             "name": "receipt",
    //             "type": "string"
    //         }
    //     ],
    // },
]

const params = [
    {
        "name": "getParams",
        "typeUrl": "/cosmos.params.v1beta1.Query/Params",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "subspace",
                "type": "string"
            },
            {
                "name": "key",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "param",
                "type": "any"
            }
        ],
    },
    {
        "name": "getSubspaces",
        "typeUrl": "/cosmos.params.v1beta1.Query/Subspaces",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
        ],
        "outputs": [
            {
                "name": "subspaces",
                "type": "any"
            }
        ],
    },
]

const ibc = [
    {
        "name": "sendIbcTokens",
        // "typeUrl": "/cosmos.params.v1beta1.Query/Params",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "recipientAddress",
                "type": "string"
            },
            {...coinAbi, name: "transferAmount"},
            {
                "name": "sourcePort",
                "type": "string"
            },
            {
                "name": "sourceChannel",
                "type": "string"
            },
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
]

const ethermint = [
    {
        "name": "MsgEthereumTx",
        "typeUrl": "/ethermint.evm.v1.MsgEthereumTx",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "data",
                "type": "string"
            },
            {
                "name": "from",
                "type": "string"
            }
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "EthCall",
        "typeUrl": "/ethermint.evm.v1.Query/EthCall",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "args",
                "type": "string"
            },
            {
                "name": "gasCap",
                "type": "uint256"
            },
        ],
        "outputs": [
            {
                "name": "data",
                "type": "any"
            },
        ],
    },
    // /ethermint.evm.v1.Query/EstimateGas
]

const mythos = [
    {
        "name": "MsgStoreCode",
        "typeUrl": "/mythos.wasmx.v1.MsgStoreCode",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "sender",
                "type": "string"
            },
            {
                "name": "WasmByteCode",
                "type": "string"
            }
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "MsgDeployCode",
        "typeUrl": "/mythos.wasmx.v1.MsgDeployCode",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "sender",
                "type": "string"
            },
            {
                "name": "EvmByteCode",
                "type": "string"
            }
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "MsgInstantiateContract",
        "typeUrl": "/mythos.wasmx.v1.MsgInstantiateContract",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "sender",
                "type": "string"
            },
            {
                "name": "codeId",
                "type": "uint256"
            },
            {
                "name": "label",
                "type": "string"
            },
            {
                "name": "msg",
                "type": "string"
            },
            {...coinsAbi, name: "funds"},
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "MsgExecuteContract",
        "typeUrl": "/mythos.wasmx.v1.MsgExecuteContract",
        "constant": false,
        "payable": true,
        "stateMutability": "payable",
        "type": "function",
        "inputs": [
            {
                "name": "sender",
                "type": "string"
            },
            {
                "name": "contract",
                "type": "string"
            },
            {
                "name": "msg",
                "type": "string"
            },
            {...coinsAbi, name: "funds"},
            {
                "name": "dependencies",
                "type": "string[]",
                "internalType": "string[]",
            },
        ],
        "outputs": [
            {
                "name": "receipt",
                "type": "string"
            }
        ],
    },
    {
        "name": "CodeInfo",
        "typeUrl": "/mythos.wasmx.v1.Query/CodeInfo",
        "constant": true,
        "payable": false,
        "stateMutability": "view",
        "type": "function",
        "inputs": [
            {
                "name": "code_id",
                "type": "uint256"
            },
        ],
        "outputs": [
            {
                "name": "data",
                "type": "any"
            },
        ],
    },
]

const cosmos = {
    bank,
    distribution,
    staking,
    gov,
    slashing,
    params,
    ibc,
    ethermint,
    fee: feeFunctionAbi,
    mythos,
}

window.cosmosAbis = cosmos;
export default cosmos;
