
async function init () {
    const workers = {};
    const workersIndex = {};
    let workersCount = 0;

    const extensions = {
        label: 'worker',
        items: [
            {
                label: 'new',
                value: (url, options = {}) => {
                    const index = workersCount;
                    workers[url] = createWorker(url, options);
                    if (!workers[url]) return;
                    workersIndex[index] = url;
                    workersCount ++;
                    return index;
                },
            },
            {
                label: 'postMessage',
                value: (index, message) => {
                    // {type: 'execute', method: "", inputs: []}
                    if (workersIndex[index] && workers[workersIndex[index]]) {
                        workers[workersIndex[index]].postMessage(message);
                        return true;
                    }
                    return false;
                },
            },
            {
                label: 'listen',
                value: (index, callType, callMethod, callback) => {
                    if (workersIndex[index] && workers[workersIndex[index]]) {
                        return workers[workersIndex[index]].addEventListener('message', function (e) {
                            console.log('-----worker sent output', e);
                            const {output, type, method} = e.data;
                            if (type === callType && method == callMethod) {
                                callback(output);
                            }
                        });
                    }
                    return false;
                },
            },
        ]
    }

    return {extensions};
}

export default init;

function createWorker (workerUrl, options = {}) {
	var worker = null;
	try {
		worker = new Worker(workerUrl, options);
	} catch (e) {
        console.log('createWorker e', e);
		try {
			var blob;
			try {
				blob = new Blob(["importScripts('" + workerUrl + "');"], { "type": 'application/javascript' });
			} catch (e1) {
                console.log('createWorker e1', e1);
				var blobBuilder = new (window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder)();
				blobBuilder.append("importScripts('" + workerUrl + "');");
				blob = blobBuilder.getBlob('application/javascript');
			}
			var url = window.URL || window.webkitURL;
			var blobUrl = url.createObjectURL(blob);
            console.log('createWorker blobUrl', blobUrl);
			worker = new Worker(blobUrl, options);
		} catch (e2) {
			//if it still fails, there is nothing much we can do
            console.error('createWorker e2', e2);
            return;
		}
	}
    console.info("Worker created for ", workerUrl);
	return worker;
}
