import React from 'react';
import { Menu } from 'antd';
import {Icon} from './icon';
const { SubMenu } = Menu;

async function init () {
    const extensions = {
        label: 'menu',
        items: [
            {
                label: '',
                value: ({items}) => {
                    const components = items.map((item, i) => recursiveMenuBuild(item, [i]));

                    return <Menu>{components}</Menu>
                },
            },
            {
                label: 'button',
                value: (props, children) => {
                    return <ButtonMenu {...props} children={children}/>
                },
            },
        ]
    }
    return {extensions};
}

export default init;

function ButtonMenu (props) {
    let {items, btn, style = {}} = props;
    const [open, setState] = React.useState(false);
    items = items || [];

    const menuFixed = {
        position: 'fixed',
        top: '38px',
        zIndex: '1',
        width: '200px',
    }
    if (style.float === 'right') menuFixed.right = '0px';
    else menuFixed.left = '0px';

    const components = items.map((item, i) => recursiveMenuBuild(item, [i]));
    const menu = open ? <Menu style={menuFixed}>{components}</Menu> : <></>;
    let _button;

    const onClick = () => {
        setState(!open);
    }

    if (props.children) {
        _button = <div style={{display: 'inline', ...style}} onClick={onClick}>{props.children}</div>
    } else {
        _button = <button {...btn} onClick={onClick}>{btn.title}</button>
    }

    return (
        <>
            {_button}
            {menu}
        </>
    )
}

function recursiveMenuBuild (item, indexes = []) {
    if (!item.items) return menuItem(item, indexes);
    else return menuLevel(item, indexes);
}

function menuItem (node, indexes) {
    const onClick = ({ item, key, keyPath }) => {
        if (node.onClick) node.onClick();
    }
    const label = node.label;
    if (node.imageIcon) {
        const icon = <Icon title={node.imageIcon || "function"} style={{ fontSize: '16px', marginLeft: 10 }} theme="outlined" onClick={node.imageOnClick}/>
        return (
            <Menu.Item
                key={indexes.join('_')}
                onClick={onClick}
            >
                <div>{label} {icon}</div>
            </Menu.Item>
        )
    }
    return (
        <Menu.Item
            key={indexes.join('_')}
            onClick={onClick}
        >
            {label}
        </Menu.Item>
    )
}

function menuLevel (node, indexes) {
    if (!node.items) return menuItem(node, indexes);
    const items = node.items.map((subitem, i) => {
        const newindexes = indexes.concat([i]);
        return recursiveMenuBuild(subitem, newindexes);
    });
    const label = node.label;

    if (node.imageIcon) {
        const icon = <Icon title={node.imageIcon || "function"}
            style={{ fontSize: '16px', marginLeft: 10, marginTop: 10, marginRight: 20,
            float: "right" }}
            theme="outlined"
            onClick={node.imageOnClick}
        />
        return (
            <SubMenu
                key={indexes.join('_')}
                title={
                    <>
                        <div>{label} {icon}</div>
                    </>
                }
            >
                {items}
            </SubMenu>
        )
    }

    return (
        <SubMenu
            key={indexes.join('_')}
            title={
                <>
                    <span>{label}</span>
                </>
            }
        >
            {items}
        </SubMenu>
    )
}
