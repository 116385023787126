import { useEffect, useState } from 'react';
import * as Ipfs from 'helia';
import { noise } from '@chainsafe/libp2p-noise'
import { yamux } from '@chainsafe/libp2p-yamux'
import { webSockets } from '@libp2p/websockets'
import { webRTCStar } from '@libp2p/webrtc-star'
import { webTransport } from '@libp2p/webtransport'
import { MemoryBlockstore } from 'blockstore-core'
import { MemoryDatastore } from 'datastore-core'
import { createLibp2p } from 'libp2p'
import { identifyService } from 'libp2p/identify'
// import { bootstrap } from '@libp2p/bootstrap'

let ipfs = null
window.Ipfs = Ipfs;

async function createNode () {
    // the blockstore is where we store the blocks that make up files
    const blockstore = new MemoryBlockstore()

    // application-specific data lives in the datastore
    const datastore = new MemoryDatastore()

    // libp2p is the networking layer that underpins Helia
    const libp2p = await getLibp2p({datastore});

    return await Ipfs.createHelia({
      datastore,
      blockstore,
      libp2p
    })
}

export async function startIpfs () {
    let _error;
    if (ipfs) {
        console.log('IPFS already started')
    } else if (window.ipfs && window.ipfs.enable) {
        console.log('Found window.ipfs')
        ipfs = await window.ipfs.enable({ commands: ['id'] })
    } else if (window.crypto.subtle) {
        try {
            console.time('IPFS Started')
            ipfs = await createNode()
            console.timeEnd('IPFS Started')
        } catch (error) {
            console.error('IPFS init error:', error)
            ipfs = null
            _error = error;
        }
        return {ready: Boolean(ipfs), ipfs, error: _error};
    }

    return {ready: true, ipfs: {}, error: 'No WebCrypto'};
}

export function ipfsCleanup () {
    if (ipfs && ipfs.stop) {
        console.log('Stopping IPFS')
        ipfs.stop().catch(err => console.error(err))
        ipfs = null
    }
}

export function useIpfsFactory () {
    const [isIpfsReady, setIpfsReady] = useState(Boolean(ipfs))
    const [ipfsInitError, setIpfsInitError] = useState(null)

    useEffect(() => {
        const {error, ready} = startIpfs();
        if (error) setIpfsInitError(error);
        setIpfsReady(ready);

        return function cleanup () {
            ipfsCleanup();
            setIpfsReady(false);
        }
}, [])

  return { ipfs, isIpfsReady, ipfsInitError }
}

async function getLibp2p ({ datastore }) {
    const webRtcStar = webRTCStar()

    return await createLibp2p({
      datastore,
    //   addresses: {
    //     listen: [
    //       '/webrtc'
    //     ],
    //   },
      transports: [
        webTransport(),
        webSockets(),
        // webRTC(),
        webRtcStar.transport
      ],
      connectionEncryption: [
        noise()
      ],
      streamMuxers: [
        yamux()
      ],
      services: {
        identify: identifyService()
      },
        peerDiscovery: [
            webRtcStar.discovery,
            // bootstrap({
            //     list: [
            //       '/dnsaddr/bootstrap.io/p2p/QmBootstrap1',
            //       '/dnsaddr/bootstrap.io/p2p/QmBootstrap2'
            //       // etc
            //     ]
            // })
      ],
      connectionManager: {
        maxParallelDials: 150, // 150 total parallel multiaddr dials
        maxDialsPerPeer: 4, // Allow 4 multiaddrs to be dialed per peer in parallel
        dialTimeout: 10e3, // 10 second dial timeout per peer dial
        autoDial: true
      },
      nat: {
        enabled: false
      },
    })
}
