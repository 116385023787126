import {createClient} from '@remixproject/plugin-iframe';

async function init () {
    let remixClient;

    async function initRemix() {
        if (!remixClient) {
            remixClient = createClient();
            await remixClient.onload();
        }
        console.log('remixClient', remixClient);
    }

    const extensions = {
        label: 'remix',
        items: [
            {
                label: 'connect',
                value: () => {
                    return initRemix();
                },
            },
            {
                label: 'fileManager-getCurrentFile',
                value: () => {
                    return remixClient.fileManager.getCurrentFile();
                },
            },
            {
                label: 'fileManager-getFile',
                value: (fileName) => {
                    return remixClient.fileManager.getFile(fileName);
                },
            },
            {
                label: 'fileManager-on-currentFileChanged',
                value: (callback) => {
                    remixClient.fileManager.on('currentFileChanged', (fileName) => {
                        callback(fileName);
                    });
                },
            },
            {
                label: 'network-on-providerChanged',
                value: (callback) => {
                    remixClient.network.on('providerChanged', (provider) => {
                        console.log("remix providerChanged", provider);
                        callback(provider);
                    });
                },
            },
            {
                label: 'network-getNetworkProvider',
                value: () => {
                    return remixClient.network.getNetworkProvider();
                },
            },
            {
                label: 'udapp-getAccounts',
                value: () => {
                    return remixClient.udapp.getAccounts()
                },
            },
            {
                label: 'udapp-sendTransaction',
                value: (transaction) => {
                    return remixClient.udapp.sendTransaction(transaction);
                },
            },
            {
                label: 'udapp-createVMAccount',
                value: (privateKey, balance) => {
                    return remixClient.udapp.createVMAccount({ privateKey, balance });
                },
            },
            {
                label: 'udapp-on-newTransaction',
                value: (callback) => {
                    remixClient.udapp.on('newTransaction', (value) => {
                        callback(value);
                    });
                },
            },
            {
                label: 'solidity-compileWithParameters',
                value: (contract, settings) => {
                    return remixClient.call('solidity', 'compileWithParameters', contract, settings);
                },
            },
            {
                label: 'solidity-compile',
                value: (...args) => {
                    return remixClient.call('solidity', 'compile', ...args);
                },
            },
            {
                label: 'solidity-on-compilationFinished',
                value: (callback) => {
                    // target, source, version, data
                    remixClient.on('solidity', 'compilationFinished', (...args) => {
                        callback(...args);
                    });
                },
            },
            {
                label: 'call',
                value: (pluginName, pluginMethod, args) => {
                    return remixClient.call(pluginName, pluginMethod, ...args);
                },
            },
            {
                label: 'on',
                value: (pluginName, eventName, callback) => {
                    remixClient.call(pluginName, eventName, callback);
                },
            },
        ]
    }

    return {extensions};
}

export default init;
