const OurIcons = `(def! react-icon-our (fn* (props)
  (react-icon (assoc
    props
    "src" (str "./menu/" (get props "title") ".svg" )
  ))
))
`

const ButtonToolbar = `
(def! toolbar (fn* (props)
  (let* (
    _style (if (nil? (get props "style")) {} (get props "style"))
    buildButtons (fn* (buttons) (map
      (fn* (button key)
        (watch (get button "watch") (fn* () (let* (
            _button (dissoc button "watch" "itemsFn")
          )
          (await (if (= (get button "type") "menu")
            (menu-button (await {
                "items" (await (if (nil? (get button "items"))
                  ((get button "itemsFn"))
                  (get button "items")
                ))
                "key" key
                "style" {"float" (get button "align")}
              })
              (react-button
                (assoc _button
                  "key" key
                  "style" {"borderWidth" "0px" "paddingRight" 5 "background" "transparent"}
                )
                (react-icon-our {"title" (get button "title") "size" "15"})
              )
            )
            (react-button
              (assoc _button "key" key
                "style" {"borderWidth" "0px" "paddingRight" 5 "float" (get button "align") "background" "transparent"}
              )
              (react-icon-our {"title" (get button "title") "size" "15"})
            )
          ))
        )))
      )
      buttons
    ))

    render (fn* (props state stateChangers)
        (react-div {"className" "ButtonToolbar" "style" _style}
          (buildButtons (get props "buttons"))
        )
    )
  )
    (react props {} {"render" render})
  )
))
`

let abiContainer =  `(def! abi-container (fn* (props)
  (let* (
      abijson "[]"

      render (fn* (props state stateChangers)
        (abi-form-abi {
            "key" 1
            "placeholder" "[{name: 'ContractName', address: '0x..', abi: [] }]"
            "value" (get state "abijson")
            "onChange" (get stateChangers "abijson")
            "onSubmit" (fn* () ((get props "onChange") (js-eval (str "(() => {return " (get state "abijson") "})()" ) ) ))
            "style" (get props "styles")
        })
      )
    )
    (react props {"abijson" abijson} {"render" render})
  )
))`


let MarkdownEditor = `(def! md-editor (fn* (props)
  (let* (
      initMarkCommunication (mark-init )
      externalInput (get props "externalInput")
      onChangeValue (get props "onChange")
      showManual (get props "showManual")

      setMdValue (set-state "mdEditorValue" (get props "mdvalue"))
      setTocState (set-state "viewerToc" nil)
      setTocDisplay (set-state "tocDisplay" false)
      setLivePreview (set-state "editorLivePreview" true)

      render (fn* (props state stateChangers)
        (let* (
            viewType (get props "viewType")
            editorDisplay (get props "editorDisplay")
            viewWidth (if (= viewType "edit")
              "calc(50% - 1px)"
              (if (get-state "tocDisplay") "60%" "100%")
            )
            editorStyle {"width" viewWidth "height" "100%"}
            editorStyle (if (= viewType "edit") editorStyle (assoc editorStyle "min-width" "calc(100% - 400px)"))
          )
            (react-div {"style" {"width" "100%" "height" "100%" "display" "flex"}} (list
              (react-div {
                "key" 2
                "className" "MarkdownEditor"
                "style" (assoc editorDisplay "width" "calc(50% - 1px)" "height" "100%")
              }
                (editor-marks {
                  "key" 2
                  "value" (get-state "mdEditorValue")
                  "onChange" (fn* (newvalue)
                    (do
                      (set-state "mdEditorValue" newvalue)
                      (onChangeValue newvalue)
                    )
                  )
                  "onToolbarButtonClick" (get props "onToolbarButtonClick")
                })
              )
              (react-div {
                "key" 3
                "style" (assoc editorDisplay "width" "0px" "height" "100%" "borderWidth" "1px" "borderColor" "eee" "borderStyle" "dashed" "opacity" "0.2")
              })
              (react-div {"style" editorStyle} (list
                (react-div {
                    "key" 4
                    "style" {
                      "width" "100%"
                      "height" "100%"
                      "overflowY" "auto"
                      "paddingLeft" "2%"
                      "paddingRight" "2%"
                    }
                    "className" (str "MarkdownPreview " (if (true? (get-state "showManual")) "scaleable-wrapper slides" ""))
                  }
                  (watch ["editorLivePreview"] (fn* ()
                    (if (get-state "editorLivePreview")
                      (watch ["mdEditorValue"] (fn* () (md-viewer {
                          "showManual" (get props "showManual")
                          "manualClass" "MarkdownPreview"
                          "data" (get props "contractdata")
                          "input" externalInput
                          "onToc" (fn* (tocvalue) (set-state "viewerToc" tocvalue))
                        }
                        (get-state "mdEditorValue")
                      )))
                      (md-viewer {
                          "showManual" (get props "showManual")
                          "manualClass" "MarkdownPreview"
                          "data" (get props "contractdata")
                          "input" externalInput
                          "onToc" (fn* (tocvalue) (set-state "viewerToc" tocvalue))
                        }
                        (get-state "mdEditorValue")
                      )
                    )
                  ))
                )
                (watch ["showDevToolsLogger"] (fn* () (let* (
                  showDevToolsLogger (get-state "showDevToolsLogger")
                  previewHeight (if showDevToolsLogger "70%!important" "100%")
                )
                  (if showDevToolsLogger
                    (do-and-render
                    ;; override console.log in marks
                    ;; set here, for every mark
                    setOverrideConsole (set-script "overrideconsole" (logger-override "logger"))
                      (list
                        (set-style "mdpreviewStyles" {
                          ".MarkdownPreview" {
                            "height" previewHeight
                          }
                        })
                        ;; override console.log in marks
                        (set-script "overrideconsole" (logger-override "logger"))
                      )
                      (react-iframe  {
                          "id" "logger"
                          "mark" true
                          "style" {
                            "width" "100%"
                            "height" "30%"
                            "border" "none"
                            "background" "#333"
                            "bottom" "0px"
                            "position" "sticky"
                          }
                      } "./?url=./marks/logger.json")
                    )
                    (react-span {} "")
                  )
                )))
              ))
              (watch ["tocDisplay" "viewerToc"] (fn* () (let* (
                  viewerToc (get-state "viewerToc")
                  noToc (nil? viewerToc)
                  viewerToc (if noToc "" viewerToc)
                  tocDisplay (get-state "tocDisplay")
                  tocDisplay (if (false? tocDisplay) "none" "block")
                  noManual (nil? (get props "manual"))
                )
                  (react-div {"style" {
                    "display" tocDisplay
                    "borderLeft" "2px dashed rgba(255, 255, 255, 0.2)"
                    "maxWidth" "400px"
                    "width" "40%"
                  }} (list
                    (if noManual
                      (react-span {} "")
                      (react-div {"className" "ManualPreview"} (list
                        (react-div {"className" "scaleable-wrapper slides"} (list
                          (md-viewer {
                              "showManual" true
                              "manualClass" "ManualPreview"
                              "data" (get props "contractdata")
                              "input" externalInput
                              ;"onToc" (fn* (tocvalue) (set-state "viewerToc" tocvalue))
                            }
                            (get props "manual")
                          )
                        ))
                      ))
                    )
                    (if noToc
                      (react-span {} "")
                      (react-div {"className" "markdown-toc" "style" {
                        "overflowY" "auto"
                        "height" (if noManual "100%" "calc(100% - 300px)")
                      }}
                        (md-viewer {} viewerToc)
                      )
                    )
                  ))
              )))
              (watch ["editorLivePreview"] (fn* ()
                (react-div {
                  "style" {
                      "position" "absolute"
                      "top" "0px"
                      "left" "calc(50% - 10px)"
                      "display" (if (= viewType "edit") "display" "none")
                    }
                } (list
                  (react-checkbox {
                    "checked" (get-state "editorLivePreview")
                    "style" {
                      "verticalAlign" "middle"
                      "marginLeft" "5px"
                      "marginRight" "5px"
                      "borderWidth" "0px"
                      "paddingRight" 5
                    }
                    "onChange" (fn* (v)
                      (set-state "editorLivePreview"
                        (if (false? (get-state "editorLivePreview"))
                          true
                          false
                        )
                      )
                    )
                  })
                ))
              ))
            ))
        )
      )
    )
    (react props {"showManual" showManual "manual" (get props "manual")} {"render" render})
  )
))
`

//;params (browser-location-params )
//;ipfsId (if (nil? params) nil (get params "ipfs"))
//;ipfsfile (if (nil? ipfsId) nil (ipfs-get ipfsId))

let MarkdownEditorWithToolbar = `(def! md-editor-toolbar (fn* (props)
  (let* (
      initvalue (get props "value")
      initmanual (get props "manual")
      initcontractdata (get props "contractdata")
      ipfsfile (get props "file")

      params (browser-location-params )
      externalInput (if (nil? params) nil (get params "input"))
      importedc (if (nil? ipfsfile) [] (get ipfsfile "contracts"))

      mdvalue (if (nil? ipfsfile) initvalue (get ipfsfile "content"))
      manual (if (nil? ipfsfile) initmanual (get ipfsfile "manual"))
      contractdata (await (if (nil? ipfsfile)
        initcontractdata
        (concat initcontractdata (await (marks-contracts-hydrate importedc)) )
      ))

      displayType (get props "displayType")

      setShowManual (set-state "showManual" false)
      setshowDevToolsLogger (set-state "showDevToolsLogger" false)

      viewType (get props "mode")

      recursivemenu (fn* (item fntoapply)
        (let* (
          item (fntoapply item)
          )
          (if (nil? (get item "items"))
            item
            (assoc item "items" (map
              (fn* (it) (recursivemenu it fntoapply))
              (get item "items")
            ))
          )
        )
      )

      onToolbarButtonClick log

      render (fn* (props state stateChangers getNonReactive setNonReactive)
        (let* (
            viewType (get state "viewType")
            editorDisplay  (if (= viewType "edit") {} {"display" "none"})
            onExport (fn* () (list
              (qr-generate "qrcodeCanvas"
                (await (md-viewer-url ))
              )
            ))
            MarkLoadButton {
              "title" "more"
              "onClick" (fn* () (log "more"))
              "type" "menu"
              "itemsFn" (fn* () (map
                (fn* (item) (recursivemenu item
                  (fn* (it) (assoc it "onClick" (fn* ()
                    (marks-go (get it "value"))
                  )))
                ))
                (await (marks-menu
                  (get state "contractdata")
                ))
              ))
            }
            ComponentLoadButton {
              "title" "component"
              "onClick" (fn* () (log "component"))
              "type" "menu"
              "items" (map
                (fn* (item) (recursivemenu item
                  (fn* (it) (assoc it "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["insert" (get it "value")])) ))
                ))
                (component-menu)
              )
            }
            MarkToolsButton {
              "title" "more"
              "align" "right"
              "onClick" (fn* () (log "more"))
              "type" "menu"
              "watch" ["viewerToc"]
              "itemsFn" (fn* () (concat (concat (concat []
                    (if (nil? (get-state "viewerToc"))
                      []
                      [{"label" "TOC" "onClick" (fn* ()
                        (set-state "tocDisplay"
                          (if (true? (get-state "tocDisplay")) false true)
                        )
                      )}]
                    )
                  )
                  (if (and (nil? (get props "manual")) (= viewType "production"))
                    []
                    [
                      {"label" "manual" "onClick" (fn* ()
                        (set-state "showManual"
                          (if (true? (get-state "showManual")) false true)
                        )
                      )}
                    ]
                  )
                )
                (if (and (= displayType "BOTH") (false? (= viewType "production")))
                  [
                    {"label" "devtools" "items" [
                      {"label" "view logs" "onClick" (fn* ()
                        (set-state "showDevToolsLogger"
                          (if (true? (get-state "showDevToolsLogger")) false true)
                        )
                      )}
                    ]}
                    {"label" "devtools external" "items" [
                      {"label" "EVM Debugger" "onClick" (fn* ()
                        (log "EVM Debugger")
                      )}
                      {"label" "Jack Hijack" "onClick" (fn* ()
                        (log "Jack Hijack")
                      )}
                    ]}
                  ]
                  []
                )
              ))
            }
          )
          (react-div {"style" {"height" "100%" "overflowY" "hidden" "position" "sticky"}} (list
            (toolbar {
              "key" 1
              "style" (assoc (if (= viewType "production")
                  {"position" "absolute" "height" "0px"}
                  {"background" "rgb(26, 26, 26)" "minHeight" "38px"}
                )
                "paddingBottom" 2
                "width" "100%"
              )
              "buttons" (if (= (get state "viewType") "edit")
                [
                  MarkLoadButton
                  ComponentLoadButton
                  {
                    "title" "eth"
                    "onClick" (fn* () (log "eth"))
                    "type" "menu"
                    "items" (map
                      (fn* (item) (recursivemenu item
                        (fn* (it) (assoc it "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["insert" (get it "value")])) ))
                      ))
                      (contractdata-to-menu
                        (get state "contractdata")
                      )
                    )
                  }
                  ;; {
                  ;;   "title" "bold"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["bold"]))
                  ;; }
                  ;; {
                  ;;   "title" "italic"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["italic"]))
                  ;; }
                  ;; {
                  ;;   "title" "h"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["h"]) )
                  ;; }
                  ;; {
                  ;;   "title" "link"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["link"]) )
                  ;; }
                  ;; {
                  ;;   "title" "quote"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["quote"]) )
                  ;; }
                  ;; {
                  ;;   "title" "image"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["image"]) )
                  ;; }
                  ;; {
                  ;;   "title" "code"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["code"]) )
                  ;; }
                  ;; {
                  ;;   "title" "codeb"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["codeb"]) )
                  ;; }
                  ;; {
                  ;;   "title" "ol"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["ol"]) )
                  ;; }
                  ;; {
                  ;;   "title" "ul"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["ul"]) )
                  ;; }
                  ;; {
                  ;;   "title" "indent"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["indent"]) )
                  ;; }
                  ;; {
                  ;;   "title" "deindent"
                  ;;   "onClick" (fn* () ((getNonReactive "onToolbarButtonClick") ["deindent"]) )
                  ;; }
                  {
                    "title" "export"
                    "className" "exportBtn"
                    "onClick" onExport
                  }
                  {
                    "title" "play"
                    "onClick" (fn* () ((get stateChangers "viewType") "play") )
                  }
                  MarkToolsButton
                ]
                (if (and (= displayType "BOTH") (false? (= viewType "production")))
                  [
                    MarkLoadButton
                    {
                      "title" "edit"
                      "onClick" (fn* () ((get stateChangers "viewType") "edit") )
                    }
                    MarkToolsButton
                  ]
                  ;; [MarkLoadButton MarkToolsButton]
                  [MarkToolsButton]
                )
              )
            })
            (react-div {"key" 2 "style" {
              "height" (if (= viewType "production") "100%" "calc(100% - 38px)")
              }}
              (list
                (react-div {"key" 1 "style" {"width" "100%" "height" "100%" "display" "flex"}} (list
                  (watch
                    ["showManual"]
                    (fn* ()
                      (if (true? (get-state "showManual"))
                        (md-editor (assoc
                          props
                          "key" 2
                          "showManual" (get-state "showManual")
                          "contractdata" (get state "contractdata")
                          "manual" nil
                          "mdvalue" (getNonReactive "manual")
                          "viewType" (get state "viewType")
                          "editorDisplay" editorDisplay
                          "externalInput" externalInput
                          "onToolbarButtonClick" (fn* (func)
                            (setNonReactive "onToolbarButtonClick" func)
                          )
                          "onChange" (fn* (value)
                            (list
                              (storage-set-manual value)
                              (setNonReactive "manual" value)
                            )
                          )
                        ))
                        (md-editor (assoc
                          props
                          "key" 2
                          "showManual" (get-state "showManual")
                          "contractdata" (get state "contractdata")
                          "mdvalue" (getNonReactive "mdvalue")
                          "manual" (getNonReactive "manual")
                          "viewType" (get state "viewType")
                          "editorDisplay" editorDisplay
                          "externalInput" externalInput
                          "onToolbarButtonClick" (fn* (func)
                            (setNonReactive "onToolbarButtonClick" func)
                          )
                          "onChange" (fn* (value)
                            (list
                              (storage-set-code value)
                              (setNonReactive "mdvalue" value)
                            )
                          )
                        ))
                      )
                    ))
                ))
                (abi-container {
                  "styles" editorDisplay
                  "onChange" (fn* (value) ((get stateChangers "contractdata") value) )
                })
              )
            )
          ))
        )
      )
    )
    (react props {"viewType" viewType "contractdata" contractdata} {"render" render} {"onToolbarButtonClick" onToolbarButtonClick "mdvalue" mdvalue "manual" manual} )
  )
))
`

export {
    OurIcons,
    ButtonToolbar,
    MarkdownEditor,
    MarkdownEditorWithToolbar,
    abiContainer,
}
