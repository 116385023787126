
import * as protos from "@tharsis/proto";
// import taylor from '@ark-us/taylor';
// import { utils as jsvmUtils } from 'ewasm-jsvm';
import {getAllFuncs} from '../utils';

// let addr = "mythos1kdqcq2kqjskjnklwausq8fx70fpm75ynp5vn8d"
// let res = protos.createMsgSend(addr, addr, "1", "amythos")
// res = protos.createAnyMessage(res);
// console.log("--any", res);
// let ser = res.serializeBinary();
// console.log(jsvmUtils.uint8ArrayToHex(ser));

async function init () {
    const extensions = {
        label: 'evmos',
        items: [
            {
                label: 'serializeBinary',
                value: (msg) => {
                    return msg.serializeBinary();
                }
            },
            {
                label: 'createMsgTextProposal',
                value: (title, description) => {
                    const msg = new protos.cosmos.gov.v1beta1.TextProposal({title, description});
                    const result = {
                        message: msg,
                        path: 'cosmos.gov.v1beta1.TextProposal',
                    }
                    return {type: 'verbatim', value: result}
                }
            }
        ]
    }

    extensions.items = apiExport(protos, '', extensions.items);

    // extensions.items.push({
    //     label: 'createMsgSubmitProposal',
    //     value: (content, proposer, denom, amount) => {
    //         return protos.createMsgSubmitProposal(content.value, proposer, denom, amount);
    //     }
    // })

    return {extensions};
}

export default init;

function apiExport (path, label, items = []) {
    const utils = getAllFuncs(path).filter(name => name[0] !== '_');
    utils.forEach(key => {
        const item = {label: label + key, value: (...args) => {
            let res;
            args = args.map(arg => {
                if (typeof arg === 'object' && arg.type === 'verbatim') return arg.value;
                return arg;
            });
            try {
                res = path[key](...args);
                if (key.includes("create")) return {type: 'verbatim', value: res}
            } catch (e) {
                console.error(e);
                return e.message;
            }
            return res;
        }};
        items.push(item);
    });
    return items;
}
