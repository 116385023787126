import * as distribution from 'cosmjs-types/cosmos/distribution/v1beta1/tx';
import * as bank from 'cosmjs-types/cosmos/bank/v1beta1/tx';
import * as staking from 'cosmjs-types/cosmos/staking/v1beta1/tx';
import * as slashing from 'cosmjs-types/cosmos/slashing/v1beta1/tx';
import * as gov from 'cosmjs-types/cosmos/gov/v1beta1/tx';
import * as ibcClient from 'cosmjs-types/ibc/core/client/v1/tx';
import * as ibcConnection from 'cosmjs-types/ibc/core/connection/v1/tx';
import * as ibcChannel from 'cosmjs-types/ibc/core/channel/v1/tx';

const allTx = {
    ...distribution,
    ...bank,
    ...staking,
    ...slashing,
    ...gov,
    ...ibcClient,
    ...ibcConnection,
    ...ibcChannel,
};

export default allTx;

export function getTxNameFromTypeUrl(path) {
    const args = path.split('.');
    return args[args.length - 1];
}

export function getTxInstance(typeUrl) {
    const objName = getTxNameFromTypeUrl(typeUrl);
    return allTx[objName];
}
