import { unixfs } from '@helia/unixfs'
import {base16} from "multiformats/bases/base16";
import {base58btc as base58} from "multiformats/bases/base58";
import {base64} from "multiformats/bases/base64";
import {base32} from "multiformats/bases/base32";
import { CID } from "multiformats/cid";
import { dagJson } from '@helia/dag-json'
// import { dagCbor } from '@helia/dag-cbor'
import {startIpfs} from './useIpfs';
import {waitFor} from '../../utils';
import * as plugems from '../../extensions/plugems';

let node;
const gateways = [
  'https://jade-shy-ape-274.mypinata.cloud/ipfs/',
  'https://gateway.pinata.cloud/ipfs/',
  'https://ipfs.io/ipfs/',
  'https://gateway.ipfs.io/ipfs/',
  'https://cloudflare-ipfs.com/ipfs/',
  'https://ipfs-pin.com/ipfs/', // dangerous site issues
]

async function getNode() {
  if (node) return node;
  console.info('STARTING IPFS')
  const obj = await startIpfs();
  if (obj.ready && obj.ipfs) {
    console.info('IPFS STARTED');
    node = obj.ipfs;
  }
  if (node && node.libp2p) {
    console.log("ipfs PeerId", node.libp2p.peerId)
  }
  return node;
}

async function init () {
  const extensions = {
      label: 'ipfs',
      items: [
          {
              label: 'create',
              value: async () => {
                  return getNode();
              }
          },
          {
              label: 'CID',
              value: (id, base) => {
                if (base === 'base16') return parseCID(id, base16.decoder);
                if (base === 'base58') return parseCID(id, base58.decoder);
                if (base === 'base64') return parseCID(id, base64.decoder);
                if (base === 'base32') return parseCID(id, base32.decoder);
                return parseCID(id);
              }
          },
          {
            label: 'CID-to-base16',
            value: (cid) => {
              cid = cid.cid ? cid.cid : cid;
              cid = cid.toV1 ? cid.toV1() : cid;
              return cid.toString(base16);
            },
          },
          {
            label: 'CID-to-base58',
            value: (cid) => {
              cid = cid.cid ? cid.cid : cid;
              // if (cid.toV0) {
              //   cid = cid.toV0();
              //   return cid.toString();
              // }
              cid = cid.toV1 ? cid.toV1() : cid;
              return cid.toString(base58);
            },
          },
          {
            label: 'CID-to-base64',
            value: (cid) => {
              cid = cid.cid ? cid.cid : cid;
              cid = cid.toV1 ? cid.toV1() : cid;
              return cid.toString(base64);
            },
          },
          {
            label: 'CID-to-base32',
            value: (cid) => {
              cid = cid.cid ? cid.cid : cid;
              cid = cid.toV1 ? cid.toV1() : cid;
              return cid.toString(base32);
            },
          },
          {
              label: 'get',
              value: ipfsGet,
          },
          {
              label: 'set',
              value: ipfsSet,
          },
          {
            label: 'set-file',
            value: ipfsSetFile,
        },
      ]
  }

  return {extensions};
}

export function parseCID (strValue, baseDecoder) {
  return CID.parse(strValue, baseDecoder);
}

async function getFileFromNode (cid) {
  const decoder = new TextDecoder();
  const node = await getNode();
  const waitTime = 5000;
  const _node = unixfs(node);

  const operation = async () => {
    let content = '';
    let stream;

    try {
      stream = _node.cat(cid);
    } catch(e) {
      console.log(e);
      return;
    }
    try {
      for await (const _chunk of stream) {
        console.log('_chunk', _chunk);
        content += decoder.decode(_chunk);
      }
    } catch(e) {
      console.log(e);
      return;
    }
    return content;
  }
  return waitFor(operation, waitTime);
}

async function _getFromGateway(url, id, waitTime) {
  let result;
  const operation = () => {
    return fetch(url + id).catch(e => null);
  }
  const response = await waitFor(operation, waitTime);
  if (response) {
    try {
      result = await response.text();
    } catch (e) {
      console.error('Could not decode file', id);
    }
  }
  return result;
}

async function getFromGateway(id) {
  for (let url of gateways) {
    console.group('gateway: ', url);
    const result = await _getFromGateway(url, id, 1500);
    if (result) return result;
  }
}

export async function ipfsGet (id) {
    console.log('ipfsGet', id);
    let result;
    result = await plugems.request('ipfs_get-cache', [id]);

    if (!result) {
      console.log('ipfs from gateway');
      result = await getFromGateway(id);
    }

    if (!result) {
      console.log('ipfs from p2p', id);
      let cid;
      try {
        cid = CID.parse(id);
      }
      catch(e) {
        console.error(e);
        return result;
      }
      console.log('ipfsGet cid', cid);
      result = await getFileFromNode(cid);
    }

    if (result) {
      plugems.request('ipfs_cache', [id, result]);
      try {
        result = JSON.parse(result);
      } catch (e) {}
    }
    return result
}

// default  {type : 'text/html'}
export async function ipfsSet (value, fileType) {
  console.log("ipfsSet", value);
  const node = await getNode();
  if (typeof value === 'object' && (!fileType || fileType === "application/json")) {
    const jsonNode = dagJson(node);
    const cid = await jsonNode.add(value);
    // const jsonNode = dagJson(node);
    // const jsonNode = dagCbor(node);
    return {path: cid.toString()};
  }
  if (typeof value === 'string') {
    const fs = unixfs(helia)
    const encoder = new TextEncoder()
    const bytes = encoder.encode(value)
    const cid = await fs.addBytes(bytes)
    return {path: cid.toString()};
  }
  let file = value;
  const options = {type: fileType}
  const cid = await ipfsSetFile(new Blob([file], options));
  const result = {path: cid.toString()};
  if (result && result.path) {
    plugems.request('ipfs_cache', [result.path, file, options]);
  }
  return result;
}

export async function ipfsSetFile (file) {
  console.log('ipfsSetFile', file);
  const node = await getNode();
  const filesys = unixfs(node);
  const buf = await file.arrayBuffer();
  const bytes = new Uint8Array(buf);
  return filesys.addBytes(bytes);
}

export default init;

