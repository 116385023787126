import VueFormGenerator from "vue-form-generator";

let typeMap = {
    uint: {
        type: 'number',
        inputType: 'number',
        validator: VueFormGenerator.validators.integer,
        min: 0,
        max: 2 ** 256,
        placeholder: 'uint256',
    },
    int: {
        type: 'number',
        inputType: 'number',
        validator: VueFormGenerator.validators.integer,
        min: -(2 ** 256),
        max: 2 ** 256,
        placeholder: 'int256',
    },
    bool: {
        type: 'boolean',
        values: [true, false],
        validator: VueFormGenerator.validators.boolean,
        placeholder: 'boolean',
    },
    address: {
        type: 'string',
        inputType: 'text',
        validator: VueFormGenerator.validators.string,
        placeholder: 'address',
        // pattern: '0x[a-fA-F0-9]{40}',
    },
    string: {
        type: 'string',
        inputType: 'text',
        validator: VueFormGenerator.validators.string,
        min: 20,
        max: 22,
        placeholder: 'string',
    },
    bytes: {
        type: 'string',
        inputType: 'text',
        validator: VueFormGenerator.validators.string,
        placeholder: 'bytes',
    },
    tuple: {
        type: 'object',
        inputType: 'object',
        validator: VueFormGenerator.validators.object,
        placeholder: 'tuple',
    },
    'tuple[]': {
        type: 'array',
        inputType: 'array',
        validator: VueFormGenerator.validators.array,
        placeholder: 'tuple[]',
    },
    'uint[]': {
        type: 'string',
        inputType: 'text',
        validator: VueFormGenerator.validators.integer,
    },
    'int[]': {
        type: 'string',
        inputType: 'text',
        validator: VueFormGenerator.validators.integer,
    },
};

for (let i = 8; i <= 256; i += 8) {
    typeMap[`uint${i}`] = Object.assign({}, typeMap.uint);
    typeMap[`uint${i}`].placeholder = `uint${i}`;
    typeMap[`uint${i}[]`] = Object.assign({}, typeMap['uint[]']);

    typeMap[`int${i}`] = Object.assign({}, typeMap.int);
    typeMap[`int${i}`].placeholder = `int${i}`;
    typeMap[`int${i}[]`] = Object.assign({}, typeMap['int[]']);
}

for (let i = 1; i <= 32; i += 1) {
    typeMap[`bytes${i}`] = Object.assign({}, typeMap.bytes);
    typeMap[`bytes${i}`].placeholder = `bytes${i}`;
}


export function ioToFormSchema(io, opts = {}, values = {}, tupleFn) {
    const schema = {
        type: typeMap[io.type].type,
        inputType: typeMap[io.type].inputType,
        enum: typeMap[io.type].values,
        title: io.name,
        // title: '',
        default: values[io.name],
        ...opts
    }

    let uiSchema = {
        "ui:placeholder": io.name + ':' + typeMap[io.type].placeholder,
        "ui:options": {
            inputType: typeMap[io.type].inputType,
        }
    }

    if (io.type === "tuple") {
        schema.properties = {}
        let tuiSchema = {}
        let _values = values[io.name] || {};
        io.components.forEach(io => {
            const schemas = tupleFn(io, _values[io.name] || {});
            schema.properties[io.name] = schemas.schema;
            tuiSchema[io.name] = schemas.uiSchema;
        })
        uiSchema = tuiSchema
        schema.default = JSON.stringify(values[io.name])
    }

    if (io.type.includes("[]")) {
        let _values = values[io.name] || {};
        schema.items = {type: 'object', required: [], properties: {}}
        let tuiSchema = {}
        io.components.forEach((ioc, i) => {
            const schemas = inputToFormSchema(ioc, _values[i]);
            schema.items.required.push(ioc.name);
            schema.items.properties[ioc.name] = schemas.schema;
            tuiSchema[ioc.name] = schemas.uiSchema;
        })
        uiSchema = tuiSchema
    }

    return {schema, uiSchema};
}

export function inputToFormSchema(io, values) {
    return ioToFormSchema(io, {}, values, inputToFormSchema)
}

export function outputToFormSchema(io, values) {
   return ioToFormSchema(io, {readonly: true}, values, outputToFormSchema);
}

export function abiFuncToFormSchema(abi, values) {
    let schema = {
        "title": "",
        "type": "object",
        "required": [],
        "properties": {
        }
    }
    let uiSchema = {}

    abi.inputs.forEach(io => {
        const schemas = inputToFormSchema(io, values);
        schema.required.push(io.name);
        schema.properties[io.name] = schemas.schema;
        uiSchema[io.name] = schemas.uiSchema;
    })
    return {schema, uiSchema};
}

export function abiToFormSchema(abi) {
    let _schema = {
        "title": "",
        "type": "object",
        "properties": {
        }
    }
    let _uiSchema = {};

    abi.forEach((v) => {
        const {schema, uiSchema} = abiFuncToFormSchema(v);
        _schema.properties[v.name] = schema;
        _uiSchema[v.name] = uiSchema;
    });

    return {schema: _schema, uiSchema: _uiSchema};
}
