import * as ethAddressConverter from '@evmos/address-converter';
import { Wallet } from '@ethersproject/wallet';
import { Registry } from '@cosmjs/proto-signing';
import {ethermint as ethermintTypes} from '@tharsis/proto/dist/proto/ethermint/types/v1/account';
import {
    isDeliverTxSuccess,
    SigningStargateClient,
    StargateClient,
    QueryClient,
    defaultRegistryTypes,
    GasPrice,
    calculateFee,
} from '@cosmjs/stargate';
import { PrivKeySecp256k1 } from '@keplr-wallet/crypto';
import { Tendermint34Client } from '@cosmjs/tendermint-rpc';
import {createTransaction} from '@evmos/proto';
import { arrayify } from '@ethersproject/bytes';
import { signTransaction as signTransactionEvm } from '@hanchon/evmos-ts-wallet';
import {toBase64, fromBase64} from '@cosmjs/encoding';
import {getQueryRequestInstance, getQueryResponseInstance} from './cosmos_queries';
import allTxs, {getTxInstance, getTxNameFromTypeUrl} from './cosmos_tx';
import {getMsgInstance} from './cosmos_msg';
import {randomElement} from '../../utils';
import { ethers } from 'ethers';
import {Any} from 'cosmjs-types/google/protobuf/any';
import {RequestQuery} from 'cosmjs-types/tendermint/abci/types';
import {
  CosmWasmClient,
  SigningCosmWasmClient,
} from "@cosmjs/cosmwasm-stargate";
import {
  WasmXClient,
  SigningWasmXClient
} from "@ark-us/wasmx-stargate";
import {isEthermintMessage, encodeMsgEthereumTxAny, EthCallEncode} from './evmos';
import { chainRegistryChainToKeplr } from '@chain-registry/keplr';
import { chains as cosmosChains } from 'chain-registry';
import {toChainRegistryFormat} from '../../data/chains';
import {utils as jsvmutils} from 'ewasm-jsvm';
import { hexToUint8Array } from 'ewasm-jsvm/src/utils';

const providerMap = {};
const providerQueryMap = {};

function createDefaultRegistry() {
  const evmosTypes = [
    ['/ethermint.types.v1.EthAccount', ethermintTypes.types.v1.EthAccount],
  ];
  // @ts-ignore
  const alltypes = defaultRegistryTypes.concat(evmosTypes);
  return new Registry(alltypes);
}

export async function buildProvider(chainId, rpc, wallet, gasPrice, force = false) {
  if (!force && providerMap[chainId]) {return providerMap[chainId];}
  console.log('--buildProvider', rpc);
  const client = await SigningStargateClient.connectWithSigner(
    rpc,
    wallet,
    {
      registry: createDefaultRegistry(),
      gasPrice: GasPrice.fromString(gasPrice),
    },
  );
  return client;
}

export async function getProvider(chain, wallet, gasPrice, force = false) {
    if (!force && providerMap[chain.chainId]) {return providerMap[chain.chainId];}
    const rpc = randomElement(chain.apis.rpc)[0];
    return buildProvider(chain.chainId, rpc, wallet, gasPrice, force);
}

export async function buildProviderQuery(chainId, endpoint, force = false) {
  if (!force && providerMap[chainId]) {return providerMap[chainId];}
  const client = await StargateClient.connect(
    endpoint.address,
    {
      registry: createDefaultRegistry(),
    },
  );
  return client;
}

export async function getProviderQuery(chain, force = false) {
  if (!force && providerMap[chain.chainId]) {return providerMap[chain.chainId];}
  const endpoint = randomElement(chain.apis.rpc)[0];
  return buildProviderQuery(chain.chainId, endpoint, force);
}

function parseFee(fee) {
  // fee can be: 'auto' | 1.4 | {amount: [{denom, amount}], gas: ""}
  if (fee === 'auto') return fee;
  if (typeof fee === 'string') {
    const feesMultiplier = parseFloat(fee);
    if (!isNaN(feesMultiplier)) return feesMultiplier;
    let feeObj;
    try {
      feeObj = JSON.parse(fee);
    } catch (e) {}
    if (feeObj) return feeObj;
  }
  return fee;

  // return fee = (fee !== 'auto' && typeof fee === 'string') ? parseFloat(fee) : fee;
}

export async function getAccounts(chainId) {
  if (!window.keplr) return [];
  const offlineSigner = window.getOfflineSigner(chainId);
  const accounts = await offlineSigner.getAccounts();
  return accounts;
}

export async function rpcRequestSend (client, sender, msgAny, fee, memo) {
  // console.log('---rpcRequestSend-', sender, [msgAny], fee, memo);
  try {
    const result = await client.signAndBroadcast(sender, [msgAny], parseFee(fee), memo);
    // console.log('--result', result);
    const success = isDeliverTxSuccess(result);
    return [success, result];
  } catch (e) {
    console.error(e);
    return [false, {error: e.message}];
  }
}

async function sendCosmosQuery(request) {
  let response;
  const {typeUrl: queryPath, selectedChain: chain, value} = request;
  const rpc = randomElement(chain.apis.rpc)[0];
  // console.log('---sendCosmosQuery--', queryPath, value, rpc.address);

  const tmClient = await Tendermint34Client.connect(rpc.address);
  const client = QueryClient.withExtensions(tmClient);
  const instance = getQueryRequestInstance(queryPath);

  if (!instance) {return 'not implemented';}

  const encodedRequest = Uint8Array.from(
    instance.encode(
      instance.fromJSON(value),
    ).finish(),
  );

  try {
    const encodedResult = await client.queryUnverified(queryPath, encodedRequest);
    const resultInstance = getQueryResponseInstance(queryPath);
    if (!resultInstance) {return encodedResult;}
    response = resultInstance.decode(encodedResult);
  } catch (e) {
    console.error(e);
    return {error: e.message};
  }
  // console.log('-----response--', response);
  return response;
}

async function sendEthermintQuery(request) {
  let response;
  const {typeUrl: queryPath, selectedChain: chain, value} = request;
  const rpc = randomElement(chain.apis.rpc)[0];
  // console.log('---sendEthermintQuery--', queryPath, value, rpc.address);

  const tmClient = await Tendermint34Client.connect(rpc.address);
  const client = QueryClient.withExtensions(tmClient);

  const encodedRequest = EthCallEncode(value);
  // console.log('---sendEthermintQuery', queryPath, encodedRequest)

  try {
    const encodedResult = await client.queryUnverified(queryPath, encodedRequest);
    // const resultInstance = getQueryResponseInstance(queryPath);
    // if (!resultInstance) {return encodedResult;}
    // response = resultInstance.decode(encodedResult);
    response = {result: encodedResult};
  } catch (e) {
    console.error(e);
    return {error: e.message};
  }
  // console.log('-----response--', response);
  return response;
}

async function getSender(restApi, bech32Address, privateKey) {
  const response = await fetch(`${restApi}/cosmos/auth/v1beta1/accounts/` + bech32Address);
  let data;
  try {
    data = await response.json();
  } catch (e) {
    return {error: 'Rest API does not support account retrieval'};
  }
  // console.log('--data', data);
  // no code or code == 0 is ok
  if (data.code) {
    return {error: data.message + ' or REST API does not support account retrieval'};
  }
  if (!data.account.base_account.pub_key && privateKey) {
    const privKey = new PrivKeySecp256k1(arrayify(privateKey));
    const pubKey = toBase64(privKey.getPubKey().toBytes());
    data.account.base_account.pub_key = {
      key: pubKey,
    };
  }
  return data;
}

async function broadcastEvm(restApi, resKeplr) {
  let broadcastRes;
  try {
    const post = await fetch(`${restApi}/cosmos/tx/v1beta1/txs`, {
      method: 'post',
      body: resKeplr,
      headers: { 'Content-Type': 'application/json' },
    });
    broadcastRes = await post.json();
  } catch (e) {
    console.error('broadcast error:', e);
    broadcastRes = {};
  }
  if (broadcastRes.tx_response.code === 0) {
      console.log('Success', broadcastRes);
      return [true, broadcastRes.tx_response];
  } else {
      console.error('Error', broadcastRes);
      return [false, {error: broadcastRes.tx_response?.raw_log || 'broadcast error', broadcastRes}];
  }
}

async function estimateEvm(restApi, resKeplr) {
  let broadcastRes;
  try {
    const post = await fetch(`${restApi}/cosmos/tx/v1beta1/simulate`, {
      method: 'post',
      body: resKeplr,
      headers: { 'Content-Type': 'application/json' },
    });
    broadcastRes = await post.json();
    console.log('--estimateEvm', broadcastRes);
    return broadcastRes.gas_info.gas_used;
  } catch (e) {
    console.error('broadcast error:', e);
    return 0;
  }
}

async function signEvm(wallet, msgAny, memo, fees, denom, gasLimit, pubKey, sequence, accountNumber, chainId) {
  console.log('---------****signEvm');
  const algo = 'ethsecp256k1';
  const tx = createTransaction(msgAny, memo, fees, denom, gasLimit, algo, pubKey, sequence, accountNumber, chainId);
  // console.log('----createTransaction', tx);
  // @ts-ignore
  return signTransactionEvm(wallet, tx);
}

// gasPrice - only value, no denom
async function signAndEstimateEvm(
  restApi, wallet,
  msgAny, memo,
  feesMultiplier,
  fees,
  gasLimit,
  denom,
  gasPrice,
  pubKey, sequence, accountNumber,
  chainId,
) {
  if (!gasLimit) {
    gasLimit = 1;
    let simFees = fees;
    if (!simFees) {
      // TODO - this fails if fees are < than needed
      // and fails if fees are > than balance ...
      // find another API
      simFees = '10000000000000000';
    }

    console.log('---signAndEstimateEvm', msgAny, memo, simFees, denom, gasLimit, pubKey, sequence, accountNumber, chainId);

    const txString = await signEvm(wallet, msgAny, memo, simFees, denom, gasLimit, pubKey, sequence, accountNumber, chainId);
    console.log('----txString', txString);
    const gasUsed = await estimateEvm(restApi, txString);

    gasLimit = Math.ceil(gasUsed * feesMultiplier);
  }
  if (!fees) {
    const stdFees = calculateFee(gasLimit, gasPrice);
    fees = stdFees.amount[0].amount;
    console.log('---fees', fees);
  }

  return signEvm(wallet, msgAny, memo, fees, denom, gasLimit, pubKey, sequence, accountNumber, chainId);
}

function msgAnyFromTypeUrl(request) {
  // set by encodeMsgEthereumTxAny
  if (request.typeUrl.includes('MsgEthereumTx')) return request.value;

  const queryName = getTxNameFromTypeUrl(request.typeUrl);
  if (!allTxs[queryName]) {return null;}

  if (queryName === 'MsgSubmitProposal') {
    if (request.value.content && request.value.content.typeUrl) {
      const {typeUrl, value} = request.value.content;
      const instance = getMsgInstance(typeUrl);
      const msg = instance.fromJSON(value);
      console.log('---msg--', msg);

      request.value.content.value = Uint8Array.from(
        instance.encode(
          instance.fromJSON(value),
        ).finish(),
      );
    }
  }

  const msg = {};
  msg.serializeBinary = () => {
    console.log('--serialize', queryName, request.value);
    console.log('---serialize fromjson', allTxs[queryName].fromJSON(request.value));
    return Uint8Array.from(
      allTxs[queryName].encode(
        allTxs[queryName].fromJSON(request.value),
      ).finish(),
    );
  };

  const msgAny = {
    path: request.typeUrl.slice(1),
    message: msg,
  };
  return msgAny;
}

// TODO sign with something else than first wallet address
async function evmTransact(mnemonic, hdpathString, request) {
  const chain = request.selectedChain;
  const wallet = Wallet.fromMnemonic(mnemonic, hdpathString);
  // const evmosAddress = ethToEvmos(wallet.address);

  const _data = ethAddressConverter.ETH.decoder(wallet.address);
  // @ts-ignore
  const CHAIN = ethAddressConverter.bech32Chain(chain.name, chain.bech32_prefix);
  const evmosAddress = CHAIN.encoder(_data);

  const restApi = randomElement(chain.apis.rest)[0].address;
  const denom = chain.nativeCurrency.name;
  const feesMultiplier = typeof request.fee === 'string' ? parseFloat(request.fee) : 1.3;
  let fees, gasLimit;
  if (typeof request.fee !== 'string') {
    gasLimit = parseInt(request.fee.gas);
    fees = request.fee.amount[0].amount;
  }
  // fee:  "1.2"
  // gasPrice: "1000agoric"

  console.log('address', wallet.address, evmosAddress);
  console.log('----restApi', restApi, chain.apis.rest);

  const senderAccount = await getSender(restApi, evmosAddress, wallet.privateKey);
  console.log('account', senderAccount);
  if (!senderAccount) {
    return [false, {error: 'Account does not exist: ' + evmosAddress}];
  }
  if (senderAccount.error) {return [false, senderAccount];}
  const baseAccount = senderAccount.account.base_account;

  const msgAny = msgAnyFromTypeUrl(request);
  if (!msgAny) {return [false, {error: 'TypeUrl not implemented: ' + request.typeUrl}];}

  const resKeplr = await signAndEstimateEvm(restApi, wallet, msgAny, request.memo, feesMultiplier, fees, gasLimit, denom, request.gasPrice, baseAccount.pub_key.key, baseAccount.sequence, baseAccount.account_number, chain.chainId);
  console.log('----resKeplr', resKeplr);
  return broadcastEvm(restApi, resKeplr);
}

export async function encodeCosmosTx (request) {
  const {typeUrl, value} = request;
  const txName = getTxNameFromTypeUrl(typeUrl);
  const instance = getTxInstance(typeUrl);
  if (!instance) {return 'not implemented';}

  if (txName === 'MsgSubmitProposal') {
    if (value.content && ethers.utils.isHexString(value.content)) {
      const content = Any.decode(ethers.utils.arrayify(value.content));
      value.content = content;
    }
    else if (value.content && value.content.typeUrl) {
      value.content.value = _encodeCosmosMsg(value.content);
    }
  }
  console.log('--encodeCosmosTx-value-', value);

  const bzval = Uint8Array.from(
    instance.encode(
      instance.fromJSON(value),
    ).finish(),
  );
  const msg = {typeUrl, value: bzval};
  const bz =  Uint8Array.from(Any.encode(msg).finish());
  return ethers.utils.hexlify(bz);
}

// e.g. /cosmos.bank.v1beta1.Query/Balance
export async function encodeCosmosQuery (request) {
  const {typeUrl: queryPath, value} = request;
  console.log('encodeCosmosQuery', queryPath);

  const instance = getQueryRequestInstance(queryPath)
  if (!instance) {return 'not implemented';}

  const bzval = Uint8Array.from(
    instance.encode(
      instance.fromJSON(value),
    ).finish(),
  );

  // const msg = {typeUrl, value: bzval};
  // console.log('encodeCosmosQuery msg', msg);
  // const bz =  Uint8Array.from(Any.encode(msg).finish());

  // const decoded = Any.decode(bz);
  // console.log('encodeCosmosQuery decoded', decoded);

  const bz = Uint8Array.from(
    RequestQuery.encode(
      RequestQuery.fromJSON({
        data: bzval,
        path: queryPath,
      }),
    ).finish(),
  );

  return ethers.utils.hexlify(bz);
}

function _encodeCosmosMsg(request) {
  const instance = getMsgInstance(request.typeUrl);
  const msg = instance.fromJSON(request.value);
  console.log('---msg--', msg);

  return Uint8Array.from(
    instance.encode(
      instance.fromJSON(request.value),
    ).finish(),
  );
}

function encodeCosmosAnyMsg(request) {
  const bzval = _encodeCosmosMsg(request);
  const msg = {typeUrl: request.typeUrl, value: bzval};
  const bz =  Uint8Array.from(Any.encode(msg).finish());
  return ethers.utils.hexlify(bz);
}

export async function encodeCosmosRequest (request) {
  if (request.typeUrl.includes('Query')) {
    return encodeCosmosQuery(request);
  }
  if (getMsgInstance(request.typeUrl)) {
    return encodeCosmosAnyMsg(request);
  }
  return encodeCosmosTx(request);
}

function decodeCosmosQuery(msg) {
  console.log('decodeCosmosQuery---msg--', msg);
  if (msg.type === 'request') return 'Not implemented';
  const encodedResult = ethers.utils.arrayify(msg.value);
  const queryPath = msg.typeUrl;
  const resultInstance = getQueryResponseInstance(queryPath);
  if (!resultInstance) {return msg.value;}

  let response;
  try {
    response = resultInstance.decode(encodedResult);
  } catch (e) {
    console.error(e);
    return {error: e.message};
  }
  console.log('-----response--', response);
  return response;
}

function decodeCosmosAnyMsg(msg) {
  return 'Not implemented';
}

function decodeCosmosTx(msg) {
  return 'Not implemented';
}

export function decodeCosmosData (msg) {
  if (!msg) return;
  if (msg.typeUrl.includes('Query')) {
    return decodeCosmosQuery(msg);
  }
  if (getMsgInstance(msg.typeUrl)) {
    return decodeCosmosAnyMsg(msg);
  }
  return decodeCosmosTx(msg);
}

async function getCosmosWallet(chainId) {
  if (!window.keplr) return [];
  const offlineSigner = window.getOfflineSigner(chainId);
  return offlineSigner;
}

export async function getCosmosChainId(req) {
  if (!req.selectedChain) {return;}
  const rpc = randomElement(req.selectedChain.apis.rpc)[0];
  const tmClient = await Tendermint34Client.connect(rpc.address);
  if (!tmClient) return;
  const status = await tmClient.status();
  if (!status) return;
  return status.nodeInfo.network;
}

export async function sendCosmosRequest (request) {
  console.log('---sendCosmosRequest--', request);
  if (!request.typeUrl || !request.value || !request.selectedChain) {return;}

  // TODO fixme
  if (request.typeUrl.includes('Query')) {
    if (request.typeUrl.includes('ethermint')) return sendEthermintQuery(request);
    return sendCosmosQuery(request);
  }

  if (isEthermintMessage(request.typeUrl)) {
    request.value = encodeMsgEthereumTxAny(request.typeUrl, request.value);
  }
  console.log('---sendCosmosRequest2--', request);

  const chain = request.selectedChain;
  const wallet = await getCosmosWallet(chain.chainId);
  if (!wallet) {return 'no profile selected';}

  const [firstAccount] = await wallet.getAccounts();
  console.log('firstAccount', firstAccount, chain.slip44);

  if (chain.slip44 == 60) {
    const hdpathString = "m/44'/" + chain.slip44.toString() + "'/0'/0/0";
    return evmTransact(wallet.mnemonic, hdpathString, request);
  }

  const client = await getProvider(chain, wallet, request.gasPrice);

  const msgAny = {
    typeUrl: request.typeUrl,
    value: request.value,
  };
  return rpcRequestSend(client, firstAccount.address, msgAny, request.fee, request.memo);
}

async function bech32FromEth (hexAddress, bech32Prefix) {
  const _data = ethAddressConverter.ETH.decoder(hexAddress);
  // @ts-ignore
  const CHAIN = ethAddressConverter.bech32Chain(bech32Prefix, bech32Prefix);
  return CHAIN.encoder(_data);
}

async function ethFromBech32 (bech32Address, bech32Prefix) {
  const CHAIN = ethAddressConverter.bech32Chain(bech32Prefix, bech32Prefix);
  const _data = CHAIN.decoder(bech32Address);
  return ethAddressConverter.ETH.encoder(_data);
}

// "/ethermint.crypto.v1.ethsecp256k1.PubKey"
// "/cosmos.crypto.secp256k1.PubKey",

///////*********CosmWasm */

// interface ClientConnection {
//     client: CosmWasmClient;
//     rpcEndpoint;
// }

// interface SigningClientConnection extends ClientConnection {
//     client;
//     address;
// }

class ConnectionManager {
    queryingClientConnection;
    signingClientConnections = {};

    getQueryClient = async (
        chain,
        forceRefresh = false
    ) => {
      const rpcEndpoint = randomElement(chain.apis.rpc)[0].address;
        if (
            this.queryingClientConnection === undefined ||
            this.queryingClientConnection.rpcEndpoint !== rpcEndpoint ||
            forceRefresh
        ) {
            this.queryingClientConnection = {
                client: await CosmWasmClient.connect(rpcEndpoint),
                rpcEndpoint,
            };
        }

        return this.queryingClientConnection.client;
    };

    getSigningClient = async (
        wallet,
        chain,
        gasPrice,
    ) => {
        const rpcEndpoint = randomElement(chain.apis.rpc)[0].address;
        const [account] = await wallet.getAccounts();
        const address = account.address;
        if (
            this.signingClientConnections[address] === undefined ||
            this.signingClientConnections[address].rpcEndpoint !== rpcEndpoint
        ) {
            this.signingClientConnections[address] = {
                client: await SigningCosmWasmClient.connectWithSigner(
                    rpcEndpoint,
                    wallet,
                    {
                        // @ts-ignore
                        gasPrice: GasPrice.fromString(gasPrice),
                    }
                ),
                address,
                rpcEndpoint,
            };
        }
        return this.signingClientConnections[address].client;
    };
}

class ConnectionManagerWasmX {
  queryingClientConnection;
  signingClientConnections = {};

  getQueryClient = async (
      chain,
      forceRefresh = false
  ) => {
    const rpcEndpoint = randomElement(chain.apis.rpc)[0].address;
      if (
          this.queryingClientConnection === undefined ||
          this.queryingClientConnection.rpcEndpoint !== rpcEndpoint ||
          forceRefresh
      ) {
          this.queryingClientConnection = {
              client: await WasmXClient.connect(rpcEndpoint),
              rpcEndpoint,
          };
      }

      return this.queryingClientConnection.client;
  };

  getSigningClient = async (
      wallet,
      chain,
      gasPrice,
  ) => {
      const rpcEndpoint = randomElement(chain.apis.rpc)[0].address;
      const [account] = await wallet.getAccounts();
      const address = account.address;
      if (
          this.signingClientConnections[address] === undefined ||
          this.signingClientConnections[address].rpcEndpoint !== rpcEndpoint
      ) {
          this.signingClientConnections[address] = {
              client: await SigningWasmXClient.connectWithSigner(
                  rpcEndpoint,
                  wallet,
                  {
                      // @ts-ignore
                      gasPrice: GasPrice.fromString(gasPrice),
                  }
              ),
              address,
              rpcEndpoint,
          };
      }
      return this.signingClientConnections[address].client;
  };
}

const CWConnectionManager = new ConnectionManager();
const WasmXConnectionManager = new ConnectionManagerWasmX();

async function cwUpload (request) {
  const {selectedChain: chain} = request;

  const wallet = await getCosmosWallet(chain.chainId);
  if (!wallet) {return {error: 'no profile selected'}};

  const [firstAccount] = await wallet.getAccounts();
  const client = await CWConnectionManager.getSigningClient(wallet, chain, request.gasPrice);

  let wasmbin = request.value
  if (request.value instanceof Uint8Array) wasmbin = request.value;
  if (typeof request.value === 'string') {
    const prefix = 'data:application/wasm;base64,';
    let _value = request.value;
    if (_value.includes(prefix)) {
      _value = _value.slice(29);
    }
    try {
      wasmbin = fromBase64(_value);
    } catch (e) {
      console.log(e);
      return {error: e.message};
    }
  }

  try {
    const result = await client.upload(
      firstAccount.address,
      wasmbin,
      parseFee(request.fee),
      request.memo,
    );
    return result;
  } catch (e) {
    console.log(e);
    return {error: e.message};
  }
}

async function cwInstantiate (request) {
    const {selectedChain: chain, codeId, value: instantiateMsg, label} = request;
    if (!codeId) return {error: "Code id missing"};
    if (!label) return {error: "Label missing"};

    const wallet = await getCosmosWallet(chain.chainId);
    if (!wallet) {return {error: 'no profile selected'}};

    const [firstAccount] = await wallet.getAccounts();
    const client = await CWConnectionManager.getSigningClient(wallet, chain, request.gasPrice);

    console.log("-- cwInstantiate", firstAccount.address, codeId, instantiateMsg, label);

    try {
      const result = await client.instantiate(
        firstAccount.address,
        codeId,
        instantiateMsg,
        label,
        parseFee(request.fee),
      );
      return result;
    } catch (e) {
      console.log(e)
      return {error: e.message};
    }
}

async function cwDeploy (request) {
  let uploadResult;
  let codeId;
  if (request.value) {
    uploadResult = await cwUpload({
      value: request.value,
      fee: request.fee,
      memo: request.memo,
      selectedChain: request.selectedChain,
      gasPrice: request.gasPrice,
    });

    // @ts-ignore
    if (uploadResult.error) {
      return uploadResult;
    }
    // @ts-ignore
    codeId = uploadResult.codeId;
  }
  if (request.codeId) codeId = request.codeId;

  const instantiateResult = await cwInstantiate({
    value: request.instantiateMsg,
    codeId,
    label: request.label,
    fee: request.fee,
    memo: request.memo,
    selectedChain: request.selectedChain,
    gasPrice: request.gasPrice,
  })

  return {upload: uploadResult, instantiate: instantiateResult};
}

async function getCWContract (client, address) {
  try {
    const contract = await client.getContract(address);
    return contract;
  } catch (e) {
    return;
  }
}

async function cwExecute(request) {
  const {selectedChain: chain, address} = request;
  const wallet = await getCosmosWallet(chain.chainId);
  if (!wallet) {return {error: 'No profile selected'};}

  const client = await CWConnectionManager.getSigningClient(wallet, chain, request.gasPrice);
  const [account] = await wallet.getAccounts();
  try {
    const result = await client.execute(
      account.address,
      address,
      request.value,
      parseFee(request.fee),
      request.memo,
      request.amount,
    );
    return result;
  } catch (e) {
    return {error: e.message};
  }
}

async function cwQuery(request) {
  const client = await CWConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.queryContractSmart(request.address, request.value);
    return result;
  } catch(e) {
    return {error: e.message};
  }
}

async function cwGetCodes(request) {
  const client = await CWConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.getCodes();
    return result;
  } catch(e) {
    return {error: e.message};
  }
}

async function cwGetCodeDetails(request) {
  const client = await CWConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.getCodeDetails(request.codeId);
    return result;
  } catch(e) {
    return {error: e.message};
  }
}

async function cwGetContracts(request) {
  const client = await CWConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.getContracts(request.codeId);
    const contracts = [];
    for (let address of result) {
      const contract = await cwGetContract({selectedChain: request.selectedChain, address});
      // @ts-ignore
      if (contract && !contract.error) {
        contracts.push(contract);
      }
    }
    return contracts;
  } catch(e) {
    return {error: e.message};
  }
}

async function cwGetContract(request) {
  const client = await CWConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.getContract(request.address);
    return result;
  } catch(e) {
    return {error: e.message};
  }
}

function getKeplrChainInfo(chainId) {
  const chain = cosmosChains.find(({chain_id}) => chain_id === chainId);
  return chainRegistryChainToKeplr(chain, assets);
}

async function connectCosmos(chainId) {
  console.log('--connectCosmos', chainId);
  if (!window.keplr || !chainId) return;
  try {
    await window.keplr.enable(chainId);
  } catch (e) {
    console.error(e);
    const config = getKeplrChainInfo(chainId);
    try {
      await window.keplr.experimentalSuggestChain(config);
    } catch (e) {
      return {error: e.message};
    }
  }
}

async function queryContractCall(client, sender, address, queryMsg, funds) {
  try {
      return await client.forceGetQueryClient().wasm.queryContractFull(sender, address, queryMsg, funds);
  }
  catch (error) {
      if (error instanceof Error) {
          if (error.message.startsWith("not found: contract")) {
              throw new Error(`No contract found at address "${address}"`);
          }
          else {
              throw error;
          }
      }
      else {
          throw error;
      }
  }
}

async function cwQueryCall(request) {
  const chain = request.selectedChain;
  const client = await CWConnectionManager.getQueryClient(
    chain,
    true,
  );
  const wallet = await getCosmosWallet(chain.chainId);
  let account = {address: ""};
  if (wallet) {
    const accounts = await wallet.getAccounts();
    if (accounts.length > 0 ) account = accounts[0];
  }

  const funds = request.amount || [];
  try {
    const result = await queryContractCall(client, account.address, request.address, request.value, funds);
    if (result && result.Data) {
      result.Data = ethers.utils.hexlify(fromBase64(result.Data));
    }
    return result;
  } catch(e) {
    return {error: e.message};
  }
}

// wasmx

async function wasmxUpload (request) {
  const {selectedChain: chain} = request;

  const wallet = await getCosmosWallet(chain.chainId);
  if (!wallet) {return {error: 'no profile selected'}};

  const [firstAccount] = await wallet.getAccounts();
  const client = await WasmXConnectionManager.getSigningClient(wallet, chain, request.gasPrice);

  let wasmbin = request.value
  if (request.value instanceof Uint8Array) wasmbin = request.value;
  if (typeof request.value === 'string') {
    if (request.value.substr(0, 2) == "0x") {
      wasmbin = hexToUint8Array(request.value)
    } else {
      const prefix = 'data:application/wasm;base64,';
      const prefixlen = prefix.length;
      let _value = request.value;
      if (_value.substr(0, prefixlen)) {
        _value = _value.slice(prefixlen);
      }
      try {
        wasmbin = fromBase64(_value);
      } catch (e) {
        console.log(e);
        return {error: e.message};
      }
    }
  }

  try {
    const result = await client.upload(
      firstAccount.address,
      wasmbin,
      request.deps || [],
      request.metadata || {},
      parseFee(request.fee),
      request.memo,
    );
    console.log('===wasmx upload===', result);
    return result;
  } catch (e) {
    console.log(e);
    return {error: e.message};
  }
}

async function wasmxDeployCode (request) {
  const {selectedChain: chain, label, funds, memo} = request;

  const wallet = await getCosmosWallet(chain.chainId);
  if (!wallet) {return {error: 'no profile selected'}};

  const [firstAccount] = await wallet.getAccounts();
  const client = await WasmXConnectionManager.getSigningClient(wallet, chain, request.gasPrice);

  let code = request.value
  if (request.value instanceof Uint8Array) code = request.value;
  if (typeof request.value === 'string') {
    code = jsvmutils.hexToUint8Array(request.value);
  }
  const instantiateMsg = request.msg;
  if (typeof instantiateMsg.data === 'string') {
    const datau8 = jsvmutils.hexToUint8Array(instantiateMsg.data)
    const b64encoded = Buffer.from(datau8).toString('base64');
    instantiateMsg.data = b64encoded;
  }

  try {
    const result = await client.deployCode(
      firstAccount.address,
      code,
      request.metadata || {},
      request.deps || [],
      instantiateMsg,
      label,
      parseFee(request.fee),
      {funds, memo},
    );
    console.log('===wasmx upload evm===', result);
    return result;
  } catch (e) {
    console.log(e);
    return {error: e.message};
  }
}

async function wasmxInstantiate (request) {
    const {selectedChain: chain, codeId, value: instantiateMsg, label, funds, memo} = request;
    if (!codeId) return {error: "Code id missing"};
    if (!label) return {error: "Label missing"};

    const wallet = await getCosmosWallet(chain.chainId);
    if (!wallet) {return {error: 'no profile selected'}};

    const [firstAccount] = await wallet.getAccounts();
    const client = await WasmXConnectionManager.getSigningClient(wallet, chain, request.gasPrice);

    if (typeof instantiateMsg.data === 'string') {
      const datau8 = jsvmutils.hexToUint8Array(instantiateMsg.data)
      const b64encoded = Buffer.from(datau8).toString('base64');
      instantiateMsg.data = b64encoded;
    }

    console.log("-- wasmxInstantiate", firstAccount.address, codeId, instantiateMsg, label);

    try {
      const result = await client.instantiate(
        firstAccount.address,
        codeId,
        instantiateMsg,
        label,
        parseFee(request.fee),
        {funds, memo},
      );
      return result;
    } catch (e) {
      console.log(e)
      return {error: e.message};
    }
}

async function wasmxDeploy (request) {
  let uploadResult;
  let codeId;
  if (request.value) {
    uploadResult = await wasmxUpload({
      value: request.value,
      fee: request.fee,
      memo: request.memo,
      selectedChain: request.selectedChain,
      gasPrice: request.gasPrice,
    });

    // @ts-ignore
    if (uploadResult.error) {
      return uploadResult;
    }
    // @ts-ignore
    codeId = uploadResult.codeId;
  }
  if (request.codeId) codeId = request.codeId;

  const instantiateResult = await wasmxInstantiate({
    value: request.instantiateMsg,
    codeId,
    label: request.label,
    fee: request.fee,
    memo: request.memo,
    selectedChain: request.selectedChain,
    gasPrice: request.gasPrice,
  })

  return {upload: uploadResult, instantiate: instantiateResult};
}


async function wasmxExecute(request) {
  const {selectedChain: chain, address} = request;
  const wallet = await getCosmosWallet(chain.chainId);
  if (!wallet) {return {error: 'No profile selected'};}

  const client = await WasmXConnectionManager.getSigningClient(wallet, chain, request.gasPrice);
  const [account] = await wallet.getAccounts();

  let msg = request.value;
  if (typeof msg.data === 'string') {
    const datau8 = jsvmutils.hexToUint8Array(msg.data)
    const b64encoded = Buffer.from(datau8).toString('base64');
    msg.data = b64encoded;
  }

  try {
    const result = await client.execute(
      account.address,
      address,
      request.value,
      parseFee(request.fee),
      request.memo,
      request.amount,
    );
    return result;
  } catch (e) {
    return {error: e.message};
  }
}

async function wasmxQuery(request) {
  const client = await WasmXConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.queryContractSmart(request.address, request.value);
    return result;
  } catch(e) {
    return {error: e.message};
  }
}

async function wasmxGetCodes(request) {
  const client = await WasmXConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.getCodes();
    return result;
  } catch(e) {
    return {error: e.message};
  }
}

async function wasmxGetCodeDetails(request) {
  const client = await WasmXConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.getCodeDetails(request.codeId);
    return result;
  } catch(e) {
    return {error: e.message};
  }
}

async function wasmxGetCodeInfo(request) {
  const client = await WasmXConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.getCodeInfo(request.codeId);
    return result;
  } catch(e) {
    return {error: e.message};
  }
}

async function wasmxGetContracts(request) {
  const client = await WasmXConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.getContracts(request.codeId);
    const contracts = [];
    for (let address of result) {
      const contract = await wasmxGetContract({selectedChain: request.selectedChain, address});
      // @ts-ignore
      if (contract && !contract.error) {
        contracts.push(contract);
      }
    }
    return contracts;
  } catch(e) {
    return {error: e.message};
  }
}

async function wasmxGetContract(request) {
  const client = await WasmXConnectionManager.getQueryClient(
    request.selectedChain,
    true,
  );
  try {
    const result = await client.getContract(request.address);
    return result;
  } catch(e) {
    return {error: e.message};
  }
}


async function wasmxQueryCall(request) {
  const chain = request.selectedChain;
  const client = await WasmXConnectionManager.getQueryClient(
    chain,
    true,
  );
  const wallet = await getCosmosWallet(chain.chainId);
  let account = {address: ""};
  if (wallet) {
    const accounts = await wallet.getAccounts();
    if (accounts.length > 0 ) account = accounts[0];
  }

  const funds = request.amount || [];
  const dependencies = request.dependencies || [];
  let msg = request.value;
  if (typeof msg.data === 'string') {
    const datau8 = jsvmutils.hexToUint8Array(msg.data)
    const b64encoded = Buffer.from(datau8).toString('base64');
    msg.data = b64encoded;
  }

  try {
    const result = await queryContractCallWasmX(client, account.address, request.address, msg, funds, dependencies);
    if (result && result.data) {
      result.data = ethers.utils.hexlify(fromBase64(result.data));
    }
    return result;
  } catch(e) {
    return {error: e.message};
  }
}

async function queryContractCallWasmX(client, sender, address, queryMsg, funds, dependencies) {
  try {
      return await client.forceGetQueryClient().wasm.queryContractFull(sender, address, queryMsg, funds, dependencies);
  }
  catch (error) {
      if (error instanceof Error) {
          if (error.message.startsWith("not found: contract")) {
              throw new Error(`No contract found at address "${address}"`);
          }
          else {
              throw error;
          }
      }
      else {
          throw error;
      }
  }
}

async function cosmosSearchTx(request) {
  if (!request.selectedChain || !request.value) {return [];}
  const chain = request.selectedChain;
  const client = await getProviderQuery(chain);
  const results = await client.searchTx(request.value);
  // [{code, events, gasUsed, gasWanted, hash, height, rawLog, tx}]
  return results;
}

async function cosmosEvents(request) {
  const results = await cosmosSearchTx(request);
  return results.map(x => {
    delete x.tx;
    delete x.rawLog;
  });
}

async function ewasmEvents(request) {
  const {abi, value} = request.value;
  const results = await cosmosSearchTx({...request, value: value});
  const allEvents = [];
  results.forEach(x => {
    const events = x.events.filter(ev => ev.type === "wasmxlog");
    return events.map(ev => {
      let data = '0x';
      let topics = [];
      const attrs = ev.attributes.map(attr => {
        const {key, value} = attr;
        if (key === 'data') data = value;
        if (key === 'topic') topics.push(value);
        return {
          key: key,
          value: value,
        }
      });

      let decoded;
      if (abi) {
        // event abi object
        try {
          const iface = new ethers.utils.Interface([abi]);
          decoded = iface.parseLog({data, topics}).args;
        } catch (e) {}
      }

      allEvents.push({
        attributes: attrs,
        data: data,
        topics: topics,
        decoded: decoded,
      });
    })
  })
  return allEvents;
}

export default async function init () {
    const extensions = {
        label: 'cosmos',
        items: [
          {
            label: 'connect',
            value: connectCosmos,
          },
          {
            label: 'chainId',
            value: getCosmosChainId,
          },
          {
            label: 'getAccounts',
            value: getAccounts,
          },
            {
                label: 'request',
                value: sendCosmosRequest,
            },
            {
              label: 'encode',
              value: encodeCosmosRequest,
            },
            {
              label: 'decode',
              value: decodeCosmosData,
            },
            {
              label: 'bech32-from-eth',
              value: bech32FromEth,
            },
            {
              label: 'eth-from-bech32',
              value: ethFromBech32,
            },
            {
              label: 'searchTx',
              value: cosmosSearchTx,
            },
            {
              label: 'events',
              value: cosmosEvents,
            },
            {
              label: 'cosmwasm-upload',
              value: cwUpload,
            },
            {
              label: 'cosmwasm-instantiate',
              value: cwInstantiate,
            },
            {
              label: 'cosmwasm-deploy',
              value: cwDeploy,
            },
            {
              label: 'cosmwasm-execute',
              value: cwExecute,
            },
            {
              label: 'cosmwasm-query',
              value: cwQuery,
            },
            {
              label: 'cosmwasm-query-call',
              value: cwQueryCall,
            },
            {
              label: 'cosmwasm-query-getCodes',
              value: cwGetCodes,
            },
            {
              label: 'cosmwasm-query-getCodeDetails',
              value: cwGetCodeDetails,
            },
            {
              label: 'cosmwasm-query-getContracts',
              value: cwGetContracts,
            },
            {
              label: 'cosmwasm-query-getContract',
              value: cwGetContract,
            },
            {
              label: 'wasmx-upload',
              value: wasmxUpload,
            },
            {
              label: 'wasmx-deploy-code',
              value: wasmxDeployCode,
            },
            {
              label: 'wasmx-instantiate',
              value: wasmxInstantiate,
            },
            {
              label: 'wasmx-deploy',
              value: wasmxDeploy,
            },
            {
              label: 'wasmx-execute',
              value: wasmxExecute,
            },
            {
              label: 'wasmx-query',
              value: wasmxQuery,
            },
            {
              label: 'wasmx-query-call',
              value: wasmxQueryCall,
            },
            {
              label: 'wasmx-query-getCodes',
              value: wasmxGetCodes,
            },
            {
              label: 'wasmx-query-getCodeDetails',
              value: wasmxGetCodeDetails,
            },
            {
              label: 'wasmx-query-getCodeInfo',
              value: wasmxGetCodeInfo,
            },
            {
              label: 'wasmx-query-getContracts',
              value: wasmxGetContracts,
            },
            {
              label: 'wasmx-query-getContract',
              value: wasmxGetContract,
            },
            {
              label: 'wasmx-events',
              value: ewasmEvents,
            },
            {
              label: 'addKeplrChain',
              value: async (chainData) => {
                let config;
                console.log('addKeplrChain', chainData);
                let {data, assets} = toChainRegistryFormat(chainData);
                console.log('data', data, assets);
                try {
                  config = chainRegistryChainToKeplr(data, assets);
                  config.stakeCurrency = { coinDenom: chainData.nativeCurrency.symbol, coinMinimalDenom: chainData.nativeCurrency.base, coinDecimals: chainData.nativeCurrency.maxExponent, coinGeckoId: chainData.nativeCurrency.symbol};
                  config.currencies = [config.stakeCurrency];
                  config.feeCurrencies = [config.stakeCurrency];
                  console.log('config', config);
                } catch (e) {
                  console.log(e);
                  return {error: e.message};
                }
                try {
                  await window.keplr.experimentalSuggestChain(config);
                } catch (e) {
                  console.log(e);
                  return {error: e.message};
                }
              }
            },
        ],
    };

    return {extensions};
}
