import * as distribution from 'cosmjs-types/cosmos/distribution/v1beta1/distribution';
import * as bank from 'cosmjs-types/cosmos/bank/v1beta1/bank';
import * as staking from 'cosmjs-types/cosmos/staking/v1beta1/staking';
import * as slashing from 'cosmjs-types/cosmos/slashing/v1beta1/slashing';
import * as gov from 'cosmjs-types/cosmos/gov/v1beta1/gov';
import * as ibcClient from 'cosmjs-types/ibc/core/client/v1/client';
import * as ibcConnection from 'cosmjs-types/ibc/core/connection/v1/connection';
import * as ibcChannel from 'cosmjs-types/ibc/core/channel/v1/channel';
import * as ibcCommitment from 'cosmjs-types/ibc/core/commitment/v1/commitment';

const allMsgs = {
    ...distribution,
    ...bank,
    ...staking,
    ...slashing,
    ...gov,
    ...ibcClient,
    ...ibcConnection,
    ...ibcChannel,
    ...ibcCommitment,
};

export default allMsgs;

export function getMsgNameFromTypeUrl(path) {
    const args = path.split('.');
    return args[args.length - 1];
}

export function getMsgInstance(typeUrl) {
    const objName = getMsgNameFromTypeUrl(typeUrl);
    let instance = allMsgs[objName];
    if (!instance) {
        instance = allMsgs[objName + 'Proposal'];
    }
    return instance;
}
