import * as d3require from "d3-require";

window.PROV = {};
window.PROV.packages = {};
window.d3require = d3require;
let require1, require2, require3, require4;

// strip version - e.g. name@1.0.19
const packagename = name => {
    let v = name.replace(/-/g, '_').split('@');
    if (v.length === 1) v = v[0];
    else v = v[v.length - 2];
    return v.replace(/\//g, '_');
}

  const tryreq = async (req, name) => {
    let instance;
    try {
        instance = await req(name);
    } catch(e) {
      console.log(e);
    }
    return instance;
}

(async () => {
    require1 = window.require1 = d3require.requireFrom(async name => {
        return `https://bundle.run/${name}`;
    });

    require2 = window.require2 = d3require.requireFrom(async name => {
        return `https://unpkg.com/${name}`;
    });

    require3 = window.require3 = d3require.requireFrom(async name => {
        return `https://cdn.jsdelivr.net/npm/${name}`;
    });

    require4 = window.require4 = d3require.requireFrom(async name => {
      return `https://cdn.skypack.dev/${name}?min`;
    });

    // require4 = window.require4 = (name) => d3require.requireFrom(d3require.resolveFrom(`https://unpkg.com/${name}/`));
})();

// const myResolve = d3.resolveFrom(`https://unpkg.com/${name}`);
// const myRequire = d3.requireFrom(myResolve);

const _require = window.PROV.require = async function(name, alias) {
    name = await name;
    alias = alias === 'nil' ? null : alias;
    const displayName = packagename(name);
    console.log('displayName, name', displayName, name);

    if (window.PROV.packages[displayName]) {
      return window.PROV.packages[displayName];
    }
    if (alias && window.PROV.packages[alias]) {
      return window.PROV.packages[alias];
    }

    let instance;
    instance = await tryreq(d3require.require, name);
    if (!instance) instance = await tryreq(require1, name);
    if (!instance) instance = await tryreq(require2, name);
    if (!instance) instance = await tryreq(require3, name);
    if (!instance) instance = await tryreq(require4, name);

    if (!instance && name.startsWith("http")) {
      const urlfetch = await d3require.requireFrom(async n => name);
      instance = await tryreq(urlfetch, name);
    }

    if (!instance) {
      throw new Error('Npm module not loaded: ' + name);
    }
    window.PROV.packages[displayName] = instance;
    const dn = await window.PROV.require.resolve(displayName);
    window.PROV.require.cache[dn] = instance;
    if (alias) {
        window.PROV.packages[alias] = instance;
        const da = await window.PROV.require.resolve(alias);
        window.PROV.require.cache[da] = instance;
    }

    return instance;
}

// window.require = window.PROV.require;
Object.keys(d3require.require).forEach(key => {
  window.PROV.require[key] = d3require.require[key];
});
window.PROV.require.cache = {};

export default _require;
