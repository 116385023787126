import React from 'react';
import {default as _taylor} from '@ark-us/taylor';
import CodeMirror from '@uiw/react-codemirror';
import {basicSetup} from 'codemirror';
import {EditorState} from '@codemirror/state';
import {StreamLanguage} from '@codemirror/language';
import {clojure} from '@codemirror/legacy-modes/mode/clojure';
import {EditorView, lineNumbers} from '@codemirror/view';
import {javascript} from '@codemirror/lang-javascript';
import {taylor as taylorLanguage} from '@ark-us/codemirror-lang-taylor';
import {markdown} from '@codemirror/lang-markdown';
import { oneDark } from '@codemirror/theme-one-dark';
import { yCollab } from 'y-codemirror.next';
import {zebraStripes} from './components/editor/zebra';
import * as Y from 'yjs';
import { WebsocketProvider } from 'y-websocket'
import {WEBSOCKET_SERVER_COLLAB} from './config';
import {locationParams} from './browser';

function taylorAutocomplete () {
    return Object.keys(_taylor.getAutocompleteList()).map(name => {
        return {label: name, type: 'function'};
    });
}

const codemirrorExtensions = [
    basicSetup,
    EditorState.tabSize.of(4),
    EditorView.lineWrapping,
    oneDark,
    zebraStripes(),
]

const DEFAULT_OPTIONS = {
    theme: 'dark',
    keyMap: 'sublime',
    indentUnit: 4,
    smartIndent: true,
}

const modeMap = {
    solidity: () => 'text/x-solidity',
    asm: () => 'text',
    javascript,
    clojure: () => StreamLanguage.define(clojure),
    taylor: () => taylorLanguage(taylorAutocomplete()),
    markdown,
}

function replaceSelection (doc, replacefn) {
    const selection = doc.getSelection();
    const replacement = replacefn(selection);
    doc.replaceSelection(replacement, selection);
    return doc.getValue();
}

function wrap (doc, m) {
    return replaceSelection(doc, v => m + v + m);
}

function beforeMultiLine(doc, marker) {
    const selection = doc.getSelection();
    const replacement = selection.split('\n').map((v) => marker + v).join('\n');
    doc.replaceSelection(replacement, selection);
    return doc.getValue();
}

const toolbarMap = {
    bold: (doc) => wrap(doc, '**'),
    italic: (doc) => wrap(doc, '*'),
    link: (doc) => replaceSelection(doc, v => `[${v}](${v})`),
    image: (doc) => replaceSelection(doc, v => `![](${v})`),
    code: (doc) => wrap(doc, "`"),
    codeb: (doc) => replaceSelection(doc, v => '```\n' + v + '\n```'),
    h: (doc) => {
        let cursor = doc.getCursor();
        let selection = doc.getLine(cursor.line);
        let replacement = (selection[0] === '#' ? '#' : '# ') + selection + '\n';
        doc.extendSelection({line: cursor.line, ch: 0}, {line: cursor.line + 1, ch: 0});
        doc.replaceSelection(replacement, selection);
        return doc.getValue();
    },
    quote: (doc) => beforeMultiLine(doc, '> '),
    ol: (doc) => {
        let selection = doc.getSelection();
        let replacement = selection.split('\n').map((v, i) => {
            return (i + 1) + '. ' + v;
        }).join('\n');
        doc.replaceSelection(replacement, selection);
        return doc.getValue();
    },
    ul: (doc) => beforeMultiLine(doc, '* '),
    indent: (doc) => beforeMultiLine(doc, '    '),
    deindent: (doc) => {
        let selection = doc.getSelection();
        let replacement = selection.split('\n').map((v) => {
            return v.slice(0, 4).trim() + v.slice(4);
        }).join('\n');
        doc.replaceSelection(replacement, selection);
        return doc.getValue();
    },
    insert: (doc, btndata) => replaceSelection(doc, v => btndata),
}

function handleToolbar ({editor}, btnClicked, btndata) {
    const doc = editor.getDoc();
    return toolbarMap[btnClicked](doc, btndata);
}

async function init (taylor) {

    const MarkdownEditor = (props) => {
        const editorRef = React.createRef();
        const {value, options = {}, onToolbarButtonClick} = props;

        if (onToolbarButtonClick) {
            onToolbarButtonClick((data) => {
                const v = taylor.interop.tay2js(data);
                const [btnClicked, btndata] = v;

                if (!editorRef.current || !editorRef.current.editor) return;
                if (!toolbarMap[btnClicked]) return;

                handleToolbar(editorRef.current, btnClicked, btndata);
            });
        }

        const _codemirrorExtensions = [...codemirrorExtensions];

        const _options = Object.assign({}, DEFAULT_OPTIONS, options);
        const mode = _options.mode && modeMap[_options.mode] ? _options.mode : 'taylor';
        _codemirrorExtensions.push(modeMap[mode]());

        const _onChange = (v) => {
            if (!props.onChange) return;
            props.onChange(v);
        }
        const _value = (typeof value === 'string') ? value : '';

        return (
            <CodeMirror
                ref={editorRef}
                value={_value}
                onChange={_onChange}
                theme={_options.theme}
                extensions={_codemirrorExtensions}
            />
        );
    }

    const extensions = {
        label: '',
        items: [
            {
                label: 'editor-marks',
                value: (props) => <MarkdownEditor {...props}/>,
            },
            {
                label: 'editor',
                value: (props) => <Editor {...props}/>,
            },
            {
                label: 'editor-invite',
                value: (uniqueid) => {
                    const location = window.location;
                    const params = locationParams();
                    params.room = uniqueid;
                    const query = Object.keys(params).map(k => `${k}=${params[k]}`).join('&');
                    return location.origin + location.pathname + '?' + query;
                },
            }
        ]
    }
    return {extensions};
}

export default init;


const usercolors = [
    { color: '#30bced', light: '#30bced33' },
    { color: '#6eeb83', light: '#6eeb8333' },
    { color: '#ffbc42', light: '#ffbc4233' },
    { color: '#ecd444', light: '#ecd44433' },
    { color: '#ee6352', light: '#ee635233' },
    { color: '#9ac2c9', light: '#9ac2c933' },
    { color: '#8acb88', light: '#8acb8833' },
    { color: '#1be7ff', light: '#1be7ff33' }
]

export class Editor extends React.Component {
    constructor (props) {
        super(props);
        this.editorRef = React.createRef();
    }

    // componentDidUpdate(oldProps, oldState) {
    //     console.log('editor componentDidUpdate', oldProps, this.props);
    //     if (!this.editorRef.current || !this.editorRef.current.editor) return;
    // }

    render () {
    const {props} = this;
    const {width, height, value, options = {}, style = {}, children, onSelectedLine} = props;
    let _value = value || children || '';

    const _onChange = (v) => {
        if (!props.onChange) return;
        const currentValue = this.props.value || this.props.children || '';
        if (v !== currentValue) {
            props.onChange(v);
        }
    }

    const selectLine = (editorView, lineNumber) => {
        const position = editorView.state.doc.line(lineNumber).from;

        let transaction = editorView.state.update({
            selection: { anchor: position },
            scrollIntoView: true
        });
        editorView.dispatch(transaction);
    }

    if (onSelectedLine) {
        onSelectedLine((line) => {
            const v = _taylor.interop.tay2js(line);
            if (!this.editorRef.current || !this.editorRef.current.view) return;
            selectLine(this.editorRef.current.view, v);
        });
    }

    const _codemirrorExtensions = [...codemirrorExtensions];
    const _options = Object.assign({}, DEFAULT_OPTIONS, options);
    const mode = _options.mode && modeMap[_options.mode] ? _options.mode : 'taylor';
    _codemirrorExtensions.push(modeMap[mode]());

    if (props.onGutterClick) {
        const lineNumberExtension = lineNumbers({
            domEventHandlers: {
                click: (view, line, event) => {
                    const lineNumber = view.state.doc.lineAt(line.from).number;
                    props.onGutterClick(lineNumber);
                    selectLine(view, lineNumber);
                }
            }
        });
        // replace the base line numbers extension
        _codemirrorExtensions[0].splice(0, 1, lineNumberExtension);
    }

    if (props.collaboration && props.roomId) {
        // select a random color for this user
        const colorndx = Math.floor(Math.random() * 8);
        const userColor = usercolors[colorndx];

        const ydoc = new Y.Doc()
        const provider = new WebsocketProvider(
            WEBSOCKET_SERVER_COLLAB,
            props.roomId,
            ydoc,
        )
        const ytext = ydoc.getText('codemirror')
        const userName = props.userName || ('Anonymous ' + Math.floor(Math.random() * 100));

        provider.awareness.setLocalStateField('user', {
            name: userName,
            color: userColor.color,
            colorLight: userColor.light
        })

        _codemirrorExtensions.push(yCollab(ytext, provider.awareness));
    }
    if (typeof _value !== 'string') _value = '';

    return (
        <div className="editorForView">
            <CodeMirror
                ref={this.editorRef}
                {..._options}
                width={width}
                height={height}
                value={_value}
                onChange={_onChange}
                style={style}
                extensions={_codemirrorExtensions}
            />
        </div>
    );
    }
}
