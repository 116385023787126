import React, { Component } from 'react';
import Recorder from 'react-mp3-recorder';
import ReactAudioPlayer from 'react-audio-player';
import blobToBuffer from 'blob-to-buffer';
import * as arrayb64 from 'base64-arraybuffer';
import {getAudioFileDuration} from '../utils';

export class RecorderComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {};

        this._onRecordingComplete = this._onRecordingComplete.bind(this);
        this._onRecordingError = this._onRecordingError.bind(this);
    }
    _onRecordingComplete = (blob) => {
        blobToBuffer(blob, async (err, buffer) => {
            if (err) {
                console.error(err)
                return
            }
    
            console.log('recording', blob);
        
            if (this.state.url) {
                window.URL.revokeObjectURL(this.state.url);
            }

            const url = window.URL.createObjectURL(blob);
            this.setState({url});

            const duration = await getAudioFileDuration(buffer.buffer.slice(0));
            const fileb64 = arrayb64.encode(buffer);
            const dd = new Date();
            const name = [
                dd.getUTCFullYear().toString().slice(2), 
                dd.getUTCMonth().toString().padStart(2, '0'), 
                dd.getUTCDay().toString().padStart(2, '0'),
                [
                    dd.getUTCHours().toString().padStart(2, '0'), 
                    dd.getUTCMinutes().toString().padStart(2, '0'), 
                    dd.getUTCSeconds().toString().padStart(2, '0')
                ].join(':'),
                Math.ceil(duration).toString().padStart(4, '0'),
            ].join('-');
            const data = {
                size: blob.size,
                type: blob.type,
                extension: 'mp3',
                data: fileb64,
                name: name,
                encoding: 'base64',
            }
            this.props.onRecordingComplete && this.props.onRecordingComplete(url, {value: data, type: 'verbatim'});
        })
    }
    
    _onRecordingError = (err) => {
        console.log('error recording', err)
    
        if (this.state.url) {
          window.URL.revokeObjectURL(this.state.url)
        }
    
        this.setState({ url: null })
        this.props.onRecordingError && this.props.onRecordingError(err);
    }

    render () {
        return (
            <Recorder
            onRecordingComplete={this._onRecordingComplete}
            onRecordingError={this._onRecordingError}
            />
        )
    }
}

export class AudioPlayerComponent extends Component {
    render () {
        const {url} = this.props;

        return (
            <div>
                {url && (
                    <div>
                    <ReactAudioPlayer
                        src={url}
                        controls
                        style={{
                        minWidth: '500px'
                        }}
                    />
                    </div>
                )}
            </div>
        )
    }
}