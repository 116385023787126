import * as d3 from "d3";
import {ColorsPastel} from '../colors';

const randomColors = ColorsPastel;

export function aggregateVotes (data) {
    // data: {weight, optionIndex}
    let optionVotes = {};
    let newdata = [];

    for (let item of data) {
        if (!optionVotes[item.optionIndex]) optionVotes[item.optionIndex] = 0;
        optionVotes[item.optionIndex] += Math.floor(parseInt(item.weight));
    }
    for (let key of Object.keys(optionVotes)) {
        const it = {
            axis: key,
            value: optionVotes[key],
        };
        newdata.push(it);
    }

    // [{axis,value}]
    return newdata;
}

function getMaxValues(data) {
    const maxvals = [];
    for (let itemArray of data) {
        let maxv = 0;
        for (let item of itemArray) {
            if (item.value > maxv) {
                maxv = item.value;
            }
        }
        maxvals.push(maxv);
    }
    return maxvals;
}

function getRandom(min, max) {
    min = min || 0;
    max = max || Number.MAX_SAFE_INTEGER;
    let num = Math.random() * (max - min) + min;
    return Math.round(num);
}

// sample data
// const data = [
//     [
//     {axis:"Sincerity",value:5},
//     {axis:"Excitement",value:5},
//     {axis:"Competence",value:5},
//     {axis:"Sophistication",value:5},
//     {axis:"Ruggedness",value:5},
//     {axis:"Social Responsibility",value:5}]
// ];
export function ContinuousVotingRadial (props) {
    let {
        id,
        data, steps,
        axesDomain: _axesDomain,
        axesColors: _axesColors,
        stepsAxes, predictedSteps,
        graphColors,
        predictedStyle: _predictedStyle,
        width = 600,
        height = 450,
    } = props;

    const maxValues = getMaxValues(data);
    const maxValue = Math.max(...maxValues, ...steps, predictedSteps);
    const maxDomainValue = maxValue + maxValue / 10;
    const colors = graphColors || ["#1976d2"];
    const predictedStyle = _predictedStyle || {color: "#6f6a65", "stroke-dasharray": "2 8"}
    const stepsData = [...steps, predictedSteps];
    let axesColors = {};
    if (_axesColors && typeof _axesColors === 'object') {
        axesColors = {..._axesColors};
    } else {
        for (let i = 0; i < _axesDomain.length; i++) {
            axesColors[_axesDomain[i]] = randomColors[i];
        }
    }
    const axesColorsArray = Object.values(axesColors);
    const stepsColors =  [
        ...stepsAxes.map(v => axesColors[v]),
        predictedStyle.color,
    ].reverse();

    const wrapWidth = 200
    const axisLabelFactor = 1.3
    const dotRadius = 4
    const margin = 50
    const radius = (height-(margin*2)) / 2
    const curveSelect = "curveCardinalClosed"

    if (!document.getElementById(id)) {
        return <p>DOM element not found for graph $#{id}</p>;
    }
    document.getElementById(id).innerHTML = '';

    const axesDomain = _axesDomain || data[0].map(d => d.axis)
    const axesLength =  _axesDomain.length;
    const formatPercent = d3.format(',.0%')
    const angleSlice = Math.PI * 2 / axesLength

    const radarLine = d3.lineRadial()
        .curve(d3[curveSelect])
        .radius(d => rScale(d))
        .angle((d, i) => i * angleSlice)

    const rScale = d3.scaleLinear()
        .domain([0, maxDomainValue])
        .range([0, radius])


    const chart = buildChart();

    function wrap(text, width) {
        text.each(function() {
            var text = d3.select(this),
                words = text.text().split(/\s+/).reverse(),
                word,
                line = [],
                lineNumber = 0,
                lineHeight = 1.4, // ems
                y = text.attr("y"),
                x = text.attr("x"),
                dy = parseFloat(text.attr("dy")),
                tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");

            while (word = words.pop()) {
                line.push(word);
                tspan.text(line.join(" "));
                if (tspan.node().getComputedTextLength() > width) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                }
            }
        });
    }

    function buildChart () {
        // const svg = d3.select(DOM.svg(width, height+(margin*2)));
        const svg = d3.select('#' + id)
            .append("svg")
            .attr("width", "100%")
            .attr("height", "100%")
            .style('width', `100%`)
            .style('height', `100%`)

        const containerWidth = width-(margin*2);
        const containerHeight = height-(margin*2);
        const container = svg.append('g')
        .attr("width", containerWidth)
        .attr("height", containerHeight)
        .attr('transform', `translate(${(width/2)+margin}, ${(height/2)+margin})`);

        var axisGrid = container.append("g")
        .attr("class", "axisWrapper");

        axisGrid.selectAll(".levels")
            .data(stepsData.reverse())
            .enter()
            .append("circle")
            .attr("class", "gridCircle")
            .attr("r", (d, i) => rScale(d))
            .style("fill", (d, i) => stepsColors[i])
            .style("stroke", (d, i) => i > 0 ? null : predictedStyle.color)
            .style("stroke-width", (d, i) => i > 0 ? 0 : 3)
            .style("fill-opacity", (d, i) => i > 0 ? 0.6 : 0.1)
            .style("stroke-dasharray", (d, i) => i > 0 ? null : predictedStyle["stroke-dasharray"])
            .style("stroke-linecap", "round");
        const axis = axisGrid.selectAll(".axis")
            .data(axesDomain)
            .enter()
            .append("g")
            .attr("class", "axis");

        axis.append("line")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", (d, i) => rScale(maxValue*1.1) * Math.cos(angleSlice*i - Math.PI/2))
            .attr("y2", (d, i) => rScale(maxValue*1.1) * Math.sin(angleSlice*i - Math.PI/2))
            .attr("class", "line")
            .style("stroke", (d, i) => axesColorsArray[i])
            .style("stroke-width", "3px")
            .style("stroke-linecap", "round");

        setTimeout(()=>{
        axis.append("text")
            .attr("class", "legend")
            .style("font-size", "18px")
            .style("font-weight", "400")
            .attr("text-anchor", "middle")
            .attr("font-family", "sans-serif")
            .attr("dy", "0.35em")
            .attr("x", (d, i) => rScale(maxValue * axisLabelFactor * 1) * Math.cos(angleSlice*i - Math.PI/2))
            .attr("y", (d, i) => rScale(maxValue * axisLabelFactor * 1) * Math.sin(angleSlice*i - Math.PI/2))
            .text((d, i) => data[0][i].value)
            .attr("fill", (d, i) => axesColorsArray[i]) //nothing
            .call(wrap, wrapWidth)
        }, 0);

        const plots = container.append('g')
        .selectAll('g')
        .data(data)
        .join('g')
            .attr("data-name", (d, i) => axesDomain[i])
            .attr("fill", (_, i) => colors[i])
            .style("fill-opacity", 0.8)
            .attr("stroke", (_, i) => colors[i])
            .style("stroke_width", "3px");

        plots.append('path')
        .attr("d", d => radarLine(d.map(v => v.value)))
        .attr("fill", (d, i) => colors[i])
        .attr("fill-opacity", 0.5)
        .attr("stroke", (d, i) => colors[i])
        .attr("stroke-width", 2);

        plots.selectAll("circle")
            .data(d => d)
            .join("circle")
                .attr("r", dotRadius)
                .attr("cx", (d,i) => rScale(d.value) * Math.cos(angleSlice*i - Math.PI/2))
                .attr("cy", (d,i) => rScale(d.value) * Math.sin(angleSlice*i - Math.PI/2))
                .style("fill-opacity", 0.8)
                .attr("fill", (d, i) => axesColorsArray[i])
                .attr("stroke", (d, i) => axesColorsArray[i]);

        return svg.node();
    }

}
