import React, { Component } from 'react';

const PLUGEM_INJ_MSG = {
    request: 'plugem_adapter_request',
    response: 'plugem_adapter_response',
    subscription: 'plugem_adapter_subscription',
    event: 'plugem_adapter_event',
}

async function init () {
    const extensions = {
        label: 'plugems',
        items: [
            {
                label: 'filesystem-save',
                value: function (...args) {
                    console.log('--plugems-filesystem-save--', args);
                    window.postMessage({type: PLUGEM_INJ_MSG.request, topic: 'filesystem_save', data: args})
                },
            },
            {
                label: 'request',
                value: async function (...fargs) {
                    let topic, args;
                    if (fargs.length === 1) {
                        ({topic, args} = fargs[0]);
                    } else {
                        topic = fargs[0];
                        args = fargs.slice(1);
                    }
                    console.log('plugems_request', !(!window.plugems), topic, args);
                    if (window.plugems) return window.plugems.request(topic, args);
                },
            },
            {
                label: 'response',
                value: async function (...args) {
                    let topic, data, id;
                    if (args.length === 1) {
                        ({topic, data, id} = args[0]);
                    } else {
                        topic = args[0];
                        id = args[1];
                        data = args[2];
                    }
                    console.log('plugems_response', !(!window.plugems), topic, data, id);
                    if (window.plugems) return window.plugems.response(topic, data, id);
                },
            },
        ]
    }
    return {extensions};
}

export default init;

export function request(...args) {
    if (window.plugems) return window.plugems.request(...args);
    return;
}

export function response(...args) {
    if (window.plugems) return window.plugems.response(...args);
    return;
}