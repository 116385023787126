import {ethers} from 'ethers';
import {evmjs, utils} from 'ewasm-jsvm';
import {default as _taylor} from '@ark-us/taylor';
import {
    DEFAULT_TX_INFO,
    parseOpcodeLogs,
} from './utils';

const {uint8ArrayToHex} = utils;

let cache = {}
export async function debugCode (runtimeBytecode, txData, options) {
    let key = ethers.utils.id(JSON.stringify(txData) + runtimeBytecode);
    if (!txData || !key) key = Math.floor(Math.random() * 1000000);
    if (cache[key]) return cache[key];
    else {
        cache[key] = debugCodeInner(runtimeBytecode, txData, options);
        return cache[key];
    }
}

let cachedEvmjs;
export async function debugCodeInner (runtimeBytecode, txData, options = {}) {
    let _evmjs, runtime, tx, result, error;
    window.providerDebug = options.provider;
    if (!txData.data) txData.data = '0x';
    if (txData.hash) {
        _evmjs = evmjs(options);
        try {
            runtime = await _evmjs.simulateTransaction(txData.hash);
        } catch (e) {
            console.error('debugger', e);
            error = e.message;
            runtime = e.runtime;
        }
        result = runtime.result;
        tx = runtime.txInfo;
    }
    else if (!runtimeBytecode && txData.bytecode) {
        _evmjs = evmjs();
        const simulatedAddress = txData.to;
        tx = {
            ...DEFAULT_TX_INFO,
            gasLimit: 14000000,
            ...txData,
        };
        delete tx.bytecode;
        delete tx.args;
        delete tx.to;
        try {
            runtime = await _evmjs.deploy(txData.bytecode, [], simulatedAddress)(txData.args, tx);
        } catch (e) {
            console.error('debugger', e);
            error = e.message;
            runtime = e.runtime;
        }
        result = runtime.address;
    }
    else if (runtimeBytecode) {
        _evmjs = cachedEvmjs || evmjs(options);
        cachedEvmjs = _evmjs;
        runtime = await _evmjs.runtimeSim(runtimeBytecode, [], txData.to);
        tx = {
            ...DEFAULT_TX_INFO,
            ...txData,
            data: txData.data.slice(0, 2) === '0x' ? txData.data : '0x' + txData.data,
            from: txData.from || DEFAULT_TX_INFO.from,
        };
        if (tx.gasPrice && tx.gasPrice._hex) tx.gasPrice = tx.gasPrice._hex;
        if (tx.gasLimit && tx.gasLimit._hex) tx.gasLimit = tx.gasLimit._hex;
        // console.log('tx', tx);

        try {
            result = await runtime.mainRaw(tx);
        } catch (e) {
            console.error('debugger.mainRaw ', e);
            error = e.message;
        }

        if (!result || result.length === 0) result = null;
        else result = uint8ArrayToHex(result);
    }
    else return [{value: [], type: 'verbatim'}, {count: 0, result: null, dappresult: null, receipt: {}, error: 'No bytecode'}, () => {}];

    return parseOpcodeLogs(result, runtime.logs, tx, options, runtime.gas, error);
}
