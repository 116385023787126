
export function Icon (props = {}) {
    const src = props.src || ("./menu/" + props.title + '.svg')
    const width = (props.size || props.width || 20) + 'px';
    const height = (props.size || props.height || 20) + 'px';
    const _onClick = () => props.onClick ? props.onClick() : null;
    const style = {
        ...(props.style || {}),
        ...{maxWidth: '100%', width, height},
    }
    return <img onClick={_onClick} style={style} src={src}></img>
}
