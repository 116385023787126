import * as d3 from "d3";
import Cosy from './cosy';
import './graphs.css';
import { ContinuousVotingRadial, aggregateVotes } from './graphsRadial';

export function accumulateVotes (data, decimals = 0) {
    // data: {weight, optionIndex}
    let optionVotes = [];
    let newdata = [];
    const base = 10 ** decimals;

    for (let item of data) {
        const it = {...item};
        if (!optionVotes[it.optionIndex]) optionVotes[it.optionIndex] = 0;
        optionVotes[it.optionIndex] += Math.floor(parseInt(it.weight) / base);
        optionVotes.forEach((value, index) => {
            it[index] = value;
        });
        newdata.push(it);
    }
    return newdata;
}

function ContinuousVoting (props) {
    let {id, ratio, data: _data, axes, axesLabels, onChange} = props;

    ratio = ratio || {x: 2, y: 1};
    axes = axes || [0, 1];
    axesLabels = axesLabels || ['x', 'y'];

    let data = _data || [];
    const origin = {};
    origin[axes[0]] = 0;
    origin[axes[1]] = 0;
    data.splice(0, 0, origin);

    const size = 500;
    const width = size;
    const height = size;
    const margin = 50;

    let p = ratio.x / ratio.y;
    const [fn, fn2, fn3] = buildFns(p);

    const lineColor = '#7a7373';
    const dataColor = '#d5d5d5';

    if (!document.getElementById(id)) return <p>DOM element not found for graph $#{id}</p>;
    document.getElementById(id).innerHTML = '';
    const getx = (value) => value[axes[0]] || 0;
    const gety = (value) => value[axes[1]] || 0;

    const svg = d3.select('#' + id)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .style('width', `${width}px`)
        .style('height', `${height}px`)


    let max = Math.max(getx(data[data.length -1]), gety(data[data.length -1]))
    if (max === 0) max = 10;

    const chart = svg.node();
    const tick = Math.min(Math.max(Math.floor(max/20), 1), 20);
    const cosy = new Cosy(chart, {xmin: 0, xmax: max, ymin: 0, ymax: max, margin});
    cosy.xGridlines(cosy.xticks(tick).filter(n => n !== 0));
    cosy.yGridlines(cosy.yticks(tick).filter(n => n !== 0));
    cosy.xAxis(cosy.xticks(tick).filter(n => n !== 0 && n !== cosy.xmin && n !== cosy.xmax), axesLabels[0]);
    cosy.yAxis(cosy.yticks(tick).filter(n => n !== 0 && n !== cosy.ymin && n !== cosy.ymax), axesLabels[1]);

    cosy.clear();
    let midline = cosy.line(cosy.xmin, fn(cosy.xmin), cosy.xmax, fn(cosy.xmax), {color: lineColor});
    let slope1 = cosy.line(cosy.xmin, fn2(cosy.xmin), cosy.xmax, fn2(cosy.xmax), {color: lineColor});
    let slope2 = cosy.line(cosy.xmin, fn3(cosy.xmin), cosy.xmax, fn3(cosy.xmax), {color: lineColor});

    function started(event) {
        const circle = d3.select(this).classed("dragging", true);
        let ratio;

        event.on("drag", dragged).on("end", ended);

        function dragged(event) {
            const {x, y} = event;
            const _x = cosy.xscale.invert(x);
            const _y = cosy.yscale.invert(y);
            ratio = _x / _y;

            circle.raise().attr("cx", x).attr("cy", y);

            const [fn, fn2, fn3] = buildFns(ratio);
            slope1.remove();
            slope2.remove();
            slope1 = cosy.line(cosy.xmin, fn2(cosy.xmin), cosy.xmax, fn2(cosy.xmax), {color: lineColor});
            slope2 = cosy.line(cosy.xmin, fn3(cosy.xmin), cosy.xmax, fn3(cosy.xmax), {color: lineColor});
        }

        function ended() {
            circle.classed("dragging", false);
            const y = 1000;
            const x = Math.floor(y * ratio);
            onChange({x, y});
        }
    }
    if (onChange) {
        const point = cosy.point(cosy.xmax, fn3(cosy.xmax), {color: lineColor, r: 6});
        point.call(d3.drag().on("start", started));
    }

    for (let i = 0; i <data.length; i++) {
        if (i>0) {
            cosy.line(getx(data[i-1]), gety(data[i-1]), getx(data[i]), gety(data[i]), {color: dataColor, strokeWidth: '2px'});
        } else {
            cosy.line(getx(data[i]), gety(data[i]), getx(data[i]), gety(data[i]), {color: dataColor, strokeWidth: '2px'});
        }
        cosy.point(getx(data[i]), gety(data[i]), {color: dataColor, r: 6});
    }
}

function buildFns (p) {
    let m = 0
    let c = 1
    let fn = x => c * x + m
    let fn2 = x => p*x
    let fn3 = x => 1/p*x
    return [fn, fn2, fn3];
}

export const voting = {
    continuous: ContinuousVoting,
    radial: ContinuousVotingRadial,
    aggregateVotes: aggregateVotes,
}
