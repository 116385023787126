import {ethers} from 'ethers';
import BN from 'bn.js';
import {apiExport} from '../../utils';
import { utils as jsvmUtils, ewasmjsvm, evmjs as _evmjs } from 'ewasm-jsvm';
const {strip0x} = jsvmUtils;
window.ethers = ethers;

export function getCustomProvider (newprovider) {
    return {
        ...window.ethereum,
        request: async (...args) => {
            console.log('InjectedProvider custom request', args, newprovider);
            if (newprovider.request) {
                const result = await newprovider.request(...args);
                if (result !== null) return result;
            }
            console.log('custom request default to provider', ...args);
            const result = await window.ethereum.request(...args);
            console.log('result', result);
            return result;
        },
        send: async (...args) => {
            console.log('InjectedProvider custom send', args);
            if (args[0] === 'eth_accounts' || args[0] === 'eth_requestAccounts') {
                return window.ethereum.send(...args);
            }

            return new Promise((resolve, reject) => reject(new Error('overridden')));
        },
        sendAsync: (...args) => {
            console.log('InjectedProvider custom sendAsync', args);
            return new Promise((resolve, reject) => reject(new Error('overridden')));
        },
        _isCustom: true,
    }
}

export const injectedProvider = `
window.customvar = 99;
class InjectedProvider {
    constructor () {
        this._isCustom = true;
        this.isMetaMask = true;
        this.count = 1;
    }
    enable () {
        this.enabled = true;
        console.log('InjectedProvider ---enable');
        return window.ethereum_provider.enable();
    }
    async request (...args) {
        // const result = await newprovider.request(...args);
        // if (result !== null) return '0x'; // new Error('overridden');

        if (args[0].method === 'eth_call') {
            const message = {
                message: args,
                type: 'customprovider',
                id: this.count,
            }
            this.count += 1;
            console.log('InjectedProvider request', args[0].method, ...args[0].params);
            return new Promise((resolve, reject) => {
                window.addEventListener('message', function(e) {
                    const data = e.data;
                    if (data.id !== message.id || data.type !== message.type) return;
                    resolve(data.message);
                });
                window.parent.postMessage(message, '*');
            });
        }
        else if (args[0].method === 'eth_sendTransaction') {
            const message = {
                message: args,
                type: 'customprovider',
                id: this.count,
            }
            this.count += 1;
            console.log('InjectedProvider request', message, args[0].method, ...args[0].params);
            return new Promise((resolve, reject) => {
                window.addEventListener('message', function(e) {
                    const data = e.data;
                    if (data.id !== message.id || data.type !== message.type) return;
                    resolve(data.message);
                });
                window.parent.postMessage(message, '*');
            });
        }
        else if (args[0].method === 'eth_estimateGas') {
            return new Promise((resolve, reject) => {
                resolve(20000000);
            });
        }
        else {
            console.log('Unknown request - sending to wallet provider', args);
            return window.ethereum_provider.request(...args);
        }
    }
    async send (...args) {
        console.log('InjectedProvider send', args);
        if (args[0] === 'eth_accounts' || args[0] === 'eth_requestAccounts') {
            const accounts = await window.ethereum_provider.send(...args);
            return accounts;
        }
        const allowed = {
            'net_version': true,
            'eth_chainId': true,
        }
        if (allowed[args[0]]) return window.ethereum_provider.send(...args);
        return new Promise((resolve, reject) => reject(new Error('overridden')));
    }
    sendAsync (...args) {
        console.log('InjectedProvider sendAsync', args);
        return new Promise((resolve, reject) => reject(new Error('overridden')));
    }
}
const customEthereum = new InjectedProvider();

function pollingWeb3() {
    console.log('InjectedProvider pollingWeb3');

    const checkid = setInterval(repeatcheck, 100);

    function repeatcheck() {
        console.log('InjectedProvider repeatcheck _isCustom', window.ethereum ? window.ethereum._isCustom : null);
        if (window.ethereum && !window.ethereum._isCustom) {
            window.ethereum_provider = window.ethereum;
            window.ethereum = customEthereum;
            clearInterval(checkid);
        }
        else if (window.ethereum && window.ethereum._isCustom) {
            clearInterval(checkid);
        }
    }
}
pollingWeb3();
                `

async function init () {
    let _cachedProvider, _cachedSigner;
    let provider;
    let signer;

    async function setProvider () {
        if (window.ethereum) {
            await window.ethereum.enable();
            provider = new ethers.providers.Web3Provider(window.ethereum);
            signer = provider.getSigner();
            window.provider = provider;
            window.signer = signer;
            provider.provider.on('chainChanged', (_chainId) => {
                console.log('-----chainChanged--_chainId---', _chainId);
                provider = new ethers.providers.Web3Provider(window.ethereum);
                console.log('-----chainChanged-----', provider.getNetwork());
                signer = provider.getSigner();
                _cachedProvider = provider;
                _cachedSigner = signer;
                window.provider = provider;
                window.signer = signer;
            });
        }
        else {
            provider = ethers.getDefaultProvider();
        }
        console.log('provider set MM')
        _cachedProvider = provider;
        _cachedSigner = signer;
        window.provider = provider;
        window.signer = signer;
    }
    await setProvider();

    const extensions = {
        label: 'eth',
        items: [
            {
                label: 'connect',
                value: () => {
                    return setProvider();
                },
            },
            {
                label: 'provider',
                value: () => {
                    return provider;
                },
            },
            {
                label: 'signer',
                value: () => {
                    return signer;
                },
            },
            {
                label: 'provider-real',
                value: () => {
                    return _cachedProvider;
                },
            },
            {
                label: 'signer-real',
                value: () => {
                    return _cachedSigner;
                },
            },
            {
                label: 'listen',
                value: (eventName, callback) => {
                    console.log('listen', eventName);
                    if (!provider || !provider.provider) return;
                    provider.provider.on(eventName, callback);
                    return null;
                },
            },
            {
                label: 'listen-once',
                value: (eventName, callback) => {
                    if (!provider || !provider.provider) return;
                    provider.provider.once(eventName, callback);
                    return null;
                },
            },
            {
                label: 'listen-off',
                value: (eventName, callback) => {
                    console.log('provider-off', eventName);
                    if (!provider || !provider.provider) return;
                    provider.provider.off(eventName, callback);
                    return null;
                },
            },
            {
                label: 'override-provider',
                value: (newprovider) => {
                    if (!newprovider) {
                        provider = _cachedProvider;
                        provider._provider = provider.provider;
                        signer = _cachedSigner;
                    }
                    else {
                        const _provider = getCustomProvider(newprovider);
                        provider = new ethers.providers.Web3Provider(_provider);
                        provider._provider = _provider;
                        provider.count = 0;
                        signer = provider;
                        signer._isSigner = true;
                        signer.getAddress = async (...args) => {
                            const result = await _cachedSigner.getAddress(...args);
                            return result;
                        }
                    }

                    window.addEventListener('message', async function(e) {
                        const data = e.data;
                        if (data.type !== 'customprovider') return;

                        e.preventDefault();
                        e.stopPropagation();
                        e.stopImmediatePropagation();

                        // TODO remove this. it is probably solved by the above
                        // avoid sending the same request multiple times
                        const previd = provider.count;
                        if (data.id <= provider.count) return;
                        provider.count = data.id;

                        const response = await provider._provider.request(...data.message);
                        const message = {
                            message: response,
                            id: data.id,
                            type: data.type,
                        }
                        console.log('custom provider message', message);
                        e.source.postMessage(message, '*');
                    });

                },
            },
            {
                label: 'provider-injected',
                value: () => {
                    return injectedProvider;
                }
            },
            {
                label: 'provider-javascript',
                value: () => {
                    const evmjs = _evmjs({provider});
                    return {
                        request: async (req) => {
                            const DEFAULT_TX = {
                                gasLimit: 3000000,
                                gasPrice: 10,
                                value: 0,
                            }
                            const {method, params} = req;
                            if (method !== 'eth_call' && method !== 'eth_sendTransaction') return null;
                            const txObj = {...DEFAULT_TX, ...params[0]};
                            if (!txObj.from && _cachedSigner) txObj.from = await _cachedSigner.getAddress();
                            let runtime;
                            if (!txObj.to) {
                                runtime = await evmjs.deploy(txObj.data, []);
                            }
                            else runtime = await evmjs.runtime(txObj.to, []);
                            if (runtime) {
                                const result = await runtime.mainRaw({...txObj});
                                console.log('--providerjavascript-- result', result);
                                return result;
                            }
                            return null;
                        }
                    }
                }
            },
            {
                label: 'provider-interpreter',
                value: (iaddress) => {
                    if (!iaddress || typeof iaddress !== 'string' || iaddress.slice(0, 2) !== '0x') return null;

                    return {
                        request: async (req) => {
                            const {method, params} = req;
                            console.log('provider-interpreter', method, params)
                            if (method !== 'eth_call' && method !== 'eth_sendTransaction') return null;
                            const txObj = params[0];
                            let bytecode = txObj.to ? await _cachedProvider.getCode(txObj.to) : '';
                            bytecode = strip0x(bytecode);

                            if (bytecode.length > 0) bytecode = '0x' + (bytecode.length).toString(16).padStart(64, '0') + bytecode;

                            let calldata = txObj.data ? strip0x(txObj.data) : '';
                            calldata = (calldata.length).toString(16).padStart(64, '0') + calldata;

                            const tx = {
                                ...txObj,
                                to: iaddress,
                                data: bytecode + calldata,
                            }
                            tx.gasLimit = 6000000; // tx.gasLimit || tx.gas;
                            delete tx.gas;
                            const result = await _cachedProvider.call(tx);
                            const receipt = {
                                result,
                                to: tx.to,
                                from: tx.from,
                                transactionHash: '0x9c88c0adc03b9ba39257a16ba4c5cafc72d203dcadb83fe64f74337fa5d23cdd',
                                status: 1,
                                blockHash: '0x9c88c0adc03b9ba39257a16ba4c5cafc72d203dcadb83fe64f74337fa5d23cdd',
                                blockNumber: 1343342,
                            }
                            receipt.hash = receipt.transactionHash;
                            console.log('receipt', receipt);
                            return receipt;
                        }
                    }
                },
            },
            {
                label: 'provider-custom',
                value: (rpcUrlOrType, methodName, args) => {
                    const provider = new ethers.providers.getDefaultProvider(rpcUrlOrType);
                    return provider[methodName](...args);
                },
            },
            {
                label: 'provider-getBalance',
                value: (...args) => {
                    console.log('-getBalance-', args);
                    return provider.getBalance(...args.slice(0, -1));
                },
            },
            {
                label: 'provider-resolveName',
                value: (...args) => {
                    console.log('-resolveName-', args);
                    return provider.resolveName(...args.slice(0, -1));
                },
            },
            {
                label: 'provider-getNetwork',
                value: (...args) => {
                    return provider.getNetwork(...args.slice(0, -1));
                },
            },
            {
                label: 'provider-getCode',
                value: (address) => {
                    return provider.getCode(address);
                },
            },
            {
                label: 'signer-getAddress',
                value: () => {
                    return signer.getAddress();
                },
            },
            {
                label: 'signer-signTypedData',
                value: (...args) => {
                    return signer._signTypedData(...args);
                },
            },
            {
                label: 'Contract',
                value: (address, abi) => {
                    return new ethers.Contract(address, abi, signer || provider);
                }
            },
            {
                label: 'sendTransaction',
                value: async (txrequest) => {
                    if (!signer) return 'Connect to Wallet';
                    let receipt = await signer.sendTransaction(txrequest).catch(e => e.message);
                    if (receipt.wait) receipt = await receipt.wait();
                    return receipt;
                }
            },
            {
                label: 'call',
                value: async (txrequest) => {
                    if (!signer) return 'Connect to Wallet';
                    const result = await signer.call(txrequest).catch(e => e.message);
                    return result;
                }
            },
            {
                label: 'deploy',
                value: async (bytecode, abi, ...args) => {
                    if (!bytecode || typeof bytecode !== 'string') bytecode = '0x';
                    abi = abi || ["constructor()"];
                    bytecode = bytecode.trim();
                    if (bytecode.slice(0, 2) !== '0x') bytecode = '0x' + bytecode;
                    if (!signer) return 'No signer';
                    const factory = new ethers.ContractFactory(abi, bytecode, signer);
                    const contract = await factory.deploy(...args);
                    const receipt = await contract.deployTransaction.wait();
                    return receipt;
                }
            },
            {
                label: 'utils-formatEther',
                value: (amount) => {
                    return ethers.utils.formatUnits(amount || '0', 'ether');
                }
            },
            {
                label: 'utils-parseUnits',
                value: (amount) => {
                    return ethers.utils.parseUnits(amount || '0', 'ether');
                }
            },
            {
                label: 'pay',
                value: async (to, value, gasLimit, gasPrice) => {
                    if (!signer) return 'Connect to Wallet';

                    const _value = valueToHex(value);
                    const _gasLimit = valueToHex(gasLimit);
                    const _gasPrice = new BN(valueToHex(gasPrice), 16).mul(new BN('1000000000', 10)).toString(16);
                    console.log('--pay', to, _value, _gasLimit, _gasPrice);

                    const tx = await signer.sendTransaction({to, value: _value, gasLimit: _gasLimit, gasPrice: _gasPrice});
                    return tx.wait ? tx.wait(1) : tx;
                }
            },
            {
                label: 'abi-fn-encode',
                value: (fnabi, values) => {
                    let interf = new ethers.utils.Interface([fnabi]);
                    if (fnabi.name === 'constructor') {
                        return interf.encodeDeploy(values);
                    }
                    return interf.encodeFunctionData(fnabi.name, values);
                }
            },
            {
                label: 'abi-fn-decode',
                value: (fnabi, data) => {
                    let interf = new ethers.utils.Interface([fnabi]);
                    return interf.decodeFunctionResult(fnabi.name, data);
                }
            },
            {
                label: 'abi-encode',
                value: (abi, fname, values) => {
                    let interf = new ethers.utils.Interface(abi);
                    if (fname === 'constructor') {
                        return interf.encodeDeploy(values);
                    }
                    return interf.encodeFunctionData(fname, values);
                }
            },
            {
                label: 'abi-decode',
                value: (abi, fname, data) => {
                    let interf = new ethers.utils.Interface(abi);
                    return interf.decodeFunctionResult(fname, data);
                }
            },
            {
                label: 'abi-interface',
                value: (methodName, abi, ...args) => {
                    let interf = new ethers.utils.Interface(abi);
                    return interf[methodName](...args);
                }
            },
            {
                'label': 'wallet-addEthereumChain',
                value: async (chainData) => {
                    // {chainId, chainName, rpcUrls: []}
                    try {
                        return await window.ethereum.request({
                          method: 'wallet_addEthereumChain',
                          params: [chainData],
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }
            },
            {
                'label': 'wallet-switchEthereumChain',
                value: async (chainId, namespace) => {
                    try {
                        return await window.ethereum.request({
                          method: 'wallet_switchEthereumChain',
                          params: [{ chainId, namespace}],
                        });
                      } catch (e) {
                        // This error code indicates that the chain has not been added to MetaMask.
                        if (e.code === 4902) {
                          console.error(e)
                        }
                        // handle other "switch" errors
                      }
                }
            }
        ]
    }

    extensions.items = apiExport(ethers.utils, 'utils-', extensions.items);
    extensions.items = apiExport(provider, 'provider-', extensions.items);
    extensions.items = apiExport(signer, 'signer-', extensions.items);

    return {extensions};
}

export default init;

export function valueToHex (value) {
    if (typeof value !== 'string') value = value.toString();
    if (value.slice(0, 2) === '0x') return value;
    return '0x' + (new BN(value, 10)).toString(16);
}
