import React from 'react';
import { withTheme } from '@rjsf/core';
import { Theme as AntDTheme } from '@rjsf/antd';
import {abiFuncToFormSchema} from './io2schema';

const Form = withTheme(AntDTheme);

export const RECEIPT_TYPE = {
    name: 'receipt',
    type: 'tuple',
    components: [
      // {
      //   name: 'etherscan',
      //   type: 'string',
      // },
      {
        name: 'transactionHash',
        type: 'string',
      },
      {
        name: 'blockHash',
        type: 'string',
      },
      {
        name: 'blockNumber',
        type: 'uint256',
      },
      {
        name: 'confirmations',
        type: 'uint256',
      },
    ]
}

export function AbiFormFunction(props) {
    const {abi, formRef, onError, onChange, values} = props;
    if (abi.stateMutability === 'nonpayable' || abi.stateMutability === 'payable') abi.outputs = RECEIPT_TYPE.components;
    if (!abi.outputs) abi.outputs = [];

    const _onChange = async ({formData}) => {
        if (onChange) onChange(JSON.parse(JSON.stringify(formData)));
    }
    const _onError = onError;

    const id1 = abi.name + new Date().getTime();
    const schemas = abiFuncToFormSchema(abi, values);

    return (
        <Form
            id={id1}
            ref={(form) => {
                if (formRef && typeof formRef === 'function') formRef(form);
            }}
            schema={schemas.schema}
            uiSchema={schemas.uiSchema}
            onChange={_onChange}
            onError={_onError}

        ><></>
        </Form>
    )
}

export function AbiForm(props) {
    const {abi, contractInstance} = props;

    const functions = abi.filter(fabi => fabi.type === 'function')
        .map((fabi, i) => {
            return <AbiFormFunction key={i} abi={fabi} contractInstance={contractInstance} />
        });

    const style = {
        overflowY: 'auto',
        width: '100%',
        height: '100%',
    }

    return (
        <div style={style}>{functions}</div>
    )
}

async function init () {
    const extensions = {
        label: 'abiform',
        items: [
            {
                label: '',
                value: (props) => {
                    if (!props.abi) return <p>Abi missing</p>;
                    if (props.abi instanceof Array) return <AbiForm {...props}/>;
                    return <AbiFormFunction {...props}/>;
                }
            }
        ]
    }

    return {extensions};
}

export default init;
