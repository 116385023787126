import {ethers} from 'ethers';
import {evmjs, utils} from 'ewasm-jsvm';
import {default as _taylor} from '@ark-us/taylor';

const {strip0x, uint8ArrayToHex, toBN} = utils;

export function updateMemValue (pos, mem, key, _value, prevMemory) {
    let item = mem[key];
    if (!item) {
        const memkey = (key * 32).toString(16).padStart(4, '0');
        item = {
            index: memkey,
        }
    }
    const value = strip0x(uint8ArrayToHex(_value))
    let changed = pos;
    if (prevMemory[key] && prevMemory[key].value == value) {
        changed = prevMemory[key].changed;
    }
    Object.assign(item, {
        _value,
        value,
        ascii: ascii(_value),
        changed,
        diff: pos - changed,
        classes: 'debug_row row_opcode_' + changed,
    });
    return item;
}

export function prepMemory (pos, changedMemory = [], prevMemory = {}) {
    let _mem = cloneMem(prevMemory);
    const [offset, value, iswritten] = changedMemory;
    if (typeof offset === 'undefined') return _mem;
    const start = Math.floor(offset / 32);
    const end = Math.ceil((offset + value.length) / 32);
    const positions = end - start;
    let soffset = offset - (start * 32);
    let cl = '';
    if (typeof iswritten === 'undefined') {
        cl = ' row_storage_' + iswritten;
    }
    if (_mem[start] && _mem[start].state !== iswritten) {
        cl = ' row_storage_2';
    }
    if (iswritten === 1) {
        let remaining = [...value];
        for (let slot = 0; slot < positions; slot++) {
            const key = start + slot;
            let _value = prevMemory[key] ? prevMemory[key]._value : new Uint8Array(32);
            const valuelen = 32 - soffset;
            _value = replaceInArray(_value, soffset, remaining.slice(0, valuelen))

            const item = updateMemValue(pos, _mem, key, _value, prevMemory);
            item.state = iswritten;
            item.classes += cl;
            _mem[key] = item;
            soffset = 0;
            remaining = remaining.slice(valuelen);
        }
    }
    return _mem;
}

// [Uint8Array, ... ]
export function prepStack (pos, stack, _prevstack) {
    const len = stack.length;
    const prevstack = [..._prevstack].reverse();
    return stack.map((_value, i) => {
        const index = len - i - 1;
        const value = strip0x(uint8ArrayToHex(_value));
        let changed = pos;

        const previ = i;
        if (previ > -1 && prevstack[previ] && prevstack[previ].value == value) {
            changed = prevstack[previ].changed;
        }
        const item = {
            pos,
            index,
            value,
            ascii: ascii(_value),
            changed: changed,
            diff: pos - changed,
            classes: 'debug_row row_opcode_' + changed,
        }
        return item;
    }).reverse();
}

export function slicePieces (str, slotsize) {
    return [...new Array(Math.ceil(str.length / slotsize)).keys()].map(i => str.slice(i * slotsize, (i + 1) * slotsize));
}

export function ascii (arr) {
    return [...arr].map(v => {
        const code = (v && v > 32 && v < 123) ? v : 63;
        return String.fromCharCode(code);
    }).join('');
}

export function prepStorage (pos, storage = {}, prevStorage = {}, changedPosition = []) {
    const _storage = {};
    const [start, v, iswritten] = changedPosition;
    // console.log('changedPosition', start, v, iswritten);
    Object.keys(storage).forEach((key, i) => {
        const skey = strip0x(key);
        const value = strip0x(uint8ArrayToHex(storage[key]));
        let changed = pos;
        let cl = '', state;
        if (start === skey) {
            state = iswritten;
            cl = ' row_storage_' + iswritten;
        }
        if (prevStorage[skey] && prevStorage[skey].value == value) {
            changed = prevStorage[skey].changed;
            if (start === skey && typeof prevStorage[skey].state !== 'undefined' && prevStorage[skey].state !== state) {
                cl = ' row_storage_2';
            }
            if (start === skey && !state) state = prevStorage[skey].state;
        }
        const item = {
            index: skey,
            value,
            ascii: ascii(storage[key]),
            changed,
            diff: pos - changed,
            classes: 'debug_row row_opcode_' + changed + cl,
            state,
        }
        _storage[skey] = item;
    });
    return _storage;
}

export const DEFAULT_TX_INFO = {
    gasLimit: 2000000,
    gasPrice: 10,
    from: '0x79f379cebbd362c99af2765d1fa541415aa78508',
    value: 0,
}

export function cloneMem (mem) {
    const _mem = {};
    Object.keys(mem).forEach((key) => {
        _mem[key] = {...mem[key]};
    });
    return _mem;
}

export function replaceInArray (iniarray, offset, value) {
    if (!iniarray) iniarray = new Uint8Array(32);
    [...value].forEach((v, i) => {
        iniarray[offset + i] = v;
    });
    return iniarray;
}

export async function parseOpcodeLogs (result, opcodeLogs, tx, options = {}, gasObj = null, error = null) {
    let opcodes = [];
    let pcToStep = [];
    let prevMemory = {};
    let prevStorage = {};
    let pcCount = {};
    let gasUsed = toBN(0);
    opcodeLogs.forEach((log, i) => {
        const {stack, output, name, input, context, contractAddress, changed = {}, position, gasCost, addlGasCost, refundedGas, logs} = log;
        const contract = context[contractAddress] || {};
        const _gasCost = gasCost ? toBN(gasCost) : toBN(0);
        const _addlGasCost = addlGasCost ? toBN(addlGasCost) : toBN(0);
        const _refundedGas = refundedGas ? toBN(refundedGas) : toBN(0);
        gasUsed = gasUsed.add(_gasCost).add(_addlGasCost).sub(_refundedGas);

        let _logs = logs ? logs : [];
        _logs = _logs.map((v, i) => {
            const topics = v.topics.map(t => '0x' + t.toString(16).padStart(64, '0'));
            return {
                index: i,
                address: '0x' + uint8ArrayToHex(v.address).substr(26),
                data: uint8ArrayToHex(v.data),
                topics,
                _topics: topics.join(' '),
            }
        });

        const prev = opcodes[i - 1] || {};
        const _stack = prepStack(i, stack, prev.stack ? prev.stack.value : []);
        const _memory = prepMemory(i, changed.memory, prevMemory);
        const __memory = Object.values(_memory).map(v => {const t = {...v}; delete t._value; return t})
        const _storage = prepStorage(i, contract.storage, prevStorage, changed.storage);
        const __storage = Object.values(_storage);
        if (!pcCount[position]) pcCount[position] = 1;
        else pcCount[position] ++;

        if (!pcToStep[position]) pcToStep[position] = [];
        pcToStep[position].push(i);

        opcodes.push({
            pc: position,
            pcCount: pcCount[position],
            step: i,
            name,
            stack: {value: _stack, type: 'verbatim'},
            stackLength: _stack.length,
            output: {value: output, type: 'verbatim'},
            input: {value: input, type: 'verbatim'},
            memory: {value: __memory, type: 'verbatim'},
            memoryLength: __memory.length,
            storage: {value: __storage, type: 'verbatim'},
            storageLength: __storage.length,
            classes: 'debug_row row_opcode_' + i,
            gasCost: _gasCost.toNumber(),
            addlGasCost: _addlGasCost.toNumber(),
            refundedGas: _refundedGas.toString(10),
            gasUsed: gasUsed.toString(10),
            logs: _logs,
        });
        prevMemory = _memory;
        prevStorage = _storage;
    });
    opcodes[0].pc = '';
    // opcodes = opcodes.slice(0, 200);
    console.log('opcodes', opcodes.length, new Date().getTime());

    let lastblock;
    if (options.provider) {
        lastblock = await options.provider.getBlock();
    } else {
        lastblock = {transactions: []};
    }

    const _gasUsed = gasObj ? gasObj.used.toNumber() : gasUsed;
    const receipt = {
        gasUsed: _gasUsed,
        logs: [],
        cumulativeGasUsed: _gasUsed,
        status: error ? 0 : 1,
        to: tx.to,
        from: tx.from,
        transactionHash: lastblock.transactions[0] || '0x01c68223adbf8d83015552ce2486ba8e36205d74d7275726dd2f7adaad636fa8',
    }
    let dappresult;
    if (result) dappresult = JSON.parse(JSON.stringify(result));
    const pcToStepFn = (_pc, _iteration) => {
        const v = _taylor.interop.tay2js(_pc);
        let iteration = _iteration ? parseInt(_taylor.interop.tay2js(_iteration)) : null;
        iteration = typeof iteration === 'number' ? iteration : 0;
        const steps = pcToStep[v] || [];
        const step = steps[iteration] ? steps[iteration] : steps[0];
        return _taylor.interop.jsToTay(step);
    }
    return [{value: opcodes, type: 'verbatim'}, {count: opcodes.length, result: result, dappresult, receipt, error}, pcToStepFn];
}
