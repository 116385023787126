const { Certificate } = require("@fidm/x509");
import { toHex, hexToPoint, getHiLo, hexToPem } from "./utils";
import { hwcrypto } from "./hwcrypto";
const hw = hwcrypto;

async function init () {
    let enabled = false;
    let cert;
    function enable () {
        if (enabled) return hw;
        enabled = true;
        return enableHw();
    }
    async function getCert() {
        if (cert) return cert;
        cert = await getHwCert(hw);
        return cert;
    }
    const extensions = {
        label: 'estonia-id',
        items: [
            {
                label: 'enable',
                value: () => enable,
            },
            {
                label: 'getCertCoords',
                value: async () => {
                    const cert = await getCert();
                    const coords = await getCertCoords(cert);
                    return coords;
                },
            },
            {
                label: 'sign',
                value: async (msgHash) => {
                    const cert = await getCert();
                    return sign(hw, cert, msgHash);
                },
            },
        ],
    }
    return {extensions};
}

async function enableHw() {
    this.log("mainAsync", hw);
    await hw.use("auto");
    hw.debug();
    return hw;
}

async function getHwCert(hw) {
    // CERT
    const cert = await hw.getCertificate({ lang: "en" });
    console.log("cert", cert);
    return cert;
}

function getCertCoords(cert) {
    const certPem = hexToPem(cert.hex);
    const ed25519Cert = Certificate.fromPEM(certPem);
    const rawPubKey = ed25519Cert.publicKey.keyRaw.toString("hex").substr(2);
    const pubKeyPoint = hexToPoint(rawPubKey);
    const Pkx = pubKeyPoint.x;
    const Pky = pubKeyPoint.y;
    const PkxHiLo = getHiLo(Pkx);
    const PkyHiLo = getHiLo(Pky);
    const PkxLo = toHex(PkxHiLo.lo);
    const PkxHi = toHex(PkxHiLo.hi);
    const PkyLo = toHex(PkyHiLo.lo);
    const PkyHi = toHex(PkyHiLo.hi);
    console.log("coords", {PkxLo, PkxHi, PkyLo, PkyHi});
    return {PkxLo, PkxHi, PkyLo, PkyHi};
}

async function sign(hw, cert, msgHash) {
    if (msgHash.substr(0, 2) == '0x') {
        msgHash = msgHash.substr(2);
    }

    // SIG
    const signature = await hw.sign(
        cert,
        {
          type: "SHA-256",
          hex: msgHash,
        },
        { lang: "en" },
    );
    const sigHex = signature.hex;
    const sigPoint = hexToPoint(sigHex);
    const r = sigPoint.x;
    const s = sigPoint.y;
    const rHiLo = getHiLo(r);
    const sHiLo = getHiLo(s);
    const rlo = toHex(rHiLo.lo);
    const rhi = toHex(rHiLo.hi);
    const slo = toHex(sHiLo.lo);
    const shi = toHex(sHiLo.hi);
    console.log("signature", {rlo, rhi, slo, shi});
    return {rlo, rhi, slo, shi};
}

export default init;
