import {evm2wast} from '@ark-us/evm2wasm';

async function init () {
    const extensions = {
        label: 'ewasm',
        items: [
            {
                label: 'evm2wast',
                value: (evmBytecode, options) => {
                    options = options || {inlineOps: true, pprint: true};
                    return evm2wast(new Buffer(evmBytecode, 'hex'), options);
                },
            },
        ]
    }

    return {extensions};
}

export default init;
