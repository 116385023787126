import {ethers} from 'ethers';
import taylor from '@ark-us/taylor';
import {extension as create} from '@ark-us/taylor-react';
import evmExtension from '@ark-us/taylor-evm';
import editorExtension from './editor';
import mdExtension from './markdown';
import evmosExtension from './web3/evmos';
import tallyExtension from './tally';
import ipfsExtension from './services/ipfs/ipfs';
import marksExtension from './marks';
import qrExtension from './qrcode';
import browserExtension from './browser';
import documentExtension from './document';
import menuExtension from './menu';
import abiExtension from './components/schema/abiform';
import abiInputExtension from './components/schema/extension';
import iframeExtension from './iframe';
import marksCommunicationExtension from './communication';
import storageExtension from './storage';
import githubExtension from './github';
import loggerExtension from './logger';
import {encodeCompilerFunctions} from './extensions/typedb';
import evmasm from '@pipeos/evmasm';
import solidityCompile from './solidity';
import masm from 'masm';
import {debugCode} from './web3/eth/debugger';
import {debugEwasmCode} from './web3/eth/debugger_ewasm';
import {storage} from './storage';
import draggable from './draggable';
import {clipboardCopy} from './utils';
import { v4 as uuidv4 } from 'uuid';
import * as components from './components';
import componentExamples from './examples';
import {DEFAULT_ABIS, CHAIN_DATA, COSMOS_ABIS} from './data';
import {STORAGE_MENU} from './config';
import {utils as jsvmUtils} from 'ewasm-jsvm';
import plugems from './extensions/plugems';
import remixExtension from './web3/remix';
import ewasmExtension from './web3/ewasm';
import estoniaExtension from './web3/hwcrypto/estonia_id';
import workerExtension from './worker';
import { hexToUint8Array, uint8ArrayToHex } from 'ewasm-jsvm/src/utils';
const BN = require('bn.js');

const {toBN} = jsvmUtils;

let evmExtensionEval;
const joinArrays = (...rest) => rest.reduce((accum, v) => accum.concat(v), []);
const joinObjects = (...rest) => rest.reduce((accum, v) => ({...accum, ...v}), {});
taylor.extendfn('join', (...rest) => {
    if (rest.length === 0) return null;
    if (rest[0] instanceof Array) {
        return joinArrays(...rest);
    }
    else {
        return joinObjects(...rest);
    }
});
taylor.extendfn('random', (min, max) => {
    min = min || 0;
    max = max || Number.MAX_SAFE_INTEGER;
    let num = Math.random() * (max - min) + min;
    return Math.round(num);
});
taylor.extendfn('add', (...args) => {
    let result = toBN(0);
    for (let arg of args) {
        result = result.add(toBN(arg));
    }
    return ethers.BigNumber.from('0x' + result.toString(16, 64));
});
taylor.extendfn('mul', (...args) => {
    let result = toBN(1);
    for (let arg of args) {
        result = result.mul(toBN(arg));
    }
    return ethers.BigNumber.from('0x' + result.toString(16, 64));
});
taylor.extendfn('sub', (a, b) => {
    const result = toBN(a).sub(toBN(b));
    return ethers.BigNumber.from('0x' + result.toString(16, 64));
});
taylor.extendfn('bn-toNumber', (n, ...args) => {
    let sign = 1;
    let value;
    if (n && n.hex) n._hex = n.hex;
    if (n && n._hex && n._hex[0] === '-') {
        sign = -1;
        n._hex = n._hex.slice(1);
        n.hex = n._hex;
    }

    try {value = toBN(n || 0).toNumber(...args);}
    catch(e) {console.debug(e); return null;}
    return value * sign;
});
taylor.extendfn('bn-toString', (n, base = 10, ...args) => {
    let value;
    try {
        value = toBN(n || 0);
        value = value.toString(base, ...args);
        return value;
    }
    catch(e) {return e.message;}
});
taylor.extendfn('includes?', (source, value, caseInsensitive = true) => {
    if (!source || !value) return false;
    return source.includes(value);
});
taylor.extendfn('str-contains', (source, value) => {
    if (!source || !value) return false;
    return source.includes(value);
});
taylor.extendfn('replace', (source, pattern, replacement) => {
    if (!source || !pattern) return source;
    if (typeof pattern === 'object' && pattern.regex) {
        pattern = new RegExp(pattern);
    }
    return source.replace(pattern, replacement);
});
taylor.extendfn('str-lower', (value) => {
    if (!value) return "";
    return value.toLowerCase();
});
taylor.extendfn('str-upper', (value) => {
    if (!value) return "";
    return value.toUpperCase();
});
taylor.extendfn('String-codePointAt', (char, index) => {
    return char.codePointAt(index);
});
taylor.extendfn('String-fromCharCode', (code) => {
    return String.fromCharCode(code);
});
taylor.extendfn('hex-to-ascii', (hex, trimZeroes = true) => {
    let str = '';
    hex = hex.substr(0, 2) === "0x" ? hex.substr(2) : hex;
    for (let i = 0; i < hex.length; i += 2) {
        let code = parseInt(hex.substr(i, 2), 16);
        const char = String.fromCharCode(code);
        if (code !== 0 || !trimZeroes) {
            str += char;
        }
    }
    return str;
});

taylor.extendfn('str-to-hex', (value) => {
    return value.split('').map(v => v.codePointAt(0).toString(16).padStart(2, '0')).join('');
});

taylor.extendfn('str-pad-start', (value, maxLength, fillString = "") => {
    return value.padStart(maxLength, fillString);
});

taylor.extendfn('str-pad-end', (value, maxLength, fillString = "") => {
    return value.padEnd(maxLength, fillString);
});

taylor.extendfn('serialize', (source) => {
    try {
        const u8arr = taylor.serialize(source);
        return ethers.utils.hexlify(u8arr);
    } catch(e) {
        return e.message;
    }
});
taylor.extendfn('deserialize', (source) => {
    if (typeof source === 'string') {
      try {
        source = ethers.utils.arrayify(source);
        source = new Uint8Array([...source]);
      } catch (e) {}
    }
    try {
        return taylor.deserialize(source);
    } catch(e) {
        return e.message;
    }
});
taylor.extendfn('safe-eval', (source) => {
    try {
        let result = taylor.eval(source);
        return taylor.interop.tay2js(result);
    } catch(e) {
        return e.message;
    }
});

window.logger = console;
taylor.extendfn('log', (...args) => {
    window.logger.log(...args);
    return args[0];
});
taylor.extendfn('json-stringify', JSON.stringify);
taylor.extendfn('json-parse', JSON.parse);
taylor.extendfn('copy-clipboard', clipboardCopy);
taylor.extendfn('uuid', uuidv4);
taylor.extendfn('range', (start, stop, step) => {
    start = start || 0;
    stop = stop || start;
    step = step || 1;
    const arr = [];
    for (let i = start; i < stop; i += step) {
        arr.push(i);
    }
    return arr;
})
taylor.extendfn('encode-uri-component', encodeURIComponent);
taylor.extendfn('decode-uri-component', decodeURIComponent);
taylor.extendfn('document-addEventListener', (className, eventName, callback) => {
    const elems = document.getElementsByClassName(className);
    for (let elem of elems) {
        elem.addEventListener(eventName, callback);
    }
});

taylor.extendfn('file-to-base64', (files) => {
    if (!files || typeof files !== 'object' || files.length === 0) return;
    const file = files[0];
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject(error);
        };
    });
});

taylor.extendfn('style-toggle-class', (className, selectedClass, eventNames) => {
    const elems = document.getElementsByClassName(className);
    for (let elem of elems) {
        elem.addEventListener(eventNames[0], (ev) => {
        const self = ev.target;
        if (!self.classList.contains(className)) return;
        const elems = document.getElementsByClassName(className);
        for (let elem of elems) {
            if (self != elem) {
            elem.classList.add(selectedClass);
            }
        }
        });
        elem.addEventListener(eventNames[1], (ev) => {
        const self = ev.target;
        if (!self.classList.contains(className)) return;
        const elems = document.getElementsByClassName(className);
        for (let elem of elems) {
            if (self != elem) {
            elem.classList.remove(selectedClass);
            }
        }
        });
    }
});
taylor.extendfn('document-removeEventListener', (className, eventName, callback) => {
    const elems = document.getElementsByClassName(className);
    for (let elem of elems) {
        elem.removeEventListener(eventName, (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        callback();
        });
    }
})
const setStyleOld = (valueMap) => {
    let content = '';
    const classNames = Object.keys(valueMap);
    classNames.forEach((className) => {
    const styles = Object.keys(valueMap[className]).map(key => {
        return `${key}: ${valueMap[className][key]};`;
    }).join('');
    content += `
    .${className} {${styles}}
`
    });
    var style = document.createElement('style');
    style.innerHTML = content;
    document.getElementsByTagName('head')[0].appendChild(style);
}

const buildScriptTag = (id, type, text, proptype = 'textContent') => {
    if (!text) return;
    if (document.getElementById(id)) {
        document.getElementById(id).remove();
    }

    let elem = document.createElement(type);
    elem.id = id;

    elem[proptype] = text;
    return elem;
}

const setScriptTag = (id, type, text, proptype = 'textContent') => {
    let elem = buildScriptTag(id, type, text, proptype);
    document.getElementsByTagName('head')[0].appendChild(elem);
    return elem;
}

export const setStyleCss = (id, csstext) => {
    setScriptTag(id, 'style', csstext, 'textContent');
}

export const setStyle = (id, valueMap) => {
    if (!id) return;
    if (typeof id === 'object') return setStyleOld(id);
    if (!valueMap) return;

    let content = '';
    const csskeys = Object.keys(valueMap);
    csskeys.forEach((csskey) => {
        const styles = Object.keys(valueMap[csskey]).map(key => {
            return `${key}: ${valueMap[csskey][key]};`;
        }).join('');
        content += `
    ${csskey} {${styles}}
  `
    });
    setStyleCss(id, content);
}
taylor.extendfn('set-style', setStyle);
taylor.extendfn('set-style-css', setStyleCss);
taylor.extendfn('add-class-to-class', (className, classesStr = []) => {
    const elems = document.getElementsByClassName(className);
    const _classes = typeof classesStr === 'string' ? classesStr.split(' ') : classesStr;
    for (let elem of elems) {
        for (let cl of _classes) {
            elem.classList.add(cl);
        }
    }
});
taylor.extendfn('remove-class', (className, classesToRemove = []) => {
    const elems = document.getElementsByClassName(className);
    classesToRemove = typeof classesToRemove === 'string' ? classesToRemove.split(' ') : classesToRemove;
    for (let elem of elems) {
        for (let cl of classesToRemove) {
            elem.classList.remove(cl);
        }
    }
});
taylor.extendfn('set-script', (id, script, scriptType = 'application/javascript') => {
    const elem = buildScriptTag(id, 'script', script, 'innerHTML');
    elem.type = scriptType;
    document.getElementsByTagName('head')[0].appendChild(elem);
    return elem;
})
// <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@fortune-sheet/react@0.8.0/dist/index.css">
taylor.extendfn('set-stylesheet', (href) => {
    const id = href.replace(/\//g, '_')
        .replace(/@/g, '_')
        .replace(/\./g, '_')
        .replace(/:/g, '_')
        .replace(/-/g, '_')
        .replace(/\?/g, '_')
        .replace(/=/g, '_');
    const elem = buildScriptTag(id, 'link', href, 'href');
    elem.rel = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(elem);
    return elem;
})
taylor.extendfn('set-script-url', (id, url, scriptType = 'application/javascript') => {
    const elem = buildScriptTag(id, 'script', url, 'src');
    elem.type = scriptType;
    document.getElementsByTagName('head')[0].appendChild(elem);
    return elem;
})

window.customImports = {};
taylor.extendfn('import', (url) => {
    const id = 'customImport_' + Object.keys(window.customImports).length;
    const content = `import * as moduleAll from "${url}"; import moduleDefault from "${url}"; window.customImports[${id}] = {...moduleAll, default: moduleDefault};`;
    const elem = buildScriptTag(id, 'script', content, 'innerText');
    elem.type = 'module';
    document.getElementsByTagName('head')[0].appendChild(elem);
    return elem;
})
// <headRequire:(set-script "fortuneSheetScript" "import * as fortune from \"https://cdn.skypack.dev/@fortune-sheet/react@0.8.0?min\"; console.log(fortune);" "module")>
taylor.extendfn('window-dimensions', () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
});
taylor.extendfn('createObjectURL', (file) => {
    if (file instanceof FileList) file = file[0];
    return window.URL.createObjectURL(file);
});
taylor.extendfn('buffer2objectURL', (binaryBuffer) => {
    const blob = new Blob([binaryBuffer]);
    const objurl = URL.createObjectURL(blob);
    return objurl;
});
taylor.extendfn('utf8-encode', (str) => {
    let utf8Encode = new TextEncoder();
    const uint8arr = utf8Encode.encode(str);
    // returns 0x...
    return uint8ArrayToHex(uint8arr);
})
taylor.extendfn('utf8-decode', (hexvalue) => {
    const uint8arr = hexToUint8Array(hexvalue);
    let utf8Decode = new TextDecoder();
    return utf8Decode.decode(uint8arr);
})
taylor.extendfn('sleep', (fn, ms) => {
    return setTimeout(fn, ms);
});
taylor.extendfn('timeout-set', (fn, ms) => {
    return setTimeout(fn, ms);
});
taylor.extendfn('timeout-clear', (id) => {
    return clearTimeout(id);
});
taylor.extendfn('interval-set', (fn, ms) => {
    return setInterval(fn, ms);
});
taylor.extendfn('interval-clear', (id) => {
    return clearInterval(id);
});
taylor.extendfn('fetch-json', async (url, options) => {
    let response;
    try {
        response = await fetch(url, options);
    } catch (e) {return e.message};
    try {
        return response.json();
    } catch (e) {return e.message};
});
taylor.extendfn('fetch-text', async (url, options) => {
    let response;
    try {
        response = await fetch(url, options);
    } catch (e) {return e.message};
    try {
        return response.text();
    } catch (e) {return e.message};
});
taylor.extendfn('eth-compile-asm', async (source = '', includeMeta = false) => {
    let result;
    source = source || '';
    const _parser = evmasm.parser(source);
    try {
        // result = await evmasm.compile(source);
        result = await _parser.compile();
        if (includeMeta) {
            const op2line = _parser.op2line;
            const line2op = _parser.line2op;
            const runtimeIndex = result.indexOf('f300') + 4;
            const runtimeLines = _parser.runtimeLines ? _parser.runtimeLines[0] : [0, 0];
            return {bytecode: result, op2line, line2op, runtimeLines, runtimeIndex};
        }
        else return result;
    } catch (e) {
        if (includeMeta) result = {error: e.message};
        else result = e.message;
        console.debug('**** eth-compile-asm error', e);
    };

    return result;
});

let macros = '';
taylor.extendfn('eth-compile-masm', async (source, transpileTimeVariables) => {
    console.log('---eth-compile-masm', transpileTimeVariables);
    if (!macros) macros = await (await fetch(masm.url)).text().catch(console.debug);

    let result;
    try {
        const {source: _source, switches} = await masm.compile(source, macros, transpileTimeVariables);
        result = {source: _source, switches};
    } catch (e) {console.log(e); result = {errors: [e.message], source: '', switches: {}}};
    return result;
});
const compileSol = async (source, language, version) => {
    const _source = {
        ContractName: {
            content: source,
        }
    }
    console.log('_source', _source, language);

    const [success, data, sourcedata] = await solidityCompile(_source, language, version);
    console.log('compiled', [success, data, sourcedata]);

    const errors = data.errors ? data.errors.map(v => v.formattedMessage || v.message) : [];
    const errorsStr = errors.join('\n');
    if (!success || !data.contracts) return {abi: JSON.stringify(errors), errors: errorsStr, asm: errorsStr, hex: errorsStr};

    // TODO - we support only one contract now.
    let compiled = data.contracts.ContractName;
    const contractName = Object.keys(compiled)[0];
    compiled = compiled[contractName];
    console.log('compiled', compiled);
    let metadata = {};
    try {
        metadata = JSON.parse(compiled.metadata);
    } catch (e) {console.debug(e)};
    return {
        abi: JSON.stringify(compiled.abi),
        asm: compiled.evm.assembly,
        hex: compiled.evm.bytecode.object,
        errors,
        compiled: metadata,
        contractName,
    }
}
taylor.extendfn('eth-compile-solidity', compileSol);
taylor.extendfn('eth-debug', debugCode);
taylor.extendfn('ewasm-debug', debugEwasmCode);
taylor.extendfn('taylor-evm', async (source) => {
    try {
        const result = await evmExtensionEval(source);
        return result;
    } catch(e) {
        return {errors: [e.message]}
    }
});
taylor.extendfn('evm-to-compiled', (tayAst) => {
    const tayval = taylor._.print(tayAst);
    let result;
    try {
        result = evmExtensionEval(tayval, false);
    } catch(e) {
        throw e;
    }
    return result;
}, null, {
    inputRaw: true,
    outputRaw: true,
});
taylor.extendfn('typedb-expose-lib', (fndefs) => {
    const cfuncs = Object.keys(fndefs).map(key => {
        const fndef = fndefs[key];
        return {
            ipfsHash: 'c1057df94217e4999303c7f825063adfb8c4fbf4c46d0b79d9a54353eac441d6',
            name: fndef.name,
            type: 0,
            inputSlots: fndef.ins.length,
            outputSlots: fndef.outs.length,
            bytecode: fndef.bytecode,
        }
    });
    const data = encodeCompilerFunctions(cfuncs);
    return {asm: 'auxdata: 0x' + data, bytecode: data}
});

let timertime = new Date().getTime();
taylor.extendfn('timer', (message='', reset=false) => {
    const d = new Date().getTime();
    if (reset) timertime = d;
    console.log(message, d - timertime);
});

const globalCommands = {};
export function extend (extension) {
  extension.items.forEach(element => {
    const label = extension.label + ((element.label && extension.label) ? ('-' + element.label) : element.label);
    taylor.extendfn(label, element.value);
    globalCommands[label] = element.value;
  });
}

export function getGlobalCommands() {
    return globalCommands;
}

function contractdata2menu (contractdata, callb) {
    const data = [];
    for (let v of contractdata) {
        data.push(c2item(v, callb));
    }
    return data;
}

function _2item(v, callb) {
    return v;
}

function c2item (contract, callb) {
    const fns = [];
    const abi = contract.abi || [];
    const _functions = abi.filter(v => v.type === 'function');
    for (let v of _functions) {
        fns.push(fn2item(v, contract, callb));
    }

    return {
        label: contract.name,
        items: [
            _2item({label: 'address', value: contract.address}, callb),
            ...fns,
        ]
    }
}

function fn2item (fn, contract, callb) {
    const initems = [];
    const outitems = [];
    const iomd = (v) => `::Contract[${v}]{name="${contract.name}" function="${fn.name}"}`;
    for (let v of fn.inputs) {
        initems.push(io2item(v, null, callb));
    }
    for (let v of fn.outputs) {
        outitems.push(io2item(v, iomd, callb));
    }
    const fnmd = `::Contract[]{name="${contract.name}" function="${fn.name}"}`;

    return {
        label: fn.name,
        items: [
            _2item({label: fn.name, value: fnmd}, callb),
            _2item({label: 'inputs', items: initems}, callb),
            _2item({label: 'outputs', items: outitems}, callb),
        ]
    }
  }

function io2item (io, iomd, callb) {
    const value = iomd ? iomd(`io.name: @${io.name}`) : io.name;
    return _2item({label: io.name, value}, callb);
}

export async function initialize () {
    taylor.eval(`(def! gt (fn* (a b) (> a b)))`);
    taylor.eval(`(def! lt (fn* (a b) (< a b)))`);
    // await taylor.eval(`(def! reduce (fn* (f xs init) (if (empty? xs) init (reduce f (rest xs) (f init (first xs)) ))))`);
    taylor.eval(`(def! reduce (fn* (f xs init) (let* (
        _count (count xs)
        _reduce (fn* (f xs init)
            (if (empty? xs) init
                (_reduce f (rest xs) (f init (first xs) (- _count (count xs)))  )
            )
        )
    )
        (_reduce f xs init)
    )

))`);

    const {extensions} = create(taylor);
    extend(extensions);
    await editorExtension(taylor).then(({extensions}) => extend(extensions));
    await evmosExtension().then(({extensions}) => extend(extensions));
    await remixExtension().then(({extensions}) => extend(extensions));
    await tallyExtension().then(({extensions}) => extend(extensions));
    await ipfsExtension().then(({extensions}) => extend(extensions));
    await marksExtension().then(({extensions}) => extend(extensions));
    await qrExtension().then(({extensions}) => extend(extensions));
    await browserExtension().then(({extensions}) => extend(extensions));
    await documentExtension().then(({extensions}) => extend(extensions));
    await menuExtension().then(({extensions}) => extend(extensions));
    await abiExtension().then(({extensions}) => extend(extensions));
    await abiInputExtension().then(({extensions}) => extend(extensions));
    await mdExtension(taylor, globalCommands).then(({extensions}) => extend(extensions));
    await iframeExtension().then(({extensions}) => extend(extensions));
    await marksCommunicationExtension().then(({extensions}) => extend(extensions));
    await storageExtension().then(({extensions}) => extend(extensions));
    await githubExtension().then(({extensions}) => extend(extensions));
    await loggerExtension().then(({extensions}) => extend(extensions));
    await draggable().then(({extensions}) => extend(extensions));
    await workerExtension().then(({extensions}) => extend(extensions));
    await ewasmExtension().then(({extensions}) => extend(extensions));
    await evmExtension(taylor).then(({extensions, transpile}) => {
        evmExtensionEval = async (tayval, js = true) => {
            let transpiled;
            try {
                transpiled = transpile(tayval);
            } catch(e) {
                transpiled = e.message;
            }
            const result = await taylor.eval(transpiled);
            if (js) return taylor.interop.tay2js(result);
            return result;
        }
        return extend(extensions);
    });
    await estoniaExtension().then(({extensions}) => extend(extensions));
    await plugems().then(({extensions}) => extend(extensions));

    function unique (contractdata) {
        const names = {};
        return contractdata.reverse().filter((c) => {
            if (names[c.name]) return false;
            names[c.name] = true;
            return true;
        });
    }

    taylor.extendfn('contractdata-to-menu', (contractdata, callb = {}) => {
        let menuAbis;
        try {
            menuAbis = JSON.parse(storage.getItem(STORAGE_MENU));
        } catch (e) {}

        contractdata = unique((menuAbis || DEFAULT_ABIS).concat(contractdata));
        storage.setItem(STORAGE_MENU, JSON.stringify(contractdata));

        const data = contractdata2menu(contractdata, callb);
        return data;
    });

    taylor.extendfn('component-menu', () => {
        return componentExamples;
    });

    taylor.extendfn('web3-chains', (namespace, networkType, chainId, allData) => {
        if (!allData) allData = CHAIN_DATA;

        if (!namespace) return allData;
        if (!networkType) return allData[namespace];
        if (!allData[namespace]) return null;
        if(!chainId) return allData[namespace][networkType];
        if (!allData[namespace][networkType]) return null;

        if (typeof chainId !== 'object') {
            return Object.values(allData[namespace][networkType]).find(v => v.chainId.toString() === chainId.toString());
        }

        const q = chainId;
        return Object.values(allData[namespace][networkType]).filter(v => {
            for (let key in q) {
                if (v[key] !== q[key]) return false;
            }
            return true;
        });
    });

    taylor.extendfn('web3-cosmos-abi', (moduleName, methodName, field) => {
        if (!moduleName) return COSMOS_ABIS;
        if (!methodName) return COSMOS_ABIS[moduleName];
        const fnabi = COSMOS_ABIS[moduleName].find(abi => abi.name === methodName);
        if (!field) return fnabi;
        return fnabi[field];
    });

    for (let comp of Object.values(components)) {
        taylor.eval(comp);
    }
    return taylor;
}

export default taylor;
