import React, { useState } from 'react';
import QRCode from 'qrcode';
import {clipboardCopy} from './utils';
import { QrReader } from 'react-qr-reader';

async function init () {
    const extensions = {
        label: 'qr',
        items: [
            {
                label: 'generate',
                value: generateQrCode,
            },
        ]
    }
    return {extensions};
}

export default init;

// generateQrCode(text) or generateQrCode(id, text)
export function generateQrCode (id, text) {
    if (id && !text) {
        text = id;
        id = "qrcodeCanvas";
    }
    console.log('generateQrCode', text);
    var canvas = document.getElementById(id);
    document.getElementById('AppFrontStage').style.display = 'block';
    QRCode.toCanvas(canvas, text, function (error) {
        if (error) console.error(error)
        console.log('qrcode generated!');
        canvas.onclick = () => {
            clipboardCopy(text);
        }
    });
    document.getElementById('qrcodeExportNavigator').onclick = () => {
        window.location.href = text;
    }
}

export function QrCodeScanner (props) {
    const {onChange} = props;
    let {active} = props;
    const _props = {...props};
    delete _props.onChange;
    return (
      <>
        {active && (<QrReader
            {..._props}
            onResult={(result, error) => {
                if (!!result) {
                    if (onChange && result && result.text) {
                        onChange({result: result.text});
                    }
                }

                if (!!error) {
                    onChange && onChange({error: error.message || "unreadable qr"});
                }
            }}
            style={{ width: '100%' }}
        />)}
      </>
    );
};
