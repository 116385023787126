import {ipfsGet} from '../../services/ipfs/ipfs';

export const resolveAbiFromIpfs = async (obj) => {
    if (obj.ipfs && !obj.abi) {
        const id = typeof obj.ipfs === 'object' ? obj.ipfs.path : obj.ipfs;
        const compiled = await ipfsGet(obj.ipfs);
        if (compiled) obj.abi = compiled.output.abi;
        if (!obj.name) obj.name = 'C_' + obj.ipfs.slice(0, 15);
    }
    return obj;
}
