import React from 'react';
import { withTheme } from '@rjsf/core';
import { Theme as AntDTheme } from '@rjsf/antd';

const Form = withTheme(AntDTheme);

function tryParse (v) {
    if (typeof v === 'object') return v;
    try {
        return JSON.parse(v);
    } catch(e) {return};
}

function SchemaForm (props) {
    const {schema, uiSchema, value, style} = props;

    const _value = tryParse(value) || {};
    const _schema = tryParse(schema) || {};
    const _uiSchema = tryParse(uiSchema) || {};

    const onFormChange = async ({formData}) => {
        if (props.onChange) {
            props.onChange(formData);
        }
    }

    let formRef;
    const onSubmit = () => {
        return props.onSubmit ? props.onSubmit() : null
    }
    const onError = (v) => console.log(v);

    return (
        <div style={{...style}}>
            <Form
                style={{'position': 'absolute', 'top': '0px'}}
                ref={(form) => {formRef = form}}
                schema={_schema}
                uiSchema={_uiSchema}
                formData={_value}
                onChange={onFormChange}
                onSubmit={onSubmit}
                onError={onError}
            >
                <button className="SchemaFormSubmit" type="submit">do</button>
            </Form>
        </div>
    );
}

export default SchemaForm;
